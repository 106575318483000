/*

Authentication hook that exports :
  ProvideAuth
  useAuth


  Usage:

  import { useAuth } from "../../hooks/auth.js";
  ...
  const auth = useAuth();

  useAuth provides the following :
    signin(user:object)
    signout()
    isAuthenticated:boolean
    user:object

  If the user is not logged in, then the user object is false.
  Please use isAuthenticated to check is user is logged in
  The user object contains the following keys:

  {
    number:integer,  // YL member number
    name:string,
    country:string,  // ISO 2 letter country code for this member
    token:string,
    api:object,      // userAPI and libraryAPI
    version:string,  // semver version from package.json
    updatedAt:integer  // Epoch time when this data was last updated
  }


  signin(user:object)
    This function will store in the user object all of the key:values of
    the object that is passed in. It will automatically add the key:values
    for version and updatedAt


 */

import React, { useState, useContext, createContext, useMemo } from "react";
// See https://usehooks.com/useAuth/ for original firebase code
import Debug from "debug";

import fb from "firebase/app";
import firebase from "../config/firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

import { navigate } from "hookrouter";
import { useGA } from "./ga";

const Log = new Debug("auth");

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const ga = useGA();

  // Always initialise user from the localStorge item if it exists.
  // This keeps the user logged in all the time.
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("user")) {
      const u = JSON.parse(localStorage.getItem("user"));
      // Make sure the user object we have fetched from the localStorage does
      // have the properties we expect including a token
      if (
        u.token &&
        u.token.length > 0 &&
        u.number &&
        u.number > 1000 &&
        u.name &&
        u.country
      )
        return u;
    }
    return false;
  });

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  // { token:string, jwt: string, name:string, number:integer, country:string }
  const signin = newUser => {
    // Sign in to firebase using the JWT (json web token)
    return firebase
      .auth()
      .signInWithCustomToken(newUser.jwt)
      .then(response => {
        // This additional call will let us retrieve the "additional claims"
        // that may be set in the auth token. The additional claims we set are
        // isAdmin and isTeamAdmin
        return firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(idTokenResult => {
            const updatedUser = {
              ...newUser,
              jwt: "",
              uid: response.user.uid,
              isAdmin:
                idTokenResult.claims.isAdmin &&
                idTokenResult.claims.isAdmin === true
                  ? true
                  : false,
              isTeamAdmin:
                idTokenResult.claims.isTeamAdmin &&
                idTokenResult.claims.isTeamAdmin === true
                  ? true
                  : false,
              version: process.env.REACT_APP_VERSION,
              updatedAt: new Date().getTime()
            };

            setUser(updatedUser);
            localStorage.setItem("user", JSON.stringify(updatedUser));
          })
          .catch(err => {
            console.log(err);
            ga.ReactGA.exception({
              description: `auth.js:firebase.auth().currentUser.getIdTokenResult():${err}`
            });
          });
      })
      .then(() => {
        // Here we are going to generate the Firebase Cloud Messaging token
        // needed to send the user notifications aka fcmToken
        // See https://firebase.google.com/docs/cloud-messaging/js/client
        return firebase.messaging();
      })

      .then(messaging => {
        messaging
          .requestPermission()
          .then(() => {
            Log("Notification messaging allowed. Generating token...");
            return messaging.getToken();
          })
          .then(token => {
            // Update this token in the members fcmTokens field in their doc
            firebase
              .firestore()
              .collection("teams")
              .doc(process.env.REACT_APP_TEAM)
              .collection("members")
              .doc(String(newUser.number))
              .set(
                { fcmTokens: fb.firestore.FieldValue.arrayUnion(token) },
                { merge: true }
              )
              .then(() => {
                Log("Added messaging token to fcmTokens in member doc");
              })
              .catch(err => {
                Log(
                  `Failed to add messaging token to fcmTokens in member doc : ${err}`
                );
                ga.ReactGA.exception({
                  description: `auth.js:firestore.set(fcmTokens field):${err}`
                });
              });
          })
          .catch(err => {
            // User denied sending notifications. Darn!
            Log(
              `User denied the ability to send notification messages : ${err}`
            );
          });

        // Setup what to do when a notification comes in and they are in the app.
        messaging.onMessage(payload => {
          Log(`Message received: ${payload}`);
        });
      })

      .catch(err => {
        console.error(
          `Failed to login to firebase. Code: ${err.code} Message: ${err.message}`
        );
        ga.ReactGA.exception({
          description: `auth.js:Failed to login to firebase. Code: ${err.code} Message: ${err.message}`,
          fatal: true
        });
      });
  };

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("tableState");
        localStorage.removeItem("library_checked");
        setUser(false);
        navigate("/signedout");
      });
  };

  const isAuthenticated = useMemo(() => {
    return user && user.token && user.token.length > 0;
  }, [user]);

  // Return the user object and auth methods
  return {
    user,
    isAuthenticated,
    signin,
    signout
  };
}
