import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Wrapper from "./Wrapper";

// Register service work on production builds
if (process.env.NODE_ENV === "production") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

// Firebase Cloud Messaging service worker registration
if ("serviceWorker" in navigator) {
  const workerFile =
    process.env.REACT_APP_SERVER === "production"
      ? "/firebase-messaging-sw.js"
      : "/firebase-messaging-sw-dev.js";

  navigator.serviceWorker
    .register(workerFile)
    .then(function(registration) {
      console.log("FCM registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("FCM Service worker registration failed, error:", err);
    });
}

ReactDOM.render(<Wrapper />, document.getElementById("root"));
