import React, { useMemo, useCallback, useEffect, useState } from "react";
import Debug from "debug";
import _ from "lodash";
import Flag from "react-country-flag";

import {
  Chip,
  Typography,
  Button,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import useCountry from "../../../hooks/useCountry";
import useScreenSize from "../../../hooks/useScreenSize";
import { teamStyles } from "../../../config/team";

const Log = Debug("MasterTableTags");

export default function MasterTabletags({
  tags,
  combo,
  tableFilters,
  save,
  open,
  close
}) {
  /*
  Log("Start Render");
  useEffect(() => {
    Log("Finish Render");
  });
*/

  const [tagCombo, setTagCombo] = useState(combo);

  const country = useCountry();
  const screenSize = useScreenSize(true);

  const ts = useMemo(() => teamStyles(), []);

  const handleClose = () => {
    Log("Closing without save");
    close();
  };

  const handleSave = () => {
    Log("Saving and closing");

    let newTableFilters = [...tableFilters];

    Object.keys(tagsSelected).map(tag => {
      if (tagsSelected[tag] === false) {
        _.remove(newTableFilters[4], function(t) {
          return t === tag;
        });
      } else {
        // Currently false, so need to add type to the array
        if (!newTableFilters[4].includes(tag)) newTableFilters[4].push(tag);
      }
      return tag;
    });

    save(newTableFilters, tagCombo);
    close();
  };

  // selected contains the currently selected (active) tag filters
  const selected = useMemo(() => {
    // Creates and returns an object that looks like:
    // { business : true, singles : false }
    // based on what table filters are currently enabled
    let filters = {};
    tags.map(type => {
      filters[type] = tableFilters[4].includes(type);
      return type;
    });

    return filters;
  }, [tableFilters, tags]);

  const [tagsSelected, setTagsSelected] = useState(selected);

  const activate = useCallback(type => {
    Log(`${type} clicked and activated`);
    setTagsSelected(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  }, []);

  // When the tableFilters have changed, then we update
  useEffect(() => {
    let filters = {};
    tags.map(type => {
      filters[type] = tableFilters[4].includes(type);
      return type;
    });

    setTagsSelected(filters);
    setTagCombo(combo);
  }, [tableFilters, tags, combo]);

  const chipStyle = makeStyles(theme => ({
    root: {
      margin: `${
        screenSize.isSmallScreen ? "2px 6px 8px 0px" : "2px 6px 6px 2px"
      }`,
      borderRadius: "16px",
      paddingLeft: `${screenSize.isSmallScreen ? "7px" : "7px"}`,
      paddingRight: `${screenSize.isSmallScreen ? "7px" : "7px"}`,
      height: `${screenSize.isSmallScreen ? "38px" : "36px"}`,
      fontSize: "0.7rem"
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 4,
      paddingRight: 4,
      whiteSpace: "nowrap"
    }
  }));

  const chipSelectedStyle = makeStyles(theme => ({
    root: {
      margin: `${
        screenSize.isSmallScreen ? "2px 6px 8px 0px" : "2px 6px 6px 2px"
      }`,
      borderRadius: "16px",
      paddingLeft: `${screenSize.isSmallScreen ? "7px" : "7px"}`,
      paddingRight: `${screenSize.isSmallScreen ? "7px" : "7px"}`,
      height: `${screenSize.isSmallScreen ? "38px" : "36px"}`,
      fontSize: "0.7rem"
    },

    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 4,
      paddingRight: 4,
      whiteSpace: "nowrap"
    },
    colorPrimary: {
      background: ts.library.buttonType.bgSelected,
      border: ts.library.buttonType.borderSelected,
      boxShadow: ts.library.buttonType.boxShadowSelected
    }
  }));

  const chipClass = chipStyle();
  const chipSelectedClass = chipSelectedStyle();

  if (!open) return null;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Select the tags to filter on
      </DialogTitle>
      <DialogContent dividers>
        {tags.map(tag => (
          <Chip
            key={tag}
            label={
              country.codesLC.includes(tag) ? (
                <Box
                  style={{
                    minHeight: 25,
                    minWidth: 50
                  }}
                >
                  <Flag
                    countryCode={tag}
                    title={tag}
                    svg
                    style={{
                      height: "auto",
                      maxHeight: "25px",
                      width: "auto"
                    }}
                    cdnUrl="/images/flags-svg/"
                  />
                </Box>
              ) : (
                tag
              )
            }
            variant={tagsSelected[tag] ? "default" : "outlined"}
            onClick={() => activate(tag)}
            classes={tagsSelected[tag] ? chipSelectedClass : chipClass}
            color="primary"
            clickable
          />
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-start" }}>
        <Button
          autoFocus
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          Save changes
        </Button>
        <FormControl component="fieldset" style={{ marginLeft: "30px" }}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={tagCombo}
            onChange={e => setTagCombo(e.target.value)}
            style={{ flexDirection: "row" }}
          >
            <FormControlLabel value="or" control={<Radio />} label="OR" />
            <FormControlLabel value="and" control={<Radio />} label="AND" />
          </RadioGroup>
        </FormControl>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Dialog = withStyles(theme => ({
  paper: {
    margin: "5px"
  },
  paperFullWidth: {
    maxHeight: "calc(100% - 15px)",
    width: "calc(100% - 10px)"
  }
}))(MuiDialog);

const DialogContent = withStyles(theme => ({
  root: {
    padding: "4px"
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
