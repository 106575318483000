import React, { useMemo, useCallback, useEffect } from "react";
import _ from "lodash";
import Debug from "debug";
import { navigate } from "hookrouter";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// "react-embed": "^3.3.2",
// import Embed from "react-embed"; // https://github.com/streamich/react-embed

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Typography,
  Box,
  Button,
  Tooltip,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";

import ViewIcon from "@material-ui/icons/OpenInNew";
import ShareIcon from "@material-ui/icons/Share";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavouriteIcon from "@material-ui/icons/FavoriteBorder";
import FavouriteSelectedIcon from "@material-ui/icons/Favorite";
import LaterIcon from "@material-ui/icons/WatchLaterOutlined";
import LaterSelectedIcon from "@material-ui/icons/WatchLater";
import CollectionAddIcon from "@material-ui/icons/PlaylistAdd";

import MyIcon from "@material-ui/icons/AccountBoxOutlined";
import LibraryIcon from "@material-ui/icons/LocalLibraryOutlined";
import BizIcon from "@material-ui/icons/BusinessCenterOutlined";

import AssetIcon from "./AssetIcon";

import useScreenSize from "../../../hooks/useScreenSize";
import { teamStyles } from "../../../config/team";

import { isAsset, getEmbedCode, EmbedAsset } from "./EmbedAsset";

const Log = Debug("AssetCard");

/*
asset
{
  id : integer, // eg 1001
  type : string, // eg pdf, ebook, image, audio, video
  name : string,
  extra : string, // eg 1 hr 35 mins
  tags : string, // comma separated list
  url : string,
  seealso : string, //comma separated list of ids
  share : boolean, // is this asset publically sharable?
  desc : string
}
 */

export default function AssetCard({
  asset,
  library,
  reportIssue,
  isFavourite,
  updateFavourite,
  isViewLater,
  updateViewLater,
  shareClick,
  addToCollection
}) {
  const [expanded, setExpanded] = React.useState(true);
  const [favourite, setFavourite] = React.useState(isFavourite);
  const [viewLater, setViewLater] = React.useState(isViewLater);
  const screenSize = useScreenSize();

  // If the asset changes, then we need to re-set the favourite
  useEffect(() => {
    if (isFavourite !== favourite) setFavourite(isFavourite);
    // eslint-disable-next-line
  }, [asset.id]); // Ignore the warning on this.

  // If the asset changes, then we need to re-set the viewLater
  useEffect(() => {
    if (isViewLater !== viewLater) setViewLater(isViewLater);
    // eslint-disable-next-line
  }, [asset.id]); // Ignore the warning on this.

  const isSA = false;

  const defaultEmbed = useMemo(() => {
    return !(
      isAsset("link", asset) ||
      isAsset("instagram", asset) ||
      isAsset("image", asset) ||
      isAsset("ylPodcast", asset) ||
      isAsset("googleAudio", asset) ||
      isAsset("googleVideo", asset) ||
      isAsset("googleImage", asset) ||
      isAsset("youtube", asset)
    );
  }, [asset]);

  const viewLink = useCallback(
    link => {
      // If we are on a smaller screen, then we REPLACE the existing window
      // with the new one, by closing the old one first, before opening the new one.
      if (screenSize.width < 800) {
        let name = "intrax";
        if (!window.popups) window.popups = {};
        if (window.popups[name]) window.popups[name].close();
        window.popups[name] = window.open(link, name);
      } else {
        // When on a larger screen, we simply open the link in a new window each time.
        let a = document.createElement("a");
        a.href = link;
        a.target = "_blank";
        a.click();
      }
    },
    [screenSize.width]
  );

  // Team Styles
  const ts = useMemo(() => {
    return teamStyles();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: `${screenSize.width < 450 ? "none" : "min-content"}`,
      margin: "5px 0px 70px 0px",
      background: ts.card.background,
      boxShadow: ts.card.boxShadow,
      borderRadius: "20px"
    },

    cardContent: {
      padding: "0px 7px 0px 7px"
    },

    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },

    expandOpen: {
      transform: "rotate(180deg)"
    },

    icon: {
      padding: "16px 20px"
    }
  }));
  const classes = useStyles();

  const handleExpandClick = useCallback(() => {
    setExpanded(e => !e);
  }, []);

  // Style overrides for the card
  const theme = useMemo(() => {
    let theme = createMuiTheme({
      overrides: {
        MuiCardHeader: {
          title: {
            fontSize: "0.80rem",
            "@media (min-width:350px)": {
              fontSize: "0.85rem"
            },
            "@media (min-width:400px)": {
              fontSize: "0.9rem"
            },
            "@media (min-width:600px)": {
              fontSize: "1.0rem"
            },
            "@media (min-width:700px)": {
              fontSize: "1.1rem"
            },
            "@media (min-width:800px)": {
              fontSize: "1.2rem"
            },
            "@media (min-width:1200px)": {
              fontSize: "1.4rem"
            }
          },

          subheader: {
            fontSize: "0.65rem",
            "@media (min-width:350px)": {
              fontSize: "0.7rem"
            },
            "@media (min-width:400px)": {
              fontSize: "0.8rem"
            },
            "@media (min-width:600px)": {
              fontSize: "0.9rem"
            },
            "@media (min-width:700px)": {
              fontSize: "0.95rem"
            },
            "@media (min-width:800px)": {
              fontSize: "1.0rem"
            },
            "@media (min-width:1200px)": {
              fontSize: "1.1rem"
            }
          },

          avatar: {
            marginRight: "5px !important",
            "@media (min-width:350px)": {
              marginRight: "7px !important"
            },
            "@media (min-width:400px)": {
              marginRight: "8px !important"
            },
            "@media (min-width:600px)": {
              marginRight: "9px !important"
            },
            "@media (min-width:700px)": {
              marginRight: "10px !important"
            }
          }
        },

        MuiCardContent: {
          root: {
            fontSize: "0.65rem",
            "@media (min-width:400px)": {
              fontSize: "0.7rem"
            },
            "@media (min-width:500px)": {
              fontSize: "0.8rem"
            },
            "@media (min-width:600px)": {
              fontSize: "0.9rem"
            },
            "@media (min-width:700px)": {
              fontSize: "0.95rem"
            },
            "@media (min-width:800px)": {
              fontSize: "1.0rem"
            },
            "@media (min-width:1200px)": {
              fontSize: "1.1rem"
            }
          }
        }
      }
    });

    return theme;
  }, []);

  // Button to open in link in a new window IF this asset is sharable.
  const newWindowButton = useMemo(() => {
    if (asset.share) {
      return (
        <Tooltip title="Open in new window">
          <IconButton
            aria-label="view"
            style={{ padding: "2px 2px 0px 0px" }}
            onClick={() => {
              viewLink(asset.url);
            }}
          >
            <ViewIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  }, [asset.share, asset.url, viewLink]);

  // inCollections is an array of objects representing the collections that this
  // asset is found in. The object has the following properties :
  // id : the id of the collection
  // name : the name of the collection
  // link : the URL we use for the navigate function
  // type : type of the collection ("myCollection" || "libraryCollection" || "bizCollection")

  const inCollections = useMemo(() => {
    let assetInCollections = []; // Array of objects with properties id, name and link
    let foundInCollections;

    _.forEach(["my", "library", "biz"], collectionType => {
      // Search through the collections and return an array of collections that
      // this asset appears in
      foundInCollections = _.filter(
        library.getCollections(collectionType),
        o => {
          return o.assets.includes(asset.id);
        }
      );
      if (foundInCollections.length > 0) {
        Log(
          `Found asset id ${asset.id} in ${foundInCollections.length} of ${collectionType} collections`
        );
        _.forEach(foundInCollections, collection => {
          assetInCollections.push({
            id: collection.id,
            name: collection.name,
            type: collection.type,
            link: `/app/${collectionType}_collections/${collection.id}`
          });
        });
      }
    });

    return assetInCollections;
  }, [asset, library]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <IconButton
              aria-label="type"
              color="primary"
              style={{
                padding: "0"
              }}
            >
              <AssetIcon type={asset.type} size="large" />
            </IconButton>
          }
          action={newWindowButton}
          title={asset.name}
          subheader={!isAsset("ylPodcast", asset) ? asset.extra : ""}
        />

        <CardContent className={classes.cardContent}>
          <EmbedAsset type="instagram" asset={asset} isSA={false} />
          <EmbedAsset type="image" asset={asset} isSA={false} />
          <EmbedAsset type="ylPodcast" asset={asset} isSA={false} />
          <EmbedAsset type="googleAudio" asset={asset} isSA={false} />
          <EmbedAsset type="googleVideo" asset={asset} isSA={false} />
          <EmbedAsset type="googleImage" asset={asset} isSA={false} />
          <EmbedAsset type="youtube" asset={asset} isSA={false} />
          <EmbedAsset
            type="link"
            asset={asset}
            viewLink={viewLink}
            isSA={false}
          />

          {defaultEmbed && (
            <Box
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "aliceblue",
                maxWidth: `${screenSize.width - 40}`
              }}
              dangerouslySetInnerHTML={getEmbedCode(asset, isSA)}
            />
          )}
        </CardContent>

        <CardActions disableSpacing>
          <Tooltip
            title={`${favourite ? "Remove from favourites" : "Add favourite"}`}
          >
            <IconButton
              aria-label="favourite"
              className={classes.icon}
              onClick={() => {
                updateFavourite(asset, !favourite);
                setFavourite(v => !v);
              }}
            >
              {favourite ? (
                <FavouriteSelectedIcon style={{ color: "red" }} />
              ) : (
                <FavouriteIcon />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip
            title={`${
              viewLater
                ? "Remove from view later list"
                : "Add to view later list"
            }`}
          >
            <IconButton
              aria-label="view_later"
              className={classes.icon}
              onClick={() => {
                updateViewLater(asset, !viewLater);
                setViewLater(v => !v);
              }}
            >
              {viewLater ? (
                <LaterSelectedIcon style={{ color: "green" }} />
              ) : (
                <LaterIcon />
              )}
            </IconButton>
          </Tooltip>

          {asset.share && navigator.share && (
            <Tooltip title="Share">
              <IconButton
                aria-label="share"
                className={classes.icon}
                onClick={() => {
                  shareClick(asset);
                  navigator.share({
                    title: asset.name,
                    url: asset.url
                  });
                }}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Add to collection">
            <IconButton
              aria-label="addToCollection"
              className={classes.icon}
              onClick={() => {
                addToCollection(asset);
              }}
            >
              <CollectionAddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Report an issue/give feedback">
            <IconButton
              aria-label="bug"
              className={classes.icon}
              onClick={() => {
                reportIssue(asset);
              }}
            >
              <BugReportIcon />
            </IconButton>
          </Tooltip>

          {asset.desc.length > 1 && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </CardActions>

        {(asset.desc.length > 1 || inCollections.length > 0) && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {asset.desc.split("\n").map((item, key) => {
                return (
                  <Typography paragraph style={{ fontSize: "100%" }} key={key}>
                    {item}
                  </Typography>
                );
              })}

              {inCollections.length > 0 && (
                <>
                  <Typography
                    paragraph
                    style={{
                      fontStyle: "italic",
                      color: "cadetblue",
                      fontSize: "90%"
                    }}
                  >
                    This asset can be found in the collection
                    {inCollections.length === 1 ? "" : "s"} listed below. Tap
                    the button to view all the assets in that collection.
                  </Typography>

                  {inCollections.map(collection => {
                    return (
                      <Button
                        variant="outlined"
                        style={{
                          margin: 5,
                          backgroundColor: "rgba(255,255,255,0.35)",
                          border: "1px solid rgba(0,82,3,0.40)",
                          color: "darkcyan",
                          textTransform: "none"
                        }}
                        onClick={() => navigate(collection.link)}
                        endIcon={
                          collection.type === "libraryCollection" ? (
                            <LibraryIcon />
                          ) : collection.type === "bizCollection" ? (
                            <BizIcon />
                          ) : (
                            <MyIcon />
                          )
                        }
                        key={collection.id}
                      >
                        {collection.name}
                      </Button>
                    );
                  })}
                </>
              )}
            </CardContent>
          </Collapse>
        )}
      </Card>
    </ThemeProvider>
  );
}
