import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect
} from "react";
import Debug from "debug";
import _ from "lodash";
import { scroller } from "react-scroll";

import { Chip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AssetIcon from "./AssetIcon";

import useLongPress from "../../../hooks/useLongPress";
import useScreenSize from "../../../hooks/useScreenSize";
import { teamAssetTypes, teamStyles } from "../../../config/team";

import MasterTableTags from "./MasterTableTags";

const Log = Debug("MasterTableFilters");

export default function MasterTableFilters({
  tableFilters,
  saveTableFilters,
  tagCombo,
  saveTagCombo,
  tags,
  types
}) {
  /*
  Log("Start Render");
  useEffect(() => {
    Log("Finish Render");
  });
*/

  const [openTagsDialog, setOpenTagsDialog] = useState(false);
  const filters = useRef(tableFilters);

  // The asset types we display is based on what types are in the results and
  // what types this team supports.
  const assetTypes = useMemo(
    () => _.filter(teamAssetTypes(), type => types.includes(type.name)),
    [types]
  );
  const screenSize = useScreenSize(true);

  const activate = useCallback(
    type => {
      if (type === "tags") {
        Log(`Tags clicked`);
        setOpenTagsDialog(true);
        return;
      }

      if (filters.current[1].includes(type)) {
        Log(`REMOVE: ${type}`);
        // Currently true, so need to remove type from the array
        _.remove(filters.current[1], t => t === type);
      } else {
        Log(`ADD: ${type}`);
        // Currently false, so need to add type to the array
        if (!filters.current[1].includes(type)) filters.current[1].push(type);
      }

      Log(`Saving: ${filters.current[1]}`);

      saveTableFilters(filters.current);
    },
    [saveTableFilters]
  );

  const ts = useMemo(() => teamStyles(), []);

  const chipStyle = makeStyles(theme => ({
    root: {
      margin: `${
        screenSize.isSmallScreen ? "2px 6px 8px 0px" : "2px 6px 6px 2px"
      }`,
      borderRadius: "16px",
      paddingLeft: `${screenSize.isSmallScreen ? "14px" : "7px"}`,
      paddingRight: `${screenSize.isSmallScreen ? "7px" : "0px"}`,
      height: `${screenSize.isSmallScreen ? "42px" : "36px"}`
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 4,
      paddingRight: 4,
      whiteSpace: "nowrap"
    }
  }));

  const chipSelectedStyle = makeStyles(theme => ({
    root: {
      margin: `${
        screenSize.isSmallScreen ? "2px 6px 8px 0px" : "2px 6px 6px 2px"
      }`,
      borderRadius: "16px",
      paddingLeft: `${screenSize.isSmallScreen ? "14px" : "7px"}`,
      paddingRight: `${screenSize.isSmallScreen ? "7px" : "0px"}`,
      height: `${screenSize.isSmallScreen ? "42px" : "36px"}`
    },

    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 4,
      paddingRight: 4,
      whiteSpace: "nowrap"
    },
    colorPrimary: {
      background: ts.library.buttonType.bgSelected,
      border: ts.library.buttonType.borderSelected,
      boxShadow: ts.library.buttonType.boxShadowSelected
    }
  }));

  const chipClass = chipStyle();
  const chipSelectedClass = chipSelectedStyle();

  // When tableFilters changes we must update selected.current with the new selected filters.
  useEffect(() => {
    Log("useEffect filters changed : %o", tableFilters[1]);
    filters.current = [...tableFilters];
  }, [tableFilters]);

  const TypeChip = ({ type }) => {
    const [selected, setSelected] = useState(
      tableFilters[1].includes(type.name)
    );

    const mouseUp = useRef(false); // Track when the mouse/touch comes up from the click/longpress
    const ref = useRef(null); // Used to change the style of the button when longpress is activated

    // We only want to SEND the activate (which causes full re-render)
    // after the mouse/touch is released.
    const onLongPress = () => {
      Log(`${type.name} longpress is triggered`);
      setSelected(v => !v); // Set the button as selected so that new styles are applied
      ref.current.style.boxShadow = "0px 0px 20px 15px #fff500"; // Make the button highlight so use known selection has occured.
      if (navigator.vibrate) window.navigator.vibrate([100, 30]); // Only supported on android

      let intervalID = setInterval(() => {
        if (mouseUp.current) {
          clearInterval(intervalID);
          activate(type.name);
        }
      }, 50);
    };

    // It is not util the click/touch is finished that we will execute the activate (which re-renders EVERYTHING)
    const onEnd = () => {
      mouseUp.current = true;
    };

    const onClick = () => {
      Log(`${type.name} click is triggered`);
      // See if we need to toggle it on or off
      filters.current[1] =
        filters.current[1].length === 1 && filters.current[1][0] === type.name
          ? [] // If type.name is the only thing in the array, then a click means we need to toggle it OFF (so make the array empty)
          : [type.name]; // Else, a click means we toggle this type ON and only this one.
      saveTableFilters(filters.current);
    };

    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 400
    };

    const longPressEvent = useLongPress(
      onLongPress,
      onClick,
      onEnd,
      defaultOptions
    );

    return (
      <Chip
        {...longPressEvent}
        ref={ref}
        label={screenSize.isSmallScreen ? "" : type.label}
        variant={selected ? "default" : "outlined"}
        avatar={<AssetIcon type={type.name} isSelected={selected} />}
        classes={selected ? chipSelectedClass : chipClass}
        color="primary"
        clickable
      />
    );
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {tags.length !== 0 && (
          <Grid item>
            <Chip
              label={screenSize.isSmallScreen ? "" : "Tags"}
              variant={tableFilters[4].length > 0 ? "default" : "outlined"}
              onClick={() => activate("tags")}
              avatar={<AssetIcon type={"tags"} />}
              classes={
                tableFilters[4].length > 0 ? chipSelectedClass : chipClass
              }
              color="primary"
              clickable
            />
          </Grid>
        )}

        {assetTypes.map(type => (
          <Grid item key={type.name}>
            <TypeChip type={type} />
          </Grid>
        ))}
      </Grid>

      <MasterTableTags
        tableFilters={tableFilters}
        combo={tagCombo}
        save={(newFilters, tagCombo) => {
          saveTableFilters(newFilters);
          saveTagCombo(tagCombo);
          setTimeout(() => {
            scroller.scrollTo(`scroll_to_filters`, {
              duration: 2000,
              delay: 10,
              smooth: "easeOutQuart",
              offset: -60
            });
          }, 10);
        }}
        tags={tags}
        open={openTagsDialog}
        close={() => setOpenTagsDialog(false)}
      />
    </React.Fragment>
  );
}
