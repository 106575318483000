import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect
} from "react";
import Debug from "debug";
import _ from "lodash";
import MUIDataTable from "mui-datatables";
import { Element } from "react-scroll";
import Flag from "react-country-flag"; // https://www.npmjs.com/package/react-country-flag

import {
  ThemeProvider,
  IconButton,
  Container,
  Grid,
  InputBase,
  Paper,
  Box,
  InputAdornment,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core";

import { isAndroid } from "react-device-detect"; // https://www.npmjs.com/package/react-device-detect

import AssetIcon from "./AssetIcon";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { canEmbed } from "./EmbedAsset";

import useScreenSize from "../../../hooks/useScreenSize";
import useCountry from "../../../hooks/useCountry";
import { useGA } from "../../../hooks/ga";
import { teamInfo, teamStyles } from "../../../config/team";
import MasterTableFilters from "./MasterTableFilters";

// eslint-disable-next-line
const Log = Debug("MasterTable");

export default function MasterTable({
  loadContent,
  results,
  tags,
  pageName,
  getTableState,
  updateTableState,
  searchPlaceholder,
  hideSearch,
  hideFilters,
  showAddedDate,
  forcePageOne
}) {
  /*
  Log("Start Render");
  useEffect(() => {
    Log("Finish Render");
  });*/

  // Team info
  const team = useMemo(() => {
    return teamInfo();
  }, []);

  // Team Styles
  const ts = useMemo(() => {
    return teamStyles();
  }, []);

  // All of the filters applied to the master table. Must match the number of columns in the table
  const [tableFilters, setTableFilters] = useState(
    getTableState("tableFilters")
  );
  const [tagCombo, setTagCombo] = useState(getTableState("tagCombo"));
  const [searchText, setSearchText] = useState(getTableState("searchText"));
  const [page, setPage] = useState(forcePageOne ? 0 : getTableState("page"));

  // An array of all of the types that exist in the results .
  // e.g. ["blog","video","pdf"] etc.
  const types = useMemo(() => {
    return _.map(_.uniqBy(results, "type"), "type");
  }, [results]);

  const screenSize = useScreenSize();
  const country = useCountry();
  const ga = useGA();

  const viewLink = useCallback(
    link => {
      let a = document.createElement("a");
      a.href = link;
      a.target = screenSize.width > 800 ? "_blank" : "intrax";
      a.click();
    },
    [screenSize.width]
  );

  const onRowClick = useCallback(
    (rowData, rowMeta) => {
      if (canEmbed(results[rowMeta.dataIndex])) {
        loadContent(results[rowMeta.dataIndex]);
      } else {
        viewLink(results[rowMeta.dataIndex].url);
      }
    },
    [results, loadContent, viewLink]
  );

  const onFilterChange = useCallback(
    (changedColumn, filterList) => {
      setTableFilters([...filterList]);
      setPage(0);
      updateTableState({ tableFilters: [...filterList], page: 0 });
    },
    [updateTableState]
  );

  const onTableChange = useCallback(
    (action, state) => {
      //Log(`Action: ${action}`);
      //Log(state);

      if (action === "changePage" || action === "changeRowsPerPage") {
        updateTableState({ page: state.page, rowsPerPage: state.rowsPerPage });
        setPage(state.page);
      }

      if (action === "search")
        updateTableState({ searchText: state.searchText });
    },
    [updateTableState]
  );

  const customSearch = useCallback((searchQuery, currentRow, columns) => {
    if (
      currentRow[2].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || // name field
      currentRow[4].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || // tag field
      currentRow[8].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 // description field
    )
      return true;

    return false;
  }, []);

  // This is the actual field that is displayed to the user that
  // includes the asset icon and flag.
  const renderNameField = useCallback(
    (value, tableMeta, updateValue) => {
      /* tableMeta.rowData array
      0: id "17-ylau"
      1: type "pdf"
      2: name "Oils - Cardamom product info page"
      3: extra ""
      4: tags "singles,cooking,ylau,au"
      5: link "https://static.youngliving.com/en-AU/PIP/Cardamom.pdf"
      6: seealso ""
      7: share true
      8: description "Long description of this asset goes here"
      9: dateadded

       */

      const cCodeArr = _.filter(tableMeta.rowData[4].split(","), v =>
        _.includes(country.codesLC, v)
      );

      // cCode will contain the lowercased country code eg au if found in tags otherwise false.
      const cCode = cCodeArr.length === 0 ? false : cCodeArr[0];

      return (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {pageName !== "news" && (
            <Grid item>
              <IconButton
                aria-label="type"
                color="primary"
                style={{ marginRight: "8px", padding: "0px" }}
              >
                <AssetIcon
                  type={tableMeta.rowData[1]}
                  size={screenSize.width < 420 ? "default" : "large"}
                />
              </IconButton>
            </Grid>
          )}
          <Grid item xs>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs>
                {showAddedDate && (
                  <span style={{ color: "grey" }}>
                    {new Date(tableMeta.rowData[9]).toLocaleDateString(
                      undefined,
                      { month: "short", day: "numeric" }
                    )}
                    {"... "}
                  </span>
                )}
                {value}{" "}
                {tableMeta.rowData[1] === "video" &&
                  tableMeta.rowData[3].length > 0 && (
                    <>({tableMeta.rowData[3]})</>
                  )}
              </Grid>
              <Grid item>
                {cCode ? (
                  <Box
                    style={{
                      minHeight: 25,
                      minWidth: 50,
                      marginLeft: "5px"
                    }}
                  >
                    <Flag
                      countryCode={cCode}
                      title={cCode}
                      svg
                      style={{
                        height: "auto",
                        maxHeight: "25px",
                        width: "auto"
                      }}
                      cdnUrl="/images/flags-svg/"
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    },
    [country.codesLC, pageName, screenSize.width, showAddedDate]
  );

  // Column definitions for the MUIDataTable
  const columns = useMemo(() => {
    let colIndex = 0;
    return [
      {
        name: "id",
        label: "ID",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: true
        }
      },

      {
        name: "type",
        label: "Type",
        options: {
          display: "false",
          filter: true,
          filterList: tableFilters[colIndex++],
          filterType: "multiselect",
          customFilterListOptions: { render: v => `Type: ${v}` },
          sort: true
        }
      },

      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: true,
          customBodyRender: renderNameField
        }
      },

      {
        name: "extra",
        label: "Extra",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: false,
          viewColumns: false
        }
      },

      {
        name: "tags",
        label: "Tags",
        options: {
          display: "false",
          sort: true,
          filter: true,
          filterList: tableFilters[colIndex++],
          filterType: "multiselect",
          customFilterListOptions: {
            render: tag => {
              if (country.codesLC.includes(tag)) {
                return (
                  <Box
                    style={{
                      minHeight: 25,
                      minWidth: 50
                    }}
                  >
                    <Flag
                      countryCode={tag}
                      title={tag}
                      svg
                      style={{
                        height: "auto",
                        maxHeight: "25px",
                        width: "auto"
                      }}
                      cdnUrl="/images/flags-svg/"
                    />
                  </Box>
                );
              } else {
                return `Tag: ${tag}`;
              }
            }
          },
          filterOptions: {
            names: tags,
            logic(value, filterVal) {
              const assetTags = value.split(","); // Array of the assets tags
              const selectedTags = filterVal; // Array of the selected tags

              // If any of the selected tags are included in the assets tags array
              if (tagCombo === "or") {
                for (let i = 0; i < assetTags.length; i++) {
                  if (selectedTags.includes(assetTags[i].trim().toLowerCase()))
                    return false;
                }
              }

              // All selected tags MUST be included in the assets tags array.
              if (tagCombo === "and") {
                if (
                  _.isEqual(
                    selectedTags,
                    _.intersection(selectedTags, assetTags)
                  )
                ) {
                  return false;
                }
              }

              return true;
            }
          }
        }
      },

      {
        name: "url",
        label: "URL",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: false,
          viewColumns: false
        }
      },

      {
        name: "seealso",
        label: "See also",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: true
        }
      },

      {
        name: "share",
        label: "Sharable?",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: false,
          viewColumns: false
        }
      },

      {
        name: "desc",
        label: "Description",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: false,
          searchable: true
        }
      },

      {
        name: "added",
        label: "Date Added",
        options: {
          display: "false",
          filter: false,
          filterList: tableFilters[colIndex++],
          sort: false,
          searchable: false
        }
      }
    ];
  }, [tags, tableFilters, tagCombo, country.codesLC, renderNameField]);

  // All of the options for the MUIDataTable
  const options = useMemo(() => {
    return {
      page: page,
      rowsPerPage: getTableState("rowsPerPage"),
      rowsPerPageOptions: [10, 25, 50, 100, 500],
      filterType: "textField", // default search field type - can be over ridden
      searchOpen: false, // Initially show search bar
      searchText: searchText,
      print: false, // Hide the printer icon
      download: false, // Hide the download icon
      selectableRowsOnClick: false, // clicking row selects it
      viewColumns: `${
        process.env.REACT_APP_SERVER === "development" ? true : false
      }`,
      disableToolbarSelect: true,
      responsive: "scrollFullHeight",
      selectableRows: "none",
      onRowClick: onRowClick,
      onFilterChange: onFilterChange,
      customSearch: customSearch,
      onTableChange: onTableChange
    };
  }, [
    onRowClick,
    onFilterChange,
    customSearch,
    onTableChange,
    getTableState,
    searchText,
    page
  ]);

  // Style overrides for the MUIDataTable
  const theme = useCallback(
    currentTheme => {
      let newTheme = createMuiTheme({
        ...currentTheme,

        overrides: {
          ...currentTheme.overrides, // Inherit the previous overrides

          MUIDataTable: {
            paper: {
              background: ts.library.table.background,
              boxShadow: ts.library.table.boxShadow,
              borderRadius: "15px",
              marginLeft: `${isAndroid ? "6px" : "3px"}`,
              marginRight: `${isAndroid ? "6px" : "3px"}`
            }
          },

          MuiTableCell: {
            head: {
              backgroundColor: "inherit !important"
            },
            footer: {
              borderBottom: "0px"
            }
          },

          MUIDataTableBodyRow: {
            root: {
              height: "35px"
            }
          },

          MUIDataTableBodyCell: {
            root: {
              padding: "3px 5px",
              fontSize: "0.73rem",
              "@media (min-width:400px)": {
                fontSize: "0.83rem"
              },
              "@media (min-width:500px)": {
                padding: "4px 6px",
                fontSize: "0.88rem"
              },
              "@media (min-width:600px)": {
                padding: "5px 8px",
                fontSize: "0.92rem"
              },
              "@media (min-width:700px)": {
                fontSize: "0.95rem"
              },
              "@media (min-width:800px)": {
                padding: "6px 10px",
                fontSize: "1.0rem"
              },
              "@media (min-width:1200px)": {
                padding: "9px 12px",
                fontSize: "1.1rem"
              }
            }
          },

          MUIDataTableHeadCell: {
            root: {
              display: "none !important", // In future, will have settings switch to enable this for admins for debugging purposes.
              padding: "5px 10px"
            }
          },

          MuiTablePagination: {
            root: {
              padding: "0px !important",
              fontSize: "0.7rem",
              "@media (min-width:400px)": {
                fontSize: "0.8rem"
              },
              "@media (min-width:500px)": {
                fontSize: "0.85rem"
              },
              "@media (min-width:600px)": {
                fontSize: "0.9rem"
              },
              "@media (min-width:700px)": {
                fontSize: "0.95rem"
              },
              "@media (min-width:800px)": {
                fontSize: "1.0rem"
              },
              "@media (min-width:1200px)": {
                fontSize: "1.1rem"
              }
            },

            caption: {
              fontSize: "0.7rem",
              "@media (min-width:400px)": {
                fontSize: "0.8rem"
              },
              "@media (min-width:500px)": {
                fontSize: "0.85rem"
              },
              "@media (min-width:600px)": {
                fontSize: "0.9rem"
              },
              "@media (min-width:700px)": {
                fontSize: "0.95rem"
              },
              "@media (min-width:800px)": {
                fontSize: "1.0rem"
              },
              "@media (min-width:1200px)": {
                fontSize: "1.1rem"
              }
            },

            actions: {
              marginLeft: "0px",
              "@media (min-width:500px)": {
                marginLeft: "4px"
              },
              "@media (min-width:600px)": {
                marginLeft: "18px"
              },
              "@media (min-width:700px)": {
                marginLeft: "20px"
              },
              "@media (min-width:800px)": {
                marginLeft: "22px"
              },
              "@media (min-width:1200px)": {
                marginLeft: "25px"
              }
            }
          },

          MUIDataTableToolbar: {
            root: {
              display: "none !important" // In future, will have settings switch to enable this for admins for debugging purposes.
            },
            filterPaper: {
              width: "300px"
            }
          },

          MuiCheckbox: {
            root: {
              padding: "0px"
            }
          },

          MuiButton: {
            root: {
              textTransform: "none"
            }
          }
        }
      });
      newTheme = responsiveFontSizes(newTheme);

      return newTheme;
    },
    [ts]
  );

  // If the results have changed, then we need to check if any of the filters
  // have changed as well.
  useEffect(() => {
    if (tableFilters !== getTableState("tableFilters"))
      setTableFilters(getTableState("tableFilters"));

    if (tagCombo !== getTableState("tagCombo"))
      setTagCombo(getTableState("tagCombo"));

    if (searchText !== getTableState("searchText"))
      setSearchText(getTableState("searchText"));

    //Log("RESULTS CHANGED!!!");
    if (forcePageOne) {
      //Log("FORCING PAGE ONE");
      updateTableState({ page: 0 });
      setPage(0);
    } else {
      if (page !== getTableState("page")) setPage(getTableState("page"));
    }

    // eslint-disable-next-line
  }, [results]);

  if (results === 0) return null;

  return (
    <ThemeProvider theme={currentTheme => theme(currentTheme)}>
      <Container maxWidth="sm" style={{ marginBottom: "15px" }}>
        <Element name="scroll_to_filters" />

        {results.length !== 0 && !hideSearch && (
          <SearchField
            searchPlaceholder={searchPlaceholder}
            initialSearchText={searchText}
            update={value => {
              updateTableState({ searchText: value });
              setSearchText(value);
            }}
            gaRecord={value => {
              // Record the search text in Google Analyitics
              if (value.length > 2)
                ga.ReactGA.event({
                  category: pageName,
                  action: "Search",
                  label: value
                });
            }}
          />
        )}

        {pageName === "news" && <Box style={{ marginTop: "25px" }}></Box>}

        {!hideFilters && (
          <MasterTableFilters
            tableFilters={tableFilters}
            saveTableFilters={filters => {
              setTableFilters([...filters]);
              setPage(0);
              updateTableState({ tableFilters: [...filters], page: 0 });
            }}
            tagCombo={tagCombo}
            saveTagCombo={combo => {
              setTagCombo(combo);
              setPage(0);
              updateTableState({ tagCombo: combo, page: 0 });
            }}
            tags={tags}
            types={types}
          />
        )}
        <MUIDataTable
          title={team.libraryTableHeading}
          data={results}
          columns={columns}
          options={options}
        />
      </Container>
    </ThemeProvider>
  );
}

function SearchField({
  initialSearchText,
  update,
  gaRecord,
  searchPlaceholder
}) {
  const paperRef = useRef(null);
  const inputRef = useRef(null);

  // We debounce this for performance reasons when a person is typing in a search
  const updateSearchText = useCallback(
    _.debounce(value => {
      update(value);
    }, 400),
    []
  );

  // We record the search text in google analyitics but we debounce
  // for 5 seconds to make sure they have finished typing.
  const gaRecordSearchText = useCallback(
    _.debounce(value => {
      gaRecord(value);
    }, 5000),
    []
  );

  // If initialSearchText changes, we need to updateSearchText
  // This occurs in circumstances like mycollections, when selecting a new
  // collection changes the results
  useEffect(() => {
    // Only perform this update IF they are not currently typing in the search field.
    if (inputRef.current.children[0] !== document.activeElement) {
      inputRef.current.children[0].value = initialSearchText;
      inputRef.current.children[0].focus();
      inputRef.current.children[0].blur();
    }
  }, [initialSearchText]);

  return (
    <Paper
      ref={paperRef}
      component="form"
      style={{
        padding: "3px 10px 1px 4px",
        display: "flex",
        alignItems: "center",
        width: "92%",
        borderRadius: "25px",
        height: "30px",
        margin: `${isAndroid ? "24px 2vw 7px 2vw" : "6px 2vw 7px 2vw"}`,
        backgroundColor: `${
          initialSearchText.length === 0 ? "lightgrey" : "#eaf0f6"
        }`
      }}
    >
      <IconButton
        style={{ padding: 4 }}
        aria-label="search-icon"
        disabled={initialSearchText.length === 0}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={inputRef}
        placeholder={searchPlaceholder || "Search"}
        inputProps={{ "aria-label": "search" }}
        defaultValue={initialSearchText}
        onChange={e => {
          updateSearchText(e.target.value);
          gaRecordSearchText(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="clear"
              size="small"
              onClick={() => {
                update("");
                inputRef.current.children[0].value = "";
                inputRef.current.children[0].focus();
                inputRef.current.children[0].blur();
              }}
            >
              {initialSearchText.length === 0 ? "" : <ClearIcon />}
            </IconButton>
          </InputAdornment>
        }
        onFocus={e => {
          paperRef.current.style.backgroundColor = "#d7e7f7";
          paperRef.current.style.boxShadow =
            "0px 2px 11px -1px rgb(51 0 255 / 52%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)";
        }}
        onBlur={e => {
          paperRef.current.style.backgroundColor = "lightgrey";

          if (e.target.value.length === 0) {
            paperRef.current.style.boxShadow = "inherit";
            inputRef.current.style.color = "inherit";
          } else {
            inputRef.current.style.color = "blueviolet";
            paperRef.current.style.backgroundColor = "#eaf0f6";
            paperRef.current.style.boxShadow =
              "0px 2px 1px -1px rgb(27 0 255 / 53%), 0px 1px 1px 0px rgb(27 0 255 / 45%), 0px 1px 3px 0px rgb(16 0 255 / 54%)";
          }
        }}
        style={{
          flex: 1,
          color: `${initialSearchText.length === 0 ? "inherit" : "blueviolet"}`
        }}
      />
    </Paper>
  );
}
