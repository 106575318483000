import React, { useEffect, useCallback } from "react";
import Debug from "debug";
import { useRoutes, navigate } from "hookrouter";

import { Fade, Box } from "@material-ui/core";

import PageVisibility from "react-page-visibility"; // https://www.npmjs.com/package/react-page-visibility

import { Root, Content } from "./layout";
import AppBottomButtons from "./AppBottomButtons";
import AppNav from "./AppLeftNav";
import LibraryPage from "./views/LibraryPage";
import LibraryNewPage from "./views/LibraryNewPage";
import FavouritesPage from "./views/FavouritesPage";
import ViewLaterPage from "./views/ViewLaterPage";
import BizPage from "./views/BizPage";
import BizNewPage from "./views/BizNewPage";
import TeamPage from "./views/TeamPage";
import NewsPage from "./views/NewsPage";
import TutorialPage from "./views/TutorialPage";
import CollectionsPage from "./views/CollectionsPage";
import AboutPage from "./views/AboutPage";
import ChangePassword from "./settings/ChangePassword";
import SettingsPage from "./settings/";
import AssetIssues from "./admin/AssetIssues";

//import Home from "./home/";

import { useAuth } from "../../hooks/auth";
import { useGA } from "../../hooks/ga";

const config = {
  navAnchor: "left",
  navVariant: {
    xs: "temporary",
    sm: "temporary",
    md: "persistent",
    lg: "permanent"
  },
  navWidth: {
    xs: 240,
    sm: 256,
    md: 270,
    lg: 300,
    xl: 330
  },
  collapsible: {
    xs: false,
    sm: false,
    md: false
  },
  collapsedWidth: {
    xs: 64,
    sm: 64,
    md: 64
  },
  clipped: {
    xs: false,
    sm: false,
    md: false
  },
  headerPosition: {
    xs: "fixed",
    sm: "fixed",
    md: "fixed"
  },
  squeezed: {
    xs: false,
    sm: false,
    md: true
  },
  footerShrink: {
    xs: true,
    sm: true,
    md: true
  }
};

const Log = Debug("App");

export default function IntraxApp({ library }) {
  const auth = useAuth();
  const ga = useGA();

  // Initialise debugging if logged in user is 1018897 (Andrew)
  if (
    auth.isAuthenticated &&
    auth.user &&
    auth.user.number === 1018897 &&
    !localStorage.getItem("debug")
  )
    localStorage.setItem("debug", "*");

  // This is where we execute functions that we need to do every x mins.
  // Doing it this way ensures that it occurs when the user has made the page visible.
  const handleVisibilityChange = useCallback(
    (isVisible, visibilityState) => {
      //Log(`Visible: ${isVisible} State: ${visibilityState}`);

      if (isVisible) {
        // The state has changed from being not visible to being visible
        const checkMins = 120 * 60 * 1000; // How often should we check for updates to the library?
        const lastChecked = parseInt(
          localStorage.getItem("library_checked") || // The OR is just incase the getItem fails cause library_checked doesnt exist yet
            new Date().getTime() - checkMins - 100
        );

        if (new Date().getTime() > lastChecked + checkMins) {
          localStorage.setItem("library_checked", new Date().getTime());
          Log(`Gonna do a library check.`);
          library.checkForUpdate();
        }
      }
    },
    [library]
  );

  const routes = {
    "/": () => {
      ga.ReactGA.pageview("/app/news", [], "News");
      return <NewsPage library={library} auth={auth} />;
    },
    "/news": () => {
      ga.ReactGA.pageview("/app/news", [], "News");
      return <NewsPage library={library} auth={auth} />;
    },
    "/news/:selectedID": ({ selectedID }) => {
      ga.ReactGA.pageview("/app/news", [], "News");
      return <NewsPage library={library} auth={auth} selectedID={selectedID} />;
    },

    "/library": () => {
      ga.ReactGA.pageview("/app/library", [], "Library Assets");
      return <LibraryPage library={library} auth={auth} />;
    },

    "/library_new": () => {
      ga.ReactGA.pageview("/app/library_new", [], "Library what's new");
      return <LibraryNewPage library={library} auth={auth} />;
    },

    "/fav": () => {
      ga.ReactGA.pageview("/app/fav", [], "Favourites");
      return <FavouritesPage library={library} auth={auth} />;
    },

    "/later": () => {
      ga.ReactGA.pageview("/app/later", [], "View Later");
      return <ViewLaterPage library={library} auth={auth} />;
    },

    "/biz": () => {
      ga.ReactGA.pageview("/app/biz", [], "Business Assets");
      return <BizPage library={library} auth={auth} />;
    },

    "/biz_new": () => {
      ga.ReactGA.pageview("/app/biz_new", [], "Business what's new");
      return <BizNewPage library={library} auth={auth} />;
    },

    "/team": () => {
      ga.ReactGA.pageview("/app/team", [], "Team");
      return <TeamPage library={library} auth={auth} />;
    },

    "/tutorial_assets": () => {
      ga.ReactGA.pageview("/app/tutorial_assets", [], "Tutorial Assets");
      return <TutorialPage library={library} auth={auth} />;
    },

    "/tutorials": () => {
      ga.ReactGA.pageview("/app/tutorials", [], "App Tutorials");
      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="tut"
          heading="App Tutorials"
        />
      );
    },

    "/tutorials/:selectedCollection": ({ selectedCollection }) => {
      ga.ReactGA.pageview("/app/tutorials", [], "App Tutorials");
      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="tut"
          heading="App Tutorials"
          selectedCollection={selectedCollection}
        />
      );
    },

    "/my_collections": () => {
      ga.ReactGA.pageview("/app/my_collections", [], "My Collections");
      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="my"
          heading="My Collections"
        />
      );
    },

    "/my_collections/:selectedCollection": ({ selectedCollection }) => {
      ga.ReactGA.pageview("/app/my_collections", [], "My Collections");

      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="my"
          heading="My Collections"
          selectedCollection={selectedCollection}
        />
      );
    },

    "/library_collections": () => {
      ga.ReactGA.pageview(
        "/app/library_collections",
        [],
        "Library Collections"
      );
      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="library"
          heading="Library Collections"
        />
      );
    },

    "/library_collections/:selectedCollection": ({ selectedCollection }) => {
      ga.ReactGA.pageview(
        "/app/library_collections",
        [],
        "Library Collections"
      );

      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="library"
          heading="Library Collections"
          selectedCollection={selectedCollection}
        />
      );
    },

    "/biz_collections": () => {
      ga.ReactGA.pageview("/app/biz_collections", [], "Business Collections");
      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="biz"
          heading="Biz Collections"
        />
      );
    },

    "/biz_collections/:selectedCollection": ({ selectedCollection }) => {
      ga.ReactGA.pageview("/app/biz_collections", [], "Business Collections");

      return (
        <CollectionsPage
          library={library}
          auth={auth}
          collectionType="biz"
          heading="Biz Collections"
          selectedCollection={selectedCollection}
        />
      );
    },

    "/about": () => {
      ga.ReactGA.pageview("/app/about", [], "About");
      return <AboutPage library={library} auth={auth} />;
    },

    "/settings": () => {
      ga.ReactGA.pageview("/app/settings", [], "Settings");
      return <SettingsPage library={library} auth={auth} />;
    },

    "/password": () => {
      ga.ReactGA.pageview("/app/password", [], "Password");
      return <ChangePassword auth={auth} />;
    },

    "/issues": () => {
      ga.ReactGA.pageview("/app/issues", [], "Asset Issues");
      return <AssetIssues />;
    }
  };

  const Routes = () => {
    const routeResult = useRoutes(routes);
    return routeResult;
  };

  // To access anything in the app section you must be logged in.
  useEffect(() => {
    if (!auth.isAuthenticated) navigate("/login");
    ga.ReactGA.set({ userId: auth.user.number });
  }, [auth.isAuthenticated, auth.user.number, ga]);

  if (!auth.isAuthenticated) {
    return null;
  }

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <Root config={config} style={{ minHeight: "100vh" }}>
        <AppBottomButtons />
        <AppNav library={library} />
        <Content>
          <Fade in={true} timeout={250}>
            <Box>
              <Routes />
            </Box>
          </Fade>
        </Content>
      </Root>
    </PageVisibility>
  );
}
