import React from "react";

import { Container, Typography } from "@material-ui/core";

import {
  isAndroid,
  isIOS,
  isMobile, // true if mobile or tablet
  isMobileOnly,
  isBrowser,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isEdge,
  isEdgeChromium,
  isChromium,
  isSmartTV,
  isTablet,
  isMobileSafari,
  isWindows,
  isMacOs,
  isConsole,
  isWearable,
  osVersion,
  osName,
  getUA,
  deviceType,
  mobileVendor,
  mobileModel,
  browserName,
  browserVersion,
  fullBrowserVersion,
  engineName,
  engineVersion
} from "react-device-detect";

import isWebView from "is-ua-webview";

export default function BrowserCheck() {
  // If they are not on a mobile device, then let them know what they need to do.

  return (
    <Container maxWidth="sm" style={{ paddingTop: "25px" }}>
      <Typography variant="h5" gutterBottom>
        WebView?
      </Typography>
      {isWebView(getUA) ? (
        <Typography>TRUE webview</Typography>
      ) : (
        <Typography>FALSE webview</Typography>
      )}
      <Typography variant="h5" gutterBottom>
        OS
      </Typography>
      <Typography>osName: {osName}</Typography>
      <Typography>osVersion: {osVersion}</Typography>
      {isWindows && <Typography>isWindows</Typography>}
      {isMacOs && <Typography>isMacOs</Typography>}
      <Typography>mobileVendor: {mobileVendor}</Typography>
      <Typography>mobileModel: {mobileModel}</Typography>

      <Typography variant="h5" gutterBottom style={{ paddingTop: "15px" }}>
        Browser
      </Typography>
      <Typography>browserName: {browserName}</Typography>
      <Typography>browserVersion: {browserVersion}</Typography>
      <Typography>fullBrowserVersion: {fullBrowserVersion}</Typography>
      <Typography>engineName: {engineName}</Typography>
      <Typography>engineVersion: {engineVersion}</Typography>

      <Typography variant="h5" gutterBottom style={{ paddingTop: "15px" }}>
        Browser Check
      </Typography>
      <Typography>User Agent: {getUA}</Typography>
      <Typography>Device Type: {deviceType}</Typography>
      {isMobile && <Typography>isMobile</Typography>}
      {isMobileOnly && <Typography>isMobileOnly</Typography>}
      {isTablet && <Typography>isTablet</Typography>}
      {isBrowser && <Typography>isBrowser</Typography>}
      {isSmartTV && <Typography>isSmartTV</Typography>}
      {isWearable && <Typography>isWearable</Typography>}
      {isConsole && <Typography>isConsole</Typography>}
      {isAndroid && <Typography>isAndroid</Typography>}
      {isIOS && <Typography>isIOS</Typography>}
      {isChrome && <Typography>isChrome</Typography>}
      {isFirefox && <Typography>isFirefox</Typography>}
      {isSafari && <Typography>isSafari</Typography>}
      {isMobileSafari && <Typography>isMobileSafari</Typography>}
      {isEdge && <Typography>isEdge</Typography>}
      {isEdgeChromium && <Typography>isEdgeChromium</Typography>}
      {isOpera && <Typography>isOpera</Typography>}
      {isChromium && <Typography>isChromium</Typography>}
    </Container>
  );
}
