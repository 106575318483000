import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

let firebaseConfig = {
  apiKey: "AIzaSyA12_3vAqcTqGlb1uE_aL1ygnsPdvtYW2s",
  authDomain: "intrax-dev.firebaseapp.com",
  databaseURL: "https://intrax-dev.firebaseio.com",
  projectId: "intrax-dev",
  storageBucket: "intrax-dev.appspot.com",
  messagingSenderId: "20221924198",
  appId: "1:20221924198:web:5122f4e811cac86c9aeea7",
  measurementId: "G-LFTWHYV0PC"
};

if (process.env.REACT_APP_SERVER === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyBrqtkeine7qN2THMNLEqxkEiaTPhGB-dg",
    authDomain: "intrax-prod.firebaseapp.com",
    databaseURL: "https://intrax-prod.firebaseio.com",
    projectId: "intrax-prod",
    storageBucket: "intrax-prod.appspot.com",
    messagingSenderId: "651637309142",
    appId: "1:651637309142:web:d024fade56747106839377",
    measurementId: "G-6221P7CXZ9"
  };
}

let returnFB;

if (!firebase.apps.length) {
  returnFB = firebase.initializeApp(firebaseConfig);

  // This means that data from remote DB is stored in a local DB on supported browsers
  // which are Chrome, Safari and Firefox. This will enable "offline" access
  // as well as faster response times to queries that have previously been run.
  // For more info on persistence see:
  // https://firebase.google.com/docs/firestore/manage-data/enable-offline
  returnFB
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(function(err) {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log(
          "Multiple tabs open and persistence can only be enabled in one tab at a time."
        );
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log(
          "Current browser does not support all of the features required to enable persistence"
        );
      }
    });
}

export default !firebase.apps.length ? returnFB : firebase.app();
