// Returns the Google Analyitics tracking ID based on the team and
// if we are on production or development
const gaTrackingId = () => {
  let gaCodes = {
    twf: {
      dev: "UA-171437182-1",
      prod: "UA-171437182-2"
    }
  };

  return process.env.REACT_APP_SERVER === "production"
    ? gaCodes[process.env.REACT_APP_TEAM].prod
    : gaCodes[process.env.REACT_APP_TEAM].dev;
};

// Not actually used anymore... Need to review.
const teamInfo = () => {
  let info = {
    libraryTableHeading: "Library"
  };

  if (process.env.REACT_APP_TEAM === "twf") {
    info = {
      ...info,
      libraryTableHeading: "Team Wild Fire Library",
      teamLeaderName: "Sharon Wild",
      teamLeaderEmail: "Sharon@TeamWildFire.org"
    };
  }

  return info;
};

const teamStyles = () => {
  // Set all of the defaults here, then override below.
  let styles = {
    library: {
      buttonType: {
        // Type button when selected (pdf, ebook etc button)
        bgSelected: "linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)",
        borderSelected: "1px solid rgba(30, 28, 199, 0.84)",
        boxShadowSelected: "0px 0px 3px 0px #3e2faf"
      },
      table: {
        // Library Table
        background: "linear-gradient(120deg, #e8faeaa8 0%, #cdedf68c 100%)",
        boxShadow: "inset 0px -4px 20px 0px #2faf7461"
      }
    }, // library END

    card: {
      // Asset Card
      background: "linear-gradient(120deg, #c7f7cc 0%, #a7e7f9 100%)",
      boxShadow: "inset -1px -4px 20px 2px #2faf74ad"
    }, // Asset Card END

    saCard: {
      // See Also Card
      background: "linear-gradient(120deg, #e3ffe7 15%, #d9e7ff 100%)",
      boxShadow: "inset -2px -4px 10px 0px #6eb3ad"
    } // See Also Card END
  };

  // EXAMPLE for how to customise the default styles.
  if (process.env.REACT_APP_TEAM === "TEAM_HERE") {
    styles = {
      ...styles,
      library: {
        ...styles.library,
        buttonType: {
          ...styles.library.buttonType,
          bgSelected: "linear-gradient(90deg, #a600ff 0%, #3a47d5 100%)"
        }
      }
    };
  }

  return styles;
};

const teamAssetTypes = () => {
  // Team specific asset type definitions
  if (process.env.REACT_APP_TEAM === "customTeamNameHere") {
    return [];
  }

  // Use the default teamAssetTypes

  return [
    { name: "audio", label: "Audio", longLabel: "Audio" },
    { name: "blog", label: "Blog", longLabel: "Blog" },
    { name: "ebook", label: "Ebook", longLabel: "Ebook" },
    { name: "fb", label: "FB", longLabel: "Facebook" },
    { name: "ig", label: "IG", longLabel: "Instagram" },
    { name: "image", label: "Image", longLabel: "Image" },
    { name: "link", label: "Link", longLabel: "Link" },
    { name: "pdf", label: "PDF", longLabel: "pdf" },
    { name: "video", label: "Video", longLabel: "Video" }
  ];
};

export { gaTrackingId, teamAssetTypes, teamInfo, teamStyles };
