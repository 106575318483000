import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  forwardRef
} from "react";
import Debug from "debug";
import _ from "lodash";
import { Element, scroller } from "react-scroll";
import arrayMove from "array-move";
import { useSnackbar } from "notistack";

import firebase from "../../../config/firebase";
import "firebase/firestore";

import {
  CircularProgress,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table"; // https://material-table.com/#/docs/all-props
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import CollectionAddIcon from "@material-ui/icons/PlaylistAdd";
import LibraryIcon from "@material-ui/icons/LocalLibraryOutlined";
import LibraryIconActive from "@material-ui/icons/LocalLibrary";
import BizIcon from "@material-ui/icons/BusinessCenterOutlined";
import BizIconActive from "@material-ui/icons/BusinessCenter";
import TutorialsIcon from "@material-ui/icons/ImportantDevicesSharp";
import TutorialsIconActive from "@material-ui/icons/ImportantDevicesTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useGA } from "../../../hooks/ga";
import Page from "../library/Page";

const Log = Debug("Collections");

export default function Collections({
  library,
  auth,
  collectionType,
  heading,
  selectedCollection,
  selectedID
}) {
  const [results, setResults] = useState([]);
  const [collection, setCollection] = useState({}); // Currently selected collection object
  const [collections, setCollections] = useState(() => {
    return _.sortBy(library.getCollections(collectionType), ["name"]);
  }); // All of the collections
  const [reorderDialog, setReorderDialog] = useState(false);
  const [ecDialog, setEcDialog] = useState(false); // Edit Collections dialog
  const [expanded, setExpanded] = useState({}); // Used to store the expanded state of the group accordians

  const ga = useGA();

  // Returns the name of the group given the passed in collection name
  // e.g.  Emotions / Feelings Kit would return Emotions
  function getGroupName(name) {
    if (name.match(/ \/ /)) {
      // The name will be everything before the first slash
      return name.match(/^([^/]+) \/ /)[1].trim();
    } else {
      return name;
    }
  }

  // Grouping all of the collections so that we know how to display the collection
  // - as a single entry or as a group.
  const collectionGroups = useMemo(() => {
    let groups = []; // groups["Emotions"] = [array of collections]

    collections.map(c => {
      // What is the group name for this collection???
      const groupName = getGroupName(c.name);

      if (groups[groupName]) {
        groups[groupName].push(c);
      } else {
        groups[groupName] = [c];
      }

      return c;
    });

    return groups;
  }, [collections]);

  // Collection is clicked from the list
  const selectCollection = useCallback(
    c => {
      library.resetTableState(`${collectionType}collections`); // Each time a new collection is selected, we reset the table state (search/tag filters, page number etc)
      setCollection(c);
      // A collection has been selected so we need to set the results to
      // all of the assets that are in that collection.
      const assetsInCollection = library.getAssetsInCollection(
        collectionType,
        c.id
      );

      // We respect the order of the assets in the collection. So put together the
      // ordered list of assets.
      let orderedResults = [];
      assetsInCollection.map(assetID => {
        let asset = _.find(library.results, { id: assetID });
        if (asset) orderedResults.push(asset);
        return 0;
      });

      // Why do we set the results to be empty here? Then update them 50ms later?
      // Because results.length
      // will now equal zero, this will cause a re-render and unmount of the <Page
      // which is needed to re-set the page number back to zero. Wierd I know,
      // but a little fix we need to do because of the way the MUIDataTable works.
      setResults([]);

      setTimeout(() => {
        setResults(orderedResults);
      }, 50);

      setTimeout(() => {
        scroller.scrollTo(`scroll_to_assets`, {
          duration: 2000,
          delay: 10,
          smooth: "easeOutQuart",
          offset: 0
        });
      }, 100);

      ga.ReactGA.event({
        category: `${collectionType}collections`,
        action: "View Collection",
        label: `${c.name} [${c.id}]`
      });
    },
    [library, collectionType, ga]
  );

  // Saving a re-ordered list of assets
  const saveReorderedCollection = useCallback(
    newResults => {
      setResults(newResults);
      library.updateCollection({
        ...collection,
        assets: newResults.map(r => r.id)
      });
    },
    [collection, library]
  );

  // Save any updated/deleted collections returned from the Edit Collections Dialog
  const saveEditedCollections = useCallback(
    updatedCollections => {
      let currentCollections = [...collections];

      // See if any of the collections have been EDITED (name or desc)
      // and if so, then update it.
      currentCollections.map(c => {
        const updatedCollection = _.find(updatedCollections, { id: c.id }); // Returns undefined if it doesnt find it

        if (
          updatedCollection && // If we have an updated collection object
          (c.name !== updatedCollection.name || // and the name or description has changed
            c.desc !== updatedCollection.desc)
        ) {
          delete updatedCollection.tableData;
          delete c.tableData;

          // There has been an update to the name or description so update this collection in the database
          library.updateCollection({
            ...c,
            name: updatedCollection.name,
            desc: updatedCollection.desc
          });

          // Update the collection in the state and re-render results.
          setCollections(prev => {
            let newCollections = [...prev];
            newCollections[_.findIndex(prev, { id: c.id })] = {
              ...c,
              name: updatedCollection.name,
              desc: updatedCollection.desc
            };
            return newCollections;
          });

          Log(`Updated collection name or desc on ${c.id}`);
        }
        return c;
      });

      // See if any of the collections have been DELETED, if so,
      // call deleteCollection on each of them.
      let removedCollections = _.filter(
        currentCollections,
        c => !updatedCollections.map(v => v.id).includes(c.id)
      );

      if (removedCollections.length > 0) {
        Log(`${removedCollections.length} collections have been deleted`);
        // Go through each removed collection and delete it.
        removedCollections.map(c => {
          library.deleteCollection(c);
          setCollections(prev => _.filter(prev, col => c.id !== col.id));
          return c;
        });
      }
    },
    [library, collections]
  );

  // Component to display a collection row
  const CollectionRow = ({ c }) => {
    const useStyles = makeStyles(theme => ({
      collectionRow: {
        padding: "10px 5px 10px 8px",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        color: `${collection.id === c.id ? "#f57f17" : "inherit"}`,
        backgroundColor: `${collection.id === c.id ? "#fff176" : "#ffffff36"}`,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f5f5f5a3"
        }
      },
      descriptionText: { fontSize: "75%", color: "#757575" }
    }));

    const classes = useStyles();

    return (
      <Grid item key={c.id} className={classes.collectionRow}>
        <Grid container wrap="nowrap">
          <Grid item xs zeroMinWidth onClick={() => selectCollection(c)}>
            <Typography
              noWrap
              style={{
                fontSize: "85%"
              }}
            >
              {c.name}
              {c.desc.length > 0 && (
                <span className={classes.descriptionText}> - {c.desc}</span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // Component to display a collection group
  const CollectionGroup = ({ groupName }) => {
    const handleChange = panel => (event, isExpanded) => {
      setExpanded(v => {
        return {
          ...v,
          [panel]: isExpanded
        };
      });
    };

    return (
      <Accordion
        expanded={expanded[groupName]}
        onChange={handleChange(groupName)}
        style={{ backgroundColor: "rgb(251 255 0 / 10%)" }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontSize: "85%" }}>{groupName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            wrap="nowrap"
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            {collectionGroups[groupName].map(c => (
              <CollectionRow c={c} key={c.id} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  // Fetch the results
  useEffect(() => {
    if (results.length === 0) {
      if (library.results.length === 0) {
        Log("Waiting for local indexedDB library.results to return data");
        return;
      }
    }
  }, [library, library.results, results.length]);

  // If an initial collection has been passed in via selectedCollection, then select that collection.
  useEffect(() => {
    if (selectedCollection) {
      // Find the collection
      let foundCollection = _.find(
        collections,
        c => c.id === selectedCollection
      );
      if (foundCollection) selectCollection(foundCollection);
    }
  }, [selectedCollection, collections, selectCollection]);

  if (library.results.length === 0)
    return (
      <Container maxWidth="sm">
        <Typography variant="h4">Loading</Typography>
        <CircularProgress />
      </Container>
    );

  if (collections.length === 0) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5">{heading}</Typography>
        <Typography variant="body2" gutterBottom>
          You have not created any collections yet. To create a collection,
          simply view an asset then add that asset to a new collection by
          clicking the button that looks like this <CollectionAddIcon />
        </Typography>
        <Typography variant="body2" gutterBottom>
          After you do that, the collection will appear here. From here you will
          be able to re-sequence the assets in your collection and delete them
          if you no longer want them in the collection.
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="sm"
      style={{ paddingTop: "5px", paddingBottom: "40px" }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="h5">{heading}</Typography>
        </Grid>
        {collectionType === "my" && (
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setEcDialog(true)}
              startIcon={<EditIcon />}
              style={{ fontSize: "65%" }}
            >
              Edit
            </Button>
          </Grid>
        )}
      </Grid>

      <Typography variant="body2" gutterBottom>
        Tap a collection
      </Typography>
      <Paper
        style={{
          maxWidth: "98%",
          margin: "10px 2px 30px 2px",
          padding: "5px 0px 5px 0px",
          background: "linear-gradient(120deg, #fffde7 0%, #fff9c4 100%)",
          boxShadow: "rgb(197 169 44) 1px 1px 8px 0px",
          borderRadius: "7px"
        }}
      >
        <Grid
          container
          wrap="nowrap"
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          {Object.keys(collectionGroups).map(groupName => (
            <React.Fragment key={`${groupName}_cg`}>
              {collectionGroups[groupName].length === 1 ? (
                <CollectionRow c={collectionGroups[groupName][0]} />
              ) : (
                <CollectionGroup groupName={groupName} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
      <Element name="scroll_to_assets" />
      {results.length > 0 && (
        <>
          <Box style={{ marginBottom: "15px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs>
                <Typography variant="h6" style={{ color: "#263238" }}>
                  {collection.name}
                </Typography>
              </Grid>
              {collectionType === "my" && (
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setReorderDialog(true)}
                    startIcon={<EditIcon />}
                    style={{ fontSize: "65%" }}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography style={{ fontSize: "75%", color: "#757575" }}>
                  {collection.desc}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Page
            page={`${collectionType}collections`}
            results={results}
            library={library}
            selectedID={selectedID}
            searchPlaceholder={`Search ${collection.name.toLowerCase()} collection`}
            hideSearch={true}
            hideFilters={true}
            forcePageOne={true}
          />
        </>
      )}

      <ReorderAssets
        assets={results}
        show={reorderDialog}
        onClose={() => setReorderDialog(false)}
        onSave={saveReorderedCollection}
      />

      <EditCollections
        collections={collections}
        library={library}
        auth={auth}
        show={ecDialog}
        onClose={() => setEcDialog(false)}
        onSave={saveEditedCollections}
      />
    </Container>
  );
}

// Shows the dialog that contains the ability to re-order assets.
function ReorderAssets({ show, onClose, onSave, assets }) {
  const results = useRef(assets);
  // eslint-disable-next-line
  const [deleteAssets, setDeleteAssets] = useState([]);

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions);

  const [open, setOpen] = React.useState(show);

  const handleClose = () => {
    results.current = assets; // Reset (cancel) any changes made
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    results.current = assets;
  }, [assets]);

  const onBeforeCapture = useCallback(() => {
    /*...*/
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /*...*/
  }, []);
  const onDragStart = useCallback(() => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }, []);
  const onDragUpdate = useCallback(() => {
    /*...*/
  }, []);
  const onDragEnd = useCallback(dropResult => {
    // the only one that is required
    results.current = arrayMove(
      results.current,
      dropResult.source.index,
      dropResult.destination.index
    );
  }, []);

  const removeAssetFromCollection = useCallback(assetID => {
    results.current = _.filter(results.current, asset => asset.id !== assetID);
    setDeleteAssets(arr => [...arr, assetID]); // We dont use this anywhere, but it does cause a re-render which we need here.
  }, []);

  const useStyles = makeStyles({
    paper: {
      margin: "5px"
    },

    paperFullWidth: {
      width: "calc(100% - 2vw)",
      maxWidth: "600px !important"
    }
  });

  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="reorder-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        classes={{
          paper: classes.paper,
          paperFullWidth: classes.paperFullWidth
        }}
      >
        <DialogTitle id="reorder-dialog-title" onClose={handleClose}>
          Update the collection
        </DialogTitle>
        <DialogContent dividers>
          <Typography style={{ fontSize: "85%" }} gutterBottom>
            Hold your finger on an asset then drag it up or down to re-sequence
            it. Tap the trashcan to remove asset from collection.
          </Typography>

          <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onBeforeDragStart={onBeforeDragStart}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
          >
            <TableContainer
              component={Paper}
              style={{
                background: "linear-gradient(120deg, #fffde7 0%, #fff9c4 100%)"
              }}
            >
              <Table size="small" aria-label="a dense table">
                <Droppable droppableId="droppable-1">
                  {(provided, snapshot) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {results.current.map((asset, index) => (
                        <Draggable
                          draggableId={asset.id}
                          key={asset.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              key={asset.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TableCell
                                scope="row"
                                style={{
                                  padding: "4px 2px 2px 4px",
                                  backgroundColor: `${
                                    snapshot.isDragging ? "#fff176" : "inherit"
                                  }`
                                }}
                              >
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="baseline"
                                >
                                  <Grid item>
                                    <IconButton
                                      aria-label="drag"
                                      style={{ padding: "6px 2px" }}
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs zeroMinWidth>
                                    <Box
                                      style={{
                                        maxWidth: `${window.innerWidth - 63}px`
                                      }}
                                      {...provided.dragHandleProps}
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "80%"
                                        }}
                                      >
                                        {asset.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  {!snapshot.isDragging && (
                                    <Grid item>
                                      <IconButton
                                        aria-label="delete"
                                        style={{ padding: "6px" }}
                                        onClick={() =>
                                          removeAssetFromCollection(asset.id)
                                        }
                                      >
                                        <DeleteIcon
                                          style={{ color: "#d20000" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </TableContainer>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={() => {
              onSave(results.current);
              handleClose();
            }}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// Edit the collections name & desciption or delete the collection
function EditCollections({
  show,
  onClose,
  onSave,
  collections,
  library,
  auth
}) {
  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Description", field: "desc" }
    ],

    options: {
      search: false,
      showFirstLastPageButtons: true,
      showTitle: false,
      toolbar: false,
      padding: "dense",
      actionsColumnIndex: 2,
      pageSize: 10,
      pageSizeOptions: [10, 25, 100]
    },

    data: collections
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions);

  const [open, setOpen] = React.useState(show);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const { enqueueSnackbar } = useSnackbar();

  const saveCollectionToFirestore = useCallback(
    collection => {
      // Set the doc in the Firestore database for this collection.
      firebase
        .firestore()
        .collection("teams")
        .doc(process.env.REACT_APP_TEAM)
        .collection("collections")
        .doc(collection.id)
        .set(collection, { merge: true })
        .then(() => {
          enqueueSnackbar(`Collection copied`, {
            variant: "success"
          });
          library.checkForUpdate(); // This will fetch the collections from firestore back down into the local database
        })
        .catch(err => {
          Log(`Error setting collection ${collection.id} in firestore: ${err}`);
          enqueueSnackbar(
            `Error setting collection ${collection.id} in firestore: ${err}`,
            {
              variant: "error"
            }
          );
        });
    },
    [enqueueSnackbar, library]
  );

  // The additional action buttons that we only display for admins when
  // they have adminCollections turned on. Turn on adminCollections from Settings
  // See "actions" on page: https://material-table.com/#/docs/all-props
  // and https://material-table.com/#/docs/features/actions
  const actions = useMemo(() => {
    if (library.getTableState("config", "adminCollections")) {
      const libraryCollections = library.getCollections("library");
      const bizCollections = library.getCollections("biz");
      const tutCollections = library.getCollections("tut");

      let icons = [
        collection => ({
          icon: () => {
            // If this collection is a library collection, then we use the solid
            // "Active" version of icon. Else, we use the outline icon. This visually shows
            // the admin that this collection is already in the libray collections.

            if (
              _.find(libraryCollections, {
                id: `coLibrary___${auth.user.number}_${collection.id}`
              })
            ) {
              return <LibraryIconActive />;
            } else {
              return <LibraryIcon />;
            }
          },
          tooltip: `Copy to Library collection`,
          onClick: (e, collection) => {
            delete collection.tableData;
            saveCollectionToFirestore({
              ...collection,
              type: "libraryCollection",
              id: `coLibrary___${auth.user.number}_${collection.id}`,
              updated: new Date()
            });
          }
        }),
        collection => ({
          icon: () => {
            if (
              _.find(bizCollections, {
                id: `coBiz___${auth.user.number}_${collection.id}`
              })
            ) {
              return <BizIconActive />;
            } else {
              return <BizIcon />;
            }
          },
          tooltip: "Copy to Biz collection",
          onClick: (e, collection) => {
            delete collection.tableData;
            saveCollectionToFirestore({
              ...collection,
              type: "bizCollection",
              id: `coBiz___${auth.user.number}_${collection.id}`,
              updated: new Date()
            });
          }
        })
      ];

      // If they are an admin who can collections to the tutorials collections,
      // then give them that option as well.
      if (auth.user.isAdmin) {
        icons.push(collection => ({
          icon: () => {
            if (
              _.find(tutCollections, {
                id: `coTut___sa_${collection.id}`
              })
            ) {
              return <TutorialsIconActive />;
            } else {
              return <TutorialsIcon />;
            }
          },
          tooltip: `Copy to Tutorials collection`,
          onClick: (e, collection) => {
            delete collection.tableData;
            saveCollectionToFirestore({
              ...collection,
              type: "tutCollection",
              id: `coTut___sa_${collection.id}`,
              updated: new Date()
            });
          }
        }));
      }

      return icons;
    } else {
      return [];
    }
  }, [library, auth.user.number, auth.user.isAdmin, saveCollectionToFirestore]);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const useStyles = makeStyles({
    paper: {
      margin: "5px"
    },

    paperFullWidth: {
      width: "calc(100% - 2vw)",
      maxWidth: "600px !important"
    }
  });

  const classes = useStyles();

  // Style overrides for the table
  const theme = useCallback(currentTheme => {
    let newTheme = createMuiTheme({
      ...currentTheme,

      overrides: {
        ...currentTheme.overrides, // Inherit the previous overrides

        MuiTableCell: {
          sizeSmall: {
            padding: "0px 2px 0px 5px"
          }
        }
      }
    });

    return newTheme;
  }, []);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="edit-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        classes={{
          paper: classes.paper,
          paperFullWidth: classes.paperFullWidth
        }}
      >
        <DialogTitle id="reorder-dialog-title" onClose={handleClose}>
          Edit collection info
        </DialogTitle>
        <DialogContent dividers style={{ fontSize: "70%", padding: "0px" }}>
          <ThemeProvider theme={theme({})}>
            <MaterialTable
              title="Editable Example"
              columns={state.columns}
              data={state.data}
              options={state.options}
              icons={tableIcons}
              actions={actions}
              editable={{
                onRowAdd: newData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      setState(prevState => {
                        const data = [...prevState.data];
                        data.push(newData);
                        return { ...prevState, data };
                      });
                    }, 300);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 300);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      setState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 300);
                  })
              }}
            />
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={() => {
              onSave(state.data);
              handleClose();
            }}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
