import React, { useMemo, useCallback, useRef, useEffect } from "react";
import Debug from "debug";
import _ from "lodash";

import {
  responsiveFontSizes,
  createMuiTheme,
  ThemeProvider,
  Fab,
  Box,
  Typography,
  Container
} from "@material-ui/core";

import { Element, scroller } from "react-scroll";
import { Waypoint } from "react-waypoint";
import { useSnackbar } from "notistack";

import NavigationIcon from "@material-ui/icons/Navigation";

import useScreenSize from "../../../hooks/useScreenSize";
import { useGA } from "../../../hooks/ga";
import Content from "./Content";
import MasterTable from "./MasterTable";
import ReportIssue from "./ReportIssue";
import AddToCollection from "./AddToCollection";

const Log = Debug("Library");

export default function Page({
  page,
  pageHeading,
  library,
  results,
  ignoreTags,
  selectedID,
  searchPlaceholder,
  hideSearch,
  hideFilters,
  showAddedDate,
  forcePageOne
}) {
  const [asset, setAsset] = React.useState(
    selectedID && results.length !== 0
      ? _.find(results, { id: selectedID })
      : library.getTableState(page, "asset")
  ); // Asset currently being displayed

  const [assetWithIssue, setAssetWithIssue] = React.useState(false);
  const [assetToAdd, setAssetToAdd] = React.useState(false);

  const scrollTopRef = useRef(null);
  const screenSize = useScreenSize();
  const ga = useGA();
  const { enqueueSnackbar } = useSnackbar();

  // tags contains an array of all of the tags that are in the results
  const tags = useMemo(() => {
    if (results.length === 0) return [];

    Log("Creating tags array");
    let t = [];
    let ignore = ignoreTags ? ignoreTags : [];
    results.map(row => {
      // Add any new tags into the array
      if (row.tags && row.tags.length > 1) {
        let values = row.tags.split(",");

        for (let i = 0; i < values.length; i++) {
          let tag = values[i].trim().toLowerCase();
          if (tag !== "" && !t.includes(tag) && !ignore.includes(tag))
            t.push(tag);
        }
      }

      return row;
    });

    // Order the array.
    t = t.sort();

    return t;
  }, [results, ignoreTags]);

  // Set the global styles for containers as their default paddings are too large.
  const theme = useMemo(() => {
    // Style overrides for the MUIDataTable
    let theme = createMuiTheme({
      overrides: {
        MuiContainer: {
          root: {
            paddingLeft: "0px",
            paddingRight: "0px",
            "@media (min-width:375px)": {
              paddingLeft: "4px",
              paddingRight: "4px"
            },
            "@media (min-width:500px)": {
              paddingLeft: "8px",
              paddingRight: "8px"
            },
            "@media (min-width:600px)": {
              paddingLeft: "10px",
              paddingRight: "10px"
            },
            "@media (min-width:700px)": {
              paddingLeft: "15px",
              paddingRight: "15px"
            },
            "@media (min-width:800px)": {
              paddingLeft: "18px",
              paddingRight: "18px"
            },
            "@media (min-width:1200px)": {
              paddingLeft: "20px",
              paddingRight: "20px"
            }
          }
        }
      }
    });
    theme = responsiveFontSizes(theme);

    return theme;
  }, []);

  const loadContent = useCallback(
    asset => {
      setAsset(asset);
      library.updateTableState(page, { asset: asset });
      ga.ReactGA.event({
        category: page,
        action: "View Asset",
        label: `${asset.name} [${asset.type}] [${asset.id}]`
      });
      setTimeout(() => {
        scroller.scrollTo(`scroll_to_asset`, {
          duration: 2000,
          delay: 10,
          smooth: "easeOutQuart",
          offset: 0
        });
      }, 10);
    },
    [library, page, ga]
  );

  const reportIssue = useCallback(asset => {
    setAssetWithIssue(asset);
  }, []);

  const addToCollection = useCallback(asset => {
    setAssetToAdd(asset);
  }, []);

  // Special case for the collections pages because on that page we NEVER want
  // to have a default asset set when the results change. The results change when
  // the user selects a different collection. Change of collection = no asset selected.
  useEffect(() => {
    if (page.endsWith("collections")) setAsset(false);
  }, [results, page, library]);

  return (
    <ThemeProvider theme={theme}>
      <Waypoint
        onEnter={() => (scrollTopRef.current.style.opacity = 0)}
        onLeave={() => (scrollTopRef.current.style.opacity = 0.6)}
      />
      {pageHeading && (
        <Container maxWidth="xs">
          <Box style={{ textAlign: "center", margin: "20px 0px 5px 0px" }}>
            <Typography variant="h4">{pageHeading}</Typography>
          </Box>
        </Container>
      )}
      <MasterTable
        results={results}
        tags={tags}
        pageName={page}
        loadContent={asset => {
          // The autoplay is for videos and audios. We only want them to autoplay
          // IF they have been selected from the list of results, NOT if they have
          // been automatically loaded when they flick between library/biz/team pages
          localStorage.setItem("autoplay", true);
          setTimeout(() => localStorage.setItem("autoplay", false), 1000); // Set autoplay back to false
          loadContent(asset);
        }}
        getTableState={key => library.getTableState(page, key)}
        updateTableState={obj => library.updateTableState(page, obj)}
        searchPlaceholder={searchPlaceholder}
        hideSearch={hideSearch}
        hideFilters={hideFilters}
        showAddedDate={showAddedDate}
        forcePageOne={forcePageOne}
      />
      <Box
        style={{
          textAlign: "right",
          position: "sticky",
          top: `${screenSize.height - 118}px`,
          marginRight: "12px",
          zIndex: 999
        }}
      >
        <Fab
          aria-label="top"
          size="small"
          ref={scrollTopRef}
          style={{ opacity: 0, transition: "opacity .7s ease-in" }}
        >
          <NavigationIcon
            onClick={() => {
              scroller.scrollTo("scroll_to_filters", {
                duration: 1350,
                delay: 10,
                smooth: "easeOutQuart",
                offset: -6
              });
            }}
          />
        </Fab>
      </Box>

      <Element name="scroll_to_asset" />

      {Object.keys(asset).length > 0 && (
        <Content
          asset={asset}
          library={library}
          setAsset={loadContent}
          reportIssue={reportIssue}
          addToCollection={addToCollection}
          isFavourite={library.isFavourite(asset)}
          updateFavourite={(asset, isFavourite) => {
            if (isFavourite)
              ga.ReactGA.event({
                category: page,
                action: "Add Favourite",
                label: `${asset.name} [${asset.type}] [${asset.id}]`
              });

            if (!isFavourite)
              ga.ReactGA.event({
                category: page,
                action: "Remove Favourite",
                label: `${asset.name} [${asset.type}] [${asset.id}]`
              });

            library.updateFavourite(asset, isFavourite);
          }}
          isViewLater={library.isViewLater(asset)}
          updateViewLater={(asset, isViewLater) => {
            if (isViewLater)
              ga.ReactGA.event({
                category: page,
                action: "Add View Later",
                label: `${asset.name} [${asset.type}] [${asset.id}]`
              });

            if (!isViewLater)
              ga.ReactGA.event({
                category: page,
                action: "Remove View Later",
                label: `${asset.name} [${asset.type}] [${asset.id}]`
              });

            library.updateViewLater(asset, isViewLater);
          }}
          shareClick={asset => {
            ga.ReactGA.event({
              category: page,
              action: "Share",
              label: `${asset.name} [${asset.type}] [${asset.id}]`
            });
          }}
        />
      )}

      <ReportIssue asset={assetWithIssue} />
      <AddToCollection
        asset={assetToAdd}
        library={library}
        resetDialog={() => setAssetToAdd(false)}
        addToCollection={(collection, asset) => {
          library.addAssetToMyCollection(collection.id, asset.id);

          enqueueSnackbar(
            `Asset has been added to the collection "${collection.name}"`,
            {
              variant: "success"
            }
          );

          ga.ReactGA.event({
            category: page,
            action: "Collection Add",
            label: asset.name
          });
          setAssetToAdd(false);
        }}
        newCollection={(collection, asset) => {
          library.updateCollection(collection);

          enqueueSnackbar(
            `New collection created called "${collection.name}" and asset added into it`,
            {
              variant: "success"
            }
          );

          ga.ReactGA.event({
            category: page,
            action: "Collection New",
            label: asset.name
          });
          setAssetToAdd(false);
        }}
      />
    </ThemeProvider>
  );
}
