import React from "react";

import PDFIcon from "@material-ui/icons/PictureAsPdf";
import VideoIcon from "@material-ui/icons/YouTube";
import EbookIcon from "@material-ui/icons/MenuBook";
import LinkIcon from "@material-ui/icons/Link";
import ImageIcon from "@material-ui/icons/Image";
import AudioIcon from "@material-ui/icons/Mic";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TagsIcon from "@material-ui/icons/LocalOffer";

// size can be small, default or large
export default function AssetIcon({ type, size, isSelected }) {
  return (
    <>
      {type === "pdf" && (
        <PDFIcon
          fontSize={size ? size : "default"}
          style={{
            color: "crimson",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "6px"
          }}
        />
      )}
      {type === "video" && (
        <VideoIcon
          fontSize={size ? size : "default"}
          style={{
            color: "red",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "12px"
          }}
        />
      )}
      {type === "ebook" && (
        <EbookIcon
          fontSize={size ? size : "default"}
          style={{
            color: "green",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "6px"
          }}
        />
      )}
      {type === "blog" && (
        <ArtTrackIcon
          fontSize={size ? size : "default"}
          style={{
            color: "#F06A35",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "11px"
          }}
        />
      )}
      {type === "link" && <LinkIcon fontSize={size ? size : "default"} />}
      {type === "image" && <ImageIcon fontSize={size ? size : "default"} />}
      {type === "fb" && (
        <FacebookIcon
          fontSize={size ? size : "default"}
          style={{
            color: "blue",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "5px"
          }}
        />
      )}
      {type === "ig" && (
        <InstagramIcon
          fontSize={size ? size : "default"}
          style={{
            color: "#914BC7",
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "7px"
          }}
        />
      )}
      {type === "audio" && (
        <AudioIcon
          fontSize={size ? size : "default"}
          style={{ color: `${isSelected ? "white" : "black"}` }}
        />
      )}
      {type === "tags" && (
        <TagsIcon
          fontSize={size ? size : "default"}
          style={{
            color: "#48c774",
            background: "rgba(255, 255, 255, 0.9)",
            borderRadius: "5px"
          }}
        />
      )}
    </>
  );
}
