import React, { useMemo, useCallback, useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Tooltip,
  Box
} from "@material-ui/core";

import ViewIcon from "@material-ui/icons/OpenInNew";
import ShareIcon from "@material-ui/icons/Share";
import TickIcon from "@material-ui/icons/DoneOutline";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavouriteIcon from "@material-ui/icons/FavoriteBorder";
import FavouriteSelectedIcon from "@material-ui/icons/Favorite";
import LaterIcon from "@material-ui/icons/WatchLaterOutlined";
import LaterSelectedIcon from "@material-ui/icons/WatchLater";
import CollectionAddIcon from "@material-ui/icons/PlaylistAdd";
import AssetIcon from "./AssetIcon";

import useScreenSize from "../../../hooks/useScreenSize";
import { teamStyles } from "../../../config/team";

import { isAsset, getEmbedCode, EmbedAsset } from "./EmbedAsset";

/*
asset
{
  id : integer, // eg 1001
  type : string, // eg pdf, ebook, image, audio, video
  name : string,
  extra : string, // eg 1 hr 35 mins
  tags : string, // comma separated list
  url : string,
  seealso : string, //comma separated list of ids
  share : boolean, // is this asset publically sharable?
  desc : string
}
 */

export default function SeeAlsoCard({
  asset,
  setAsset,
  reportIssue,
  isFavourite,
  updateFavourite,
  isViewLater,
  updateViewLater,
  shareClick,
  addToCollection
}) {
  const [expanded, setExpanded] = useState(false);
  const [favourite, setFavourite] = React.useState(isFavourite);
  const [viewLater, setViewLater] = React.useState(isViewLater);

  // If the asset changes, then we need to re-set the favourite
  useEffect(() => {
    if (isFavourite !== favourite) setFavourite(isFavourite);
    // eslint-disable-next-line
  }, [asset.id]); // Ignore the warning on this.

  // If the asset changes, then we need to re-set the viewLater
  useEffect(() => {
    if (isViewLater !== viewLater) setViewLater(isViewLater);
    // eslint-disable-next-line
  }, [asset.id]); // Ignore the warning on this.

  const screenSize = useScreenSize();

  const viewLink = useCallback(
    link => {
      // If we are on a smaller screen, then we REPLACE the existing window
      // with the new one, by closing the old one first, before opening the new one.
      if (screenSize.width < 800) {
        let name = "intrax";
        if (!window.popups) window.popups = {};
        if (window.popups[name]) window.popups[name].close();
        window.popups[name] = window.open(link, name);
      } else {
        // When on a larger screen, we simply open the link in a new window each time.
        let a = document.createElement("a");
        a.href = link;
        a.target = "_blank";
        a.click();
      }
    },
    [screenSize.width]
  );

  const isSA = useMemo(() => true, []);

  const defaultEmbed = useMemo(() => {
    return !(
      isAsset("link", asset) ||
      isAsset("instagram", asset) ||
      isAsset("image", asset) ||
      isAsset("ylPodcast", asset) ||
      isAsset("googleAudio", asset) ||
      isAsset("googleVideo", asset) ||
      isAsset("googleImage", asset) ||
      isAsset("youtube", asset)
    );
  }, [asset]);

  // Team Styles
  const ts = useMemo(() => {
    return teamStyles();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      width: `${
        screenSize.width < 415 ? `${screenSize.width - 12}px` : "auto"
      }`,
      maxWidth: `${screenSize.width < 415 ? `${screenSize.width}px` : "365px"}`,
      margin: "0px 8px 30px 8px",
      background: ts.saCard.background,
      boxShadow: ts.saCard.boxShadow,
      borderRadius: "12px"
    },

    cardContent: {
      padding: "0px 7px 0px 7px"
    },

    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },

    expandOpen: {
      transform: "rotate(180deg)"
    },

    icon: {
      padding: "12px 14px"
    }
  }));
  const classes = useStyles();

  const handleExpandClick = useCallback(() => {
    setExpanded(e => !e);
  }, []);

  // Button to open in link in a new window IF this asset is sharable.
  const newWindowButton = useMemo(() => {
    if (asset.share) {
      return (
        <Tooltip title="Open in new window">
          <IconButton
            aria-label="view"
            style={{ padding: "2px 2px 0px 0px" }}
            onClick={() => {
              viewLink(asset.url);
            }}
          >
            <ViewIcon />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  }, [asset.share, asset.url, viewLink]);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <IconButton
            aria-label="type"
            color="primary"
            style={{
              padding: "0"
            }}
            onClick={() => {
              setAsset(asset);
            }}
          >
            <AssetIcon type={asset.type} />
          </IconButton>
        }
        action={newWindowButton}
        title={asset.name}
        subheader={!isAsset("ylPodcast", asset) ? asset.extra : ""}
        titleTypographyProps={{ style: { fontSize: "75%" } }}
        subheaderTypographyProps={{ style: { fontSize: "75%" } }}
      />

      <CardContent className={classes.cardContent}>
        <EmbedAsset type="instagram" asset={asset} isSA={true} />
        <EmbedAsset type="image" asset={asset} isSA={true} />
        <EmbedAsset type="ylPodcast" asset={asset} isSA={true} />
        <EmbedAsset type="googleAudio" asset={asset} isSA={true} />
        <EmbedAsset type="googleVideo" asset={asset} isSA={true} />
        <EmbedAsset type="googleImage" asset={asset} isSA={true} />
        <EmbedAsset type="youtube" asset={asset} isSA={true} />
        <EmbedAsset type="link" asset={asset} viewLink={viewLink} isSA={true} />

        {defaultEmbed && (
          <Box
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: `${screenSize.width - 40}`
            }}
            dangerouslySetInnerHTML={getEmbedCode(asset, isSA)}
          />
        )}
      </CardContent>

      <CardActions disableSpacing>
        <Tooltip title="Select">
          <IconButton
            aria-label="view"
            className={classes.icon}
            onClick={() => {
              setAsset(asset);
            }}
          >
            <TickIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={`${favourite ? "Remove from favourites" : "Add favourite"}`}
        >
          <IconButton
            aria-label="favourite"
            className={classes.icon}
            onClick={() => {
              updateFavourite(asset, !favourite);
              setFavourite(v => !v);
            }}
          >
            {favourite ? (
              <FavouriteSelectedIcon style={{ color: "red" }} />
            ) : (
              <FavouriteIcon />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip
          title={`${
            viewLater ? "Remove from view later list" : "Add to view later list"
          }`}
        >
          <IconButton
            aria-label="view_later"
            className={classes.icon}
            onClick={() => {
              updateViewLater(asset, !viewLater);
              setViewLater(v => !v);
            }}
          >
            {viewLater ? (
              <LaterSelectedIcon style={{ color: "green" }} />
            ) : (
              <LaterIcon />
            )}
          </IconButton>
        </Tooltip>

        {asset.share && navigator.share && (
          <Tooltip title="Share">
            <IconButton
              aria-label="share"
              className={classes.icon}
              onClick={() => {
                shareClick(asset);
                navigator.share({
                  title: asset.name,
                  url: asset.url
                });
              }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Add to collection">
          <IconButton
            aria-label="addToCollection"
            className={classes.icon}
            onClick={() => {
              addToCollection(asset);
            }}
          >
            <CollectionAddIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Report an issue/give feedback">
          <IconButton
            aria-label="bug"
            className={classes.icon}
            onClick={() => {
              reportIssue(asset);
            }}
          >
            <BugReportIcon />
          </IconButton>
        </Tooltip>

        {asset.desc.length > 1 && (
          <Tooltip title="Show full description">
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>

      {asset.desc.length > 1 && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {asset.desc.split("\n").map((item, key) => {
              return (
                <Typography paragraph style={{ fontSize: "75%" }} key={key}>
                  {item}
                </Typography>
              );
            })}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}
