import React, { useState } from "react";
import { navigate } from "hookrouter";
import {
  Container,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";

export default function SettingsPage({ library, auth }) {
  const [adminCollections, setAdminCollections] = useState(
    library.getTableState("config", "adminCollections")
  );

  return (
    <Container maxWidth="sm">
      <Typography gutterBottom>
        This settings page is not yet complete, so there is not much you can do
        here at the moment...
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ margin: "10px" }}
        onClick={() => {
          navigate("/app/password");
        }}
      >
        Change Password
      </Button>

      {auth.user.isTeamAdmin && (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={() => {
              library.checkForUpdate(true);
            }}
          >
            Force Library Update
          </Button>

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={adminCollections}
                  onChange={() => {
                    library.updateTableState("config", {
                      adminCollections: !adminCollections
                    });
                    setAdminCollections(v => !v);
                  }}
                  name="adminCollections"
                  color="primary"
                />
              }
              label="Enable Admin of Collections"
            />
          </FormGroup>
        </>
      )}
    </Container>
  );
}
