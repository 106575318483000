import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import Debug from "debug";
import _ from "lodash";
import ReactJson from "react-json-view";
import MUIDataTable from "mui-datatables";
import { isAndroid } from "react-device-detect"; // https://www.npmjs.com/package/react-device-detect
import { useSnackbar } from "notistack";

import {
  Box,
  Container,
  Typography,
  Button,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";

import { teamStyles } from "../../../config/team";
import firebase from "../../../config/firebase";

const Log = Debug("AssetIssues");

export default function AssetIssues({ library, auth }) {
  const [results, setResults] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [issue, setIssue] = useState(false);
  const isMounted = useRef(true);
  const { enqueueSnackbar } = useSnackbar();

  // Team Styles
  const ts = useMemo(() => {
    return teamStyles();
  }, []);

  const onRowClick = useCallback(
    (rowData, rowMeta) => {
      setIssue(results[rowMeta.dataIndex]);
    },
    [results]
  );

  const loadIssues = useCallback(() => {
    firebase
      .firestore()
      .collection("teams")
      .doc(process.env.REACT_APP_TEAM)
      .collection("assetIssues")
      .where("resolved", "==", false)
      .orderBy("date")
      .limit(100)
      .get()
      .then(querySnapshot => {
        if (!isMounted.current) return;

        let allResults = [];
        querySnapshot.forEach(doc => {
          allResults.push({
            ...doc.data(),
            id: doc.id,
            date: doc
              .data()
              .date.toDate()
              .getTime()
          });
        });
        setResults(allResults);
        setLoaded(true);
      })
      .catch(err => {
        Log(err);
        enqueueSnackbar(`Error fetching issues from database: ${err}`, {
          variant: "error"
        });
      });
  }, [enqueueSnackbar]);

  const markResolved = useCallback(
    id => {
      firebase
        .firestore()
        .collection("teams")
        .doc(process.env.REACT_APP_TEAM)
        .collection("assetIssues")
        .doc(id)
        .set({ resolved: true }, { merge: true })
        .then(() => {
          enqueueSnackbar(`Issue marked as resolved.`, {
            variant: "info"
          });
          setIssue(false);
          loadIssues();
        })
        .catch(err => {
          enqueueSnackbar(
            `Error changing issue status to resolved in database: ${err}`,
            {
              variant: "error"
            }
          );
        });
    },
    [loadIssues, enqueueSnackbar]
  );

  // Load up the asset issues
  useEffect(() => {
    loadIssues();

    return () => {
      isMounted.current = false;
    };
  }, [loadIssues]);

  // Column definitions for the MUIDataTable
  const columns = useMemo(() => {
    return [
      {
        name: "asset.id",
        label: "ID",
        options: {
          filter: true,
          sort: true
        }
      },

      {
        name: "report",
        label: "Report",
        options: {
          filter: true,
          sort: true
        }
      }
    ];
  }, []);

  // All of the options for the MUIDataTable
  const options = useMemo(() => {
    return {
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50, 100],
      filterType: "textField", // default search field type - can be over ridden
      searchOpen: false, // Initially show search bar
      print: false, // Hide the printer icon
      download: false, // Hide the download icon
      selectableRowsOnClick: false, // clicking row selects it
      disableToolbarSelect: true,
      responsive: "scrollFullHeight",
      selectableRows: "none",
      onRowClick: onRowClick
    };
  }, [onRowClick]);

  const ourTheme = createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          background: ts.library.table.background,
          boxShadow: ts.library.table.boxShadow,
          borderRadius: "15px",
          marginLeft: `${isAndroid ? "6px" : "3px"}`,
          marginRight: `${isAndroid ? "6px" : "3px"}`
        }
      },

      MuiTableCell: {
        head: {
          backgroundColor: "inherit !important"
        },
        footer: {
          borderBottom: "0px"
        }
      },

      MUIDataTableBodyRow: {
        root: {
          height: "35px"
        }
      },

      MUIDataTableBodyCell: {
        root: {
          padding: "3px 5px",
          fontSize: "0.73rem",
          "@media (min-width:400px)": {
            fontSize: "0.83rem"
          },
          "@media (min-width:500px)": {
            padding: "4px 6px",
            fontSize: "0.88rem"
          },
          "@media (min-width:600px)": {
            padding: "5px 8px",
            fontSize: "0.92rem"
          },
          "@media (min-width:700px)": {
            fontSize: "0.95rem"
          },
          "@media (min-width:800px)": {
            padding: "6px 10px",
            fontSize: "1.0rem"
          },
          "@media (min-width:1200px)": {
            padding: "9px 12px",
            fontSize: "1.1rem"
          }
        }
      }
    }
  });

  if (!isLoaded) {
    return (
      <Container maxWidth="sm">
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <ThemeProvider theme={ourTheme}>
        <MUIDataTable
          title="Asset Issues"
          data={results}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {issue && (
        <Box style={{ marginTop: "30px", marginBottom: "80px" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "0px 0px 20px 20px" }}
            onClick={() => markResolved(issue.id)}
          >
            Mark Resolved
          </Button>
          <ReactJson
            src={_.omit(issue, ["id", "date", "resolved"])}
            collapsed={true}
            enableClipboard={true}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </Box>
      )}
    </Container>
  );
}
