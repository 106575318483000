import React, { useEffect, useState } from "react";
import Debug from "debug";
import _ from "lodash";

import { CircularProgress, Container, Typography } from "@material-ui/core";

import Page from "../library/Page";

const Log = Debug("NewsPage");

export default function NewsPage({ library, selectedID }) {
  const [results, setResults] = useState(
    _.chain(library.results)
      .filter(asset => asset.tags.match(/news[,]?/))
      .sortBy(function(o) {
        return parseInt(o.id.match(/(\d+)/)[0]);
      })
      .reverse()
      .value()
  );

  // Fetch the results
  useEffect(() => {
    if (results.length === 0) {
      if (library.results.length === 0) {
        Log("Waiting for local indexedDB library.results to return data");
        return;
      }

      setResults(
        _.chain(library.results)
          .filter(asset => asset.tags.match(/news[,]?/))
          .sortBy("id")
          .reverse()
          .value()
      );
    }
  }, [library, library.results, results.length]);

  if (library.results.length === 0)
    return (
      <Container maxWidth="sm">
        <Typography variant="h4">Loading</Typography>
        <CircularProgress />
      </Container>
    );

  return (
    <Page
      page="news"
      pageHeading="News & Updates"
      results={results}
      library={library}
      selectedID={selectedID}
      ignoreTags={["news"]}
      hideSearch={true}
      hideFilters={true}
      forcePageOne={true}
    />
  );
}
