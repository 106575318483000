import React from "react";
import { ProvideAuth } from "./hooks/auth.js";
import { ProvideGA } from "./hooks/ga.js";
import { SnackbarProvider } from "notistack"; // https://iamhosseindhv.com/notistack
import App from "./App";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Cancel";

export default function Wrapper() {
  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ProvideGA>
      <SnackbarProvider
        maxSnack={4}
        autoHideDuration={10000}
        ref={notistackRef}
        action={key => (
          <IconButton size="small" onClick={onClickDismiss(key)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </SnackbarProvider>
    </ProvideGA>
  );
}
