import React from "react";

import { navigate } from "hookrouter";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoutIcon from "@material-ui/icons/ExitToAppRounded";
import LibraryIcon from "@material-ui/icons/LocalLibrary";
import FavouriteIcon from "@material-ui/icons/Favorite";
import ViewLaterIcon from "@material-ui/icons/WatchLater";
import BizIcon from "@material-ui/icons/BusinessCenter";
import TeamIcon from "@material-ui/icons/PeopleAlt";
import NewsIcon from "@material-ui/icons/MessageOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import AboutIcon from "@material-ui/icons/Info";
import IssuesIcon from "@material-ui/icons/BugReport";
import CollectionsIcon from "@material-ui/icons/Subscriptions";
import TutorialsIcon from "@material-ui/icons/ImportantDevices";
import TutorialAssetsIcon from "@material-ui/icons/ListAlt";
import NewIcon from "@material-ui/icons/NewReleases";

import {
  MenuList,
  MenuItem,
  Typography,
  Box,
  CardActionArea
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Nav } from "./layout";
import { useAuth } from "../../hooks/auth.js";
import { useGA } from "../../hooks/ga";

const useStyles = makeStyles({
  navLogo: {
    margin: 5,
    width: "7vh",
    maxWidth: 70,
    minWidth: 35,
    height: "7vh",
    maxHeight: 70,
    minHeight: 35
  },

  menuitem: {
    height: "5.5vh",
    minHeight: "38px",
    maxHeight: "53px"
  },

  listItemIcon: {
    width: "5vw",
    minWidth: "30px",
    maxWidth: "55px"
  }
});

export default function HomeNav({ library }) {
  const classes = useStyles();
  const auth = useAuth();
  const ga = useGA();

  return (
    <Nav
      collapsedIcon={{
        inactive: <ChevronLeftIcon />,
        active: <ChevronRightIcon />
      }}
      header={
        // you can provide fixed header inside nav
        // change null to some react element
        ctx => null
      }
    >
      <CardActionArea>
        <Box textAlign="center">
          <img
            alt="Team App"
            src={`/${process.env.REACT_APP_TEAM}/icons/apple-icon-114x114.png`}
            className={classes.navLogo}
          />
        </Box>
      </CardActionArea>

      <MenuList>
        <MenuItem
          onClick={event => navigate("/app/library")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <LibraryIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Library Assets
          </Typography>
        </MenuItem>

        {auth.user.isTeamAdmin === true && (
          <MenuItem
            onClick={event => navigate("/app/library_collections")}
            className={classes.menuitem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CollectionsIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Library Collections
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={event => navigate("/app/library_new")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <NewIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Library What's New?
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/biz")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BizIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Business Assets
          </Typography>
        </MenuItem>

        {auth.user.isTeamAdmin === true && (
          <MenuItem
            onClick={event => navigate("/app/biz_collections")}
            className={classes.menuitem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CollectionsIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Business Collections
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={event => navigate("/app/biz_new")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <NewIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Business What's New?
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/team")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <TeamIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Team Assets
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/fav")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <FavouriteIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            My Favourites
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/later")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ViewLaterIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            My View later
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/my_collections")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CollectionsIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            My Collections
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/news")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <NewsIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            News
          </Typography>
        </MenuItem>

        {auth.user.isAdmin && (
          <MenuItem
            onClick={event => navigate("/app/tutorial_assets")}
            className={classes.menuitem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TutorialAssetsIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              App Tutorial Assets
            </Typography>
          </MenuItem>
        )}

        {auth.user.isTeamAdmin === true && (
          <MenuItem
            onClick={event => navigate("/app/tutorials")}
            className={classes.menuitem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TutorialsIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              App Tutorials
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={event => navigate("/app/settings")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Settings
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={event => navigate("/app/about")}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AboutIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            About
          </Typography>
        </MenuItem>

        {auth.user.isTeamAdmin && (
          <MenuItem
            onClick={event => navigate("/app/issues")}
            className={classes.menuitem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <IssuesIcon />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Asset Issues
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={event => {
            ga.ReactGA.pageview("/app/logout", [], "Logout");
            library.clearDixieTables();
            auth.signout();
          }}
          className={classes.menuitem}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <LogoutIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Logout
          </Typography>
        </MenuItem>
      </MenuList>
    </Nav>
  );
}
