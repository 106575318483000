import React from "react";
import { navigate } from "hookrouter";

import { Container, Paper, Typography, Button } from "@material-ui/core";

export default function SignedOutPage() {
  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: "40px" }}>
        <Typography>You have been signed out of the app.</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "30px" }}
          onClick={() => navigate("/login")}
        >
          Sign In
        </Button>
      </Paper>
    </Container>
  );
}
