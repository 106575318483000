import { useEffect, useState, useMemo, useRef } from "react";

/*

  width: integer,
  height: integer,
  isSmallScreen: boolean

 */

export default function useScreenSize(trackSizeChange) {
  const isClient = useMemo(() => typeof window === "object", []);
  const disabled = useRef(false);

  const [dimensions, setDimensions] = useState({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
    isSmallScreen: isClient && window.innerWidth <= 475 ? true : false,
    disable: () => (disabled.current = true),
    enable: () => (disabled.current = false)
  });

  // Screen width change reset the dimensions state height and width
  useEffect(() => {
    function debounce(fn, ms) {
      let timer;
      return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    }

    //webkitDisplayingFullscreen

    const debouncedHandleResize = debounce(function handleResize() {
      //check if any element is currently full screen
      if (
        document.fullScreen ||
        document.fullScreenElement ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
      ) {
        // Do nothing because something is in fullscreen mode. Generally this is a video playing.
        // When a video is playing fullscreen and they rotate their phone, we DO NOT want to
        // call setDimensions because it will exit them out of full screen mode, because it
        // forces a re-render.
      } else {
        if (!disabled.current)
          setDimensions(prev => ({
            ...prev,
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
            isSmallScreen: isClient && window.innerWidth <= 475 ? true : false
          }));
      }
    }, 500);

    //console.log(`useScreenSize(${trackSizeChange})`);

    if (trackSizeChange)
      window.addEventListener("resize", debouncedHandleResize);

    return _ => {
      if (trackSizeChange)
        window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [isClient, trackSizeChange]);

  return dimensions;
}
