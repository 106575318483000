import React, { useEffect, useState } from "react";
import Debug from "debug";
import { navigate } from "hookrouter";

import { Container, Typography, Button } from "@material-ui/core";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import isWebView from "is-ua-webview";

import {
  isAndroid,
  isIOS,
  isMobile, // true if mobile or tablet
  isMobileOnly,
  isTablet,
  isChrome,
  isSafari,
  isMobileSafari,
  isWindows,
  isMacOs,
  getUA
} from "react-device-detect";

const Log = Debug("Installation");

export default function InstallationPage() {
  // If they are in a webview - for example, they click on a link sent to them
  // via Facebook Messenger on their phone, we must tell them that they have to open
  // this website using a browser.

  if (isWebView(getUA)) return <WebView />;

  // If they are not on a mobile device, then let them know what they need to do.
  if (!isMobile) {
    return (
      <Container maxWidth="sm" style={{ paddingTop: "25px" }}>
        {isSafari && <DesktopSafari />}
        {isChrome && <DesktopChrome />}
        {!isSafari && !isChrome && isWindows && <DesktopUnsupportedWindows />}
        {!isSafari && !isChrome && isMacOs && <DesktopUnsupportedMac />}
        {!isSafari && !isChrome && !isMacOs && !isWindows && <DesktopUnknown />}
      </Container>
    );
  }

  if (isMobile) {
    return (
      <Container maxWidth="sm">
        {isIOS && isMobileOnly && <IPhone isSafari={isMobileSafari} />}
        {isIOS && isTablet && <IPad isSafari={isMobileSafari} />}
        {isAndroid && <Android isChrome={isChrome} />}
        {!isIOS && !isAndroid && <MobileUnknown />}
      </Container>
    );
  }
}

function WebView() {
  return (
    <Container maxWidth="sm" style={{ paddingTop: "25px" }}>
      <Typography>
        To install this app, you must first open a browser. Open{" "}
        <b>Safari on iPhone or iPad</b> or <b>Google Chrome</b> on any other
        device. Then, open this website and you will see the relevant
        installation instructions.
      </Typography>
    </Container>
  );
}

function DesktopSafari() {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        App Installation
      </Typography>
      <Typography gutterBottom>
        You are currently using the Safari browser on MacOS. We{" "}
        <b>highly recommend</b> that you use the Google Chrome browser to
        install this app. Although the instructions below <i>may</i> work for
        you, you won't have access to all the features. So please close Safari
        and open Google Chrome. If this is impossible for you, then continue
        reading and see if you can do the installation using Safari...
      </Typography>
      <Typography gutterBottom>
        To install this app on your mac, simply follow the instructions below.{" "}
        <b>After</b> installing the app, close this window and locate the app
        icon from your Launchpad to launch it.
      </Typography>
      <Typography gutterBottom>
        <b>To install the app:</b> Tap the <b>+</b> icon located in the address
        bar. In the image below you will see an arrow pointing to what it looks
        like.
      </Typography>
      <Typography gutterBottom>
        If you cannot find the + icon, we suggest you open this page in Google
        Chrome.
      </Typography>
      <img
        src="./images/apple_macos_safari.jpg"
        alt="macos installation"
        maxWidth={247}
      />
    </>
  );
}

function DesktopChrome() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [installed, setInstalled] = useState(false);

  // See https://web.dev/customize-install/#listen_for_beforeinstallprompt
  function installApp() {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        Log("User accepted the install prompt");
        setShowInstallPrompt(false);
        setInstalled(true);
        navigate("/login");
      } else {
        Log("User dismissed the install prompt");
      }
    });
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setShowInstallPrompt(true);
    });

    // This is fired if they install the app via the + button in their browser.
    window.addEventListener("appinstalled", e => {
      Log("App installed");
      setShowInstallPrompt(false);
      setInstalled(true);
      navigate("/login");
    });

    // For what is below it is not confirmed working, but will leave it in incase
    // a situation comes up where they access the installation page and the app
    // is already installed.
    //
    // After installation, chrome will open in its app standalone window which this
    // detects and will redirect the user to the login page.
    window.addEventListener("DOMContentLoaded", () => {
      if (navigator.standalone) {
        navigate("/login"); // iOS standalond detection
      }
      if (window.matchMedia("(display-mode: standalone)").matches) {
        navigate("/login"); // Chrome standalone detection
      }
    });

    // This isn't needed but we will keep it here anyway.
    window.addEventListener("DOMContentLoaded", () => {
      window.matchMedia("(display-mode: standalone)").addListener(evt => {
        if (evt.matches) {
          navigate("/login");
        }
      });
    });
  }, []);

  return (
    <>
      <Typography variant="h4">App Installation</Typography>
      <Typography variant="h5">for Google Chrome</Typography>
      {!installed && !showInstallPrompt && (
        <>
          <Typography>
            Locate the dot dot dot icon at the top right of the screen and click
            it. Then click the <b>"Install Team Wild Fire App..."</b> option and
            follow the prompts to install. If it does not appear, you may need
            to hold down shift and click the reload icon (located left hand side
            of home icon). You may need to do this several times.
          </Typography>
          <Typography>
            After installation, locate the icon on your computer and launch the
            App!
          </Typography>
        </>
      )}
      {showInstallPrompt && (
        <Button onClick={installApp} variant="contained" color="primary">
          Click here to install App
        </Button>
      )}
      {installed && (
        <Typography>
          The application has been installed on your computer. You may now close
          this window. Locate the application icon on your computer and click it
          to launch the app.
        </Typography>
      )}
    </>
  );
}

function DesktopUnsupportedMac() {
  return (
    <Typography>
      Please open this page using the Google Chrome browser
    </Typography>
  );
}

function DesktopUnsupportedWindows() {
  return (
    <Typography>Please open this page using Google Chrome browser</Typography>
  );
}

function DesktopUnknown() {
  return (
    <Typography>
      We do not recognise the device you are using. You still may be able to
      install the app but we cannot give you specific instructions.
    </Typography>
  );
}

function MobileUnknown() {
  return (
    <Typography>
      We do not recognise the device you are using. You still may be able to
      install the app but we cannot give you specific instructions.
    </Typography>
  );
}

function IPhone({ isSafari }) {
  if (!isSafari)
    return (
      <Typography>
        To install this App, you must open this website using the Safari
        browser.
      </Typography>
    );

  return (
    <>
      <Typography variant="h5" gutterBottom>
        iPhone App Installation
      </Typography>
      <Typography gutterBottom>
        <b>Step #1:</b> Check to see if this icon{" "}
        <ExploreOutlinedIcon style={{ color: "#087CEF" }} /> appears at the
        bottom right corner of your screen.{" "}
        <b>If it doesn't appear, proceed to step 2.</b> If it does, please tap
        it, which will open this page in Safari then continue to step 2.
      </Typography>

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #2:</b> Tap the <b>share icon</b> located at the very bottom of
        the screen. This is what it looks like:
      </Typography>
      <img
        src="./images/apple_share300px.png"
        alt="share icon"
        maxWidth={300}
        width={180}
      />

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #3:</b> In the displayed list locate <b>Add to home screen</b>{" "}
        <AddBoxOutlinedIcon /> and tap it. You may have to scroll down to find
        the Add to home screen option.
      </Typography>

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #4:</b> Find the icon on your iPhone and tap it to launch the
        App.
      </Typography>
    </>
  );
}

function IPad({ isSafari }) {
  if (!isSafari)
    return (
      <Typography>
        To install this App, you must open this website using the Safari
        browser.
      </Typography>
    );

  return (
    <>
      <Typography variant="h5" gutterBottom>
        iPad App Installation
      </Typography>
      <Typography gutterBottom>
        <b>Step #1:</b> Check to see if this icon{" "}
        <ExploreOutlinedIcon style={{ color: "#087CEF" }} /> appears at the top
        right corner of your screen.{" "}
        <b>If it doesn't appear, proceed to step 2.</b> If it does, please tap
        it, which will open this page in Safari then continue to step 2.
      </Typography>

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #2:</b> Tap the <b>share icon</b> located at the top right of
        the screen. This is what it looks like:
      </Typography>
      <img
        src="./images/apple_share300px.png"
        alt="share icon"
        maxWidth={300}
        width={180}
      />

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #3:</b> In the displayed list locate <b>Add to home screen</b>{" "}
        <AddBoxOutlinedIcon /> and tap it and follow the directions.
      </Typography>

      <Typography gutterBottom style={{ marginTop: "25px" }}>
        <b>Step #4:</b> Find the icon on your iPad and tap it to launch the App.
      </Typography>
    </>
  );
}

function Android({ isChrome }) {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [installed, setInstalled] = useState(false);

  // See https://web.dev/customize-install/#listen_for_beforeinstallprompt
  function installApp() {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        Log("User accepted the install prompt");
        setShowInstallPrompt(false);
        setInstalled(true);
      } else {
        Log("User dismissed the install prompt");
      }
    });
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setShowInstallPrompt(true);
    });

    // This is fired if they install the app via the + button in their browser.
    window.addEventListener("appinstalled", e => {
      Log("App installed");
      setShowInstallPrompt(false);
      setInstalled(true);
    });
  }, []);

  if (!isChrome)
    return (
      <Typography>
        To install this app, you must open it using the Google Chrome browser.
      </Typography>
    );

  return (
    <>
      <Typography variant="h4">
        {installed ? "App Installed!" : "App Installation"}
      </Typography>
      {!installed && !showInstallPrompt && (
        <>
          <Typography>
            Locate the dot dot dot icon at the top right of the screen and tap
            it. Then tap the <b>"Add to Home Screen"</b> option and follow the
            prompts to install.
          </Typography>
          <Typography>
            After installation, locate the icon and launch the App!
          </Typography>
        </>
      )}
      {showInstallPrompt && !installed && (
        <Button
          onClick={installApp}
          variant="contained"
          color="primary"
          style={{ margin: "15px" }}
        >
          Click here to install App
        </Button>
      )}
      {installed && (
        <Typography>
          The application has been installed on your device. You may now close
          this window. Locate the application icon on your device and tap it to
          launch the app.
        </Typography>
      )}
    </>
  );
}
