import React, { useEffect, useState } from "react";
import Debug from "debug";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import "./App.css";
import Routes from "./routes";

import useCacheBuster from "./hooks/useCacheBuster";
import { useLibrary } from "./hooks/library";

const Log = Debug("Index");

// Initialise debugging if we are on localhost development
if (process.env.NODE_ENV === "development" && !localStorage.getItem("debug"))
  localStorage.setItem("debug", "*"); // Doesnt yet exist, so create it.

function App() {
  const [isUpdateAlertOpen, setIsUpdateAlertOpen] = useState(false);

  const library = useLibrary();

  //
  // Cache Busting Code!
  //
  // We use our custom hook, useCacheBuster to make sure that the client
  // regularily checks to ensure that they are running the latest version
  // available.
  //
  const [isLatestVersion, refreshCacheAndReload] = useCacheBuster();

  useEffect(() => {
    if (!isLatestVersion) {
      Log(`Refresh Cache and Reload please!`);
      setIsUpdateAlertOpen(true);
    }
  }, [isLatestVersion]);

  return (
    <React.Fragment>
      <Routes library={library} />

      <Dialog
        open={isUpdateAlertOpen}
        onClose={() => setIsUpdateAlertOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"New version available - Reload Now"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An update of the app has been released, please tap the OK button to
            reload the latest version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUpdateAlertOpen(false)} color="primary">
            Ignore
          </Button>
          <Button onClick={refreshCacheAndReload} color="primary" autoFocus>
            OK Reload Now
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default App;
