import React, { useMemo, useState } from "react";
import { navigate } from "hookrouter";

import { Header } from "./layout";

import {
  Typography,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  MenuList,
  MenuItem,
  ListItemIcon,
  ClickAwayListener,
  Grow,
  Paper,
  makeStyles,
  Popper
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import LibraryIcon from "@material-ui/icons/LocalLibraryOutlined";
import LibraryIconSelected from "@material-ui/icons/LocalLibrary";
//import FavouriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavouriteIconSelected from "@material-ui/icons/Favorite";
//import ViewLaterIcon from "@material-ui/icons/WatchLaterOutlined";
import ViewLaterIconSelected from "@material-ui/icons/WatchLater";
import BizIcon from "@material-ui/icons/BusinessCenterOutlined";
import BizIconSelected from "@material-ui/icons/BusinessCenter";
import TeamIcon from "@material-ui/icons/PeopleAltOutlined";
import TeamIconSelected from "@material-ui/icons/PeopleAlt";
import MyIcon from "@material-ui/icons/AccountBoxOutlined";
import MyIconSelected from "@material-ui/icons/AccountBoxRounded";
import CollectionsIcon from "@material-ui/icons/Subscriptions";
import NewsIcon from "@material-ui/icons/MessageOutlined";
import NewsIconSelected from "@material-ui/icons/Message";
import NewIcon from "@material-ui/icons/NewReleases";

import { isIOS } from "react-device-detect";
import useScreenSize from "../../hooks/useScreenSize";
import { useGA } from "../../hooks/ga";
import { useAuth } from "../../hooks/auth.js";

/*
const useStyles = makeStyles(theme => ({
  topNavSize: {
    height: "65px",
    backgroundColor: "inherit"
  },
  root: {
    padding: "6px 4px 8px",
    minWidth: "65px"
  }
}));
*/

export default function AppBottomButtons(props) {
  const screenSize = useScreenSize(true);
  const ga = useGA();
  const auth = useAuth();

  const [openMyMenu, setOpenMyMenu] = useState(false);
  const [myMenuAnchor, setMyMenuAnchor] = useState(null);

  const [openLibraryMenu, setOpenLibraryMenu] = useState(false);
  const [libraryMenuAnchor, setLibraryMenuAnchor] = useState(null);

  const [openBizMenu, setOpenBizMenu] = useState(false);
  const [bizMenuAnchor, setBizMenuAnchor] = useState(null);

  const minIconWidth = useMemo(() => {
    const availableSize = screenSize.width - 40; // Minus 40 for menu icon
    const iconCount = 5;
    const availableWidth = Math.floor(availableSize / iconCount);
    return availableWidth > 80 ? 80 : availableWidth;
  }, [screenSize.width]);

  const bottomNav = makeStyles(theme => ({
    root: {
      height: "60px",
      backgroundColor: "inherit"
    }
  }));

  const icon = makeStyles(theme => ({
    root: {
      padding: "6px 4px 8px",
      minWidth: `${minIconWidth}px`,
      marginBottom: `${isIOS ? "12" : "0"}px`
    },
    label: {
      fontSize: "0.70rem"
    }
  }));

  const bottomNavClasses = bottomNav();
  const iconClasses = icon();

  const inLibrary = useMemo(
    () => window.location.pathname.indexOf("/app/library") !== -1,
    []
  );

  const inBiz = useMemo(
    () => window.location.pathname.indexOf("/app/biz") !== -1,
    []
  );

  const inTeam = useMemo(
    () => window.location.pathname.indexOf("/app/team") !== -1,
    []
  );

  const inNews = useMemo(
    () =>
      window.location.pathname.indexOf("/app/news") !== -1 ||
      window.location.pathname === "/app/" ||
      window.location.pathname === "/app",
    []
  );

  const inMy = useMemo(
    () =>
      window.location.pathname.indexOf("/app/later") !== -1 ||
      window.location.pathname.indexOf("/app/fav") !== -1 ||
      window.location.pathname.indexOf("/app/my_collections") !== -1,
    []
  );

  return (
    <Header
      menuIcon={{
        inactive: <MenuIcon />,
        active: <ChevronLeftIcon />
      }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <BottomNavigation
            onChange={(event, url) => {
              if (url === "open_my_menu") {
                setOpenMyMenu(true);
                setMyMenuAnchor(event.currentTarget);
                return;
              }

              if (url === "open_library_menu") {
                setOpenLibraryMenu(true);
                setLibraryMenuAnchor(event.currentTarget);
                return;
              }

              if (url === "open_biz_menu") {
                setOpenBizMenu(true);
                setBizMenuAnchor(event.currentTarget);
                return;
              }

              navigate(url);
            }}
            showLabels
            classes={bottomNavClasses}
          >
            <BottomNavigationAction
              label="Library"
              value="open_library_menu"
              classes={iconClasses}
              style={{ backgroundColor: `${inLibrary ? "ivory" : "inherit"}` }}
              icon={
                <>
                  {inLibrary ? (
                    <LibraryIconSelected style={{ color: "goldenrod" }} />
                  ) : (
                    <LibraryIcon />
                  )}
                </>
              }
            />

            <BottomNavigationAction
              label="Biz"
              value="open_biz_menu"
              classes={iconClasses}
              style={{ backgroundColor: `${inBiz ? "ivory" : "inherit"}` }}
              icon={
                <>
                  {inBiz ? (
                    <BizIconSelected style={{ color: "blueviolet" }} />
                  ) : (
                    <BizIcon />
                  )}
                </>
              }
            />

            <BottomNavigationAction
              label="Team"
              value="/app/team"
              classes={iconClasses}
              style={{ backgroundColor: `${inTeam ? "ivory" : "inherit"}` }}
              icon={
                <>
                  {inTeam ? (
                    <TeamIconSelected style={{ color: "teal" }} />
                  ) : (
                    <TeamIcon />
                  )}
                </>
              }
            />

            <BottomNavigationAction
              label="My"
              value="open_my_menu"
              classes={iconClasses}
              style={{ backgroundColor: `${inMy ? "ivory" : "inherit"}` }}
              icon={
                <>
                  {inMy ? (
                    <MyIconSelected style={{ color: "blueviolet" }} />
                  ) : (
                    <MyIcon />
                  )}
                </>
              }
            />

            <BottomNavigationAction
              label="News"
              value="/app/news"
              classes={iconClasses}
              style={{ backgroundColor: `${inNews ? "ivory" : "inherit"}` }}
              icon={
                <>
                  {inNews ? (
                    <NewsIconSelected style={{ color: "green" }} />
                  ) : (
                    <NewsIcon />
                  )}
                </>
              }
            />
          </BottomNavigation>

          <LibraryMenu
            isOpen={openLibraryMenu}
            anchorEl={libraryMenuAnchor}
            close={() => setOpenLibraryMenu(false)}
            ga={ga}
            auth={auth}
          />

          <BizMenu
            isOpen={openBizMenu}
            anchorEl={bizMenuAnchor}
            close={() => setOpenBizMenu(false)}
            ga={ga}
            auth={auth}
          />

          <MyMenu
            isOpen={openMyMenu}
            anchorEl={myMenuAnchor}
            close={() => setOpenMyMenu(false)}
            ga={ga}
            auth={auth}
          />
        </Grid>
      </Grid>
    </Header>
  );
}

function LibraryMenu({ isOpen, close, anchorEl, ga, auth }) {
  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    close();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      close();
    }
  }

  return (
    <Popper
      open={isOpen}
      placement="top-start"
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper elevation={3} style={{ backgroundColor: "#f5f5f5" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={isOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => navigate("/app/library")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <LibraryIconSelected fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>Assets</Typography>
                </MenuItem>

                {auth.user.isTeamAdmin === true && (
                  <MenuItem
                    onClick={() => navigate("/app/library_collections")}
                  >
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <CollectionsIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography style={{ fontSize: "90%" }}>
                      Collections
                    </Typography>
                  </MenuItem>
                )}

                <MenuItem onClick={() => navigate("/app/library_new")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <NewIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>
                    What's New?
                  </Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

function BizMenu({ isOpen, close, anchorEl, ga, auth }) {
  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    close();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      close();
    }
  }

  return (
    <Popper
      open={isOpen}
      placement="top-start"
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper elevation={3} style={{ backgroundColor: "#f5f5f5" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={isOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => navigate("/app/biz")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <BizIconSelected fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>Assets</Typography>
                </MenuItem>

                {auth.user.isTeamAdmin === true && (
                  <MenuItem onClick={() => navigate("/app/biz_collections")}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <CollectionsIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography style={{ fontSize: "90%" }}>
                      Collections
                    </Typography>
                  </MenuItem>
                )}

                <MenuItem onClick={() => navigate("/app/biz_new")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <NewIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>
                    What's New?
                  </Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

function MyMenu({ isOpen, close, anchorEl, ga, auth }) {
  const handleClose = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    close();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      close();
    }
  }

  return (
    <Popper
      open={isOpen}
      placement="top-end"
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper elevation={3} style={{ backgroundColor: "#f5f5f5" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={isOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => navigate("/app/fav")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <FavouriteIconSelected fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>
                    Favourites
                  </Typography>
                </MenuItem>

                <MenuItem onClick={() => navigate("/app/later")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <ViewLaterIconSelected fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>
                    View Later
                  </Typography>
                </MenuItem>

                <MenuItem onClick={() => navigate("/app/my_collections")}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <CollectionsIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ fontSize: "90%" }}>
                    Collections
                  </Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
