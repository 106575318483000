import React from "react";

import { Container, Typography } from "@material-ui/core";
import { teamInfo } from "../../../config/team";

export default function AboutPage({ library, auth }) {
  return (
    <Container maxWidth="sm">
      <Typography variant="h5">About this app</Typography>
      <Typography>App version: {process.env.REACT_APP_VERSION}</Typography>
      <Typography>Total number of assets: {library.results.length}</Typography>
      <Typography>
        Saved Favourites: {library.getFavourites().length}
      </Typography>
      <Typography>Saved View Later: {library.getViewLater().length}</Typography>
      <Typography>
        My collections: {library.getCollections("my").length}
      </Typography>
      <Typography>Member: {auth.user.number}</Typography>
      {auth.user.isAdmin && <Typography>Is Admin: true</Typography>}
      {auth.user.isTeamAdmin && <Typography>Is Team Admin: true</Typography>}
      <Typography variant="body2" paragraph style={{ marginTop: 10 }}>
        This app was developed by e-JAZ Pty Ltd. All content (assets) listed in
        the app are provided for educational purposes only by your team leader{" "}
        {teamInfo().teamLeaderName} ({teamInfo().teamLeaderEmail})
      </Typography>
    </Container>
  );
}
