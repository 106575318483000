import React, { useMemo } from "react";
import Debug from "debug";
import _ from "lodash";

import { Grid, Container } from "@material-ui/core";

import AssetCard from "./AssetCard";
import SeeAlsoCard from "./SeeAlsoCard";
import { canEmbed } from "./EmbedAsset";

const Log = Debug("Content");

/*
asset array

0: "1001"
1: "video"
2: "Introduction to Essential Oils for You Webinar"
3: "1 hr"
4: "twf,oils discovery,intro,psk,webinar"
5: "https://vimeo.com/429891467/ca40b6597c"
6: "1000,1002"
7: "1 hour introduction to essential oils webinar covering all of the oils in the Premium Starter Kit and how to use them."
8: undefined

asset
{
  id : integer, // eg 1001
  type : string, // eg pdf, ebook, image, audio, video
  name : string,
  extra : string, // eg 1 hr 35 mins
  tags : string, // comma separated list
  url : string,
  seealso : string, //comma separated list of ids
  share : boolean, // is this asset publically sharable?
  desc : string
}

 */

export default function Content({
  asset,
  setAsset,
  library,
  reportIssue,
  isFavourite,
  updateFavourite,
  isViewLater,
  updateViewLater,
  shareClick,
  addToCollection
}) {
  /*
  Log("Start Render");
  useEffect(() => {
    Log("Finish Render");
  });*/

  // We separate the seealso which is comma separated into an array.
  const saList = useMemo(() => {
    // Are the any see also assets? If so, put them together into an array for display.
    let saList = []; // List of see also objects
    if (asset.seealso.length > 1) {
      asset.seealso = asset.seealso.replace(/\s+/g, ""); // Get rid of any spaces
      asset.seealso.split(",").map(index => {
        // Find this asset in the results and add it into our saList
        let saAsset = _.find(library.results, { id: index });
        if (!saAsset)
          Log(
            `Warning: Failed to find seealso id ${index} . Check seealso row for id ${asset.id}`
          );
        if (saAsset && canEmbed(saAsset)) saList.push(saAsset);
        return index;
      });
    }
    return saList;
  }, [library, asset]);

  if (Object.keys(asset).length === 0) return null;
  if (library.results.length === 0) return null;

  if (!canEmbed(asset)) return null;

  return (
    <Container maxWidth="xl" style={{ marginBottom: "70px" }}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item>
          <AssetCard
            asset={asset}
            reportIssue={reportIssue}
            isFavourite={isFavourite}
            updateFavourite={updateFavourite}
            isViewLater={isViewLater}
            updateViewLater={updateViewLater}
            shareClick={shareClick}
            addToCollection={addToCollection}
            library={library}
          />
        </Grid>
      </Grid>

      {saList.length > 0 && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {saList.map(a => (
            <Grid item key={a.id}>
              <SeeAlsoCard
                asset={a}
                setAsset={setAsset}
                reportIssue={reportIssue}
                isFavourite={isFavourite}
                updateFavourite={updateFavourite}
                isViewLater={isViewLater}
                updateViewLater={updateViewLater}
                shareClick={shareClick}
                addToCollection={addToCollection}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}
