import React, { useCallback, useEffect } from "react";
import Debug from "debug";
import axios from "axios";
import { useSnackbar } from "notistack";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

import { deviceDetect } from "react-device-detect"; // https://www.npmjs.com/package/react-device-detect

import { useAuth } from "../../../hooks/auth.js";

const Log = Debug("ReportIssue");

export default function ReportIssue({ asset }) {
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState("");

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // When the asset being passed in changes, we open the dialog.
  useEffect(() => {
    if (asset && asset.id) {
      Log(`Reporting issue for asset ${asset.id}`);
      setOpen(true);
    }
  }, [asset]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const submitReport = useCallback(() => {
    axios
      .post(auth.user.api.userAPI, {
        action: "reportIssue",
        team: process.env.REACT_APP_TEAM,
        number: auth.user.number,
        token: auth.user.token,
        report: report,
        asset: asset,
        browser: deviceDetect()
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //
        // 1. Report submitted
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 1) {
          Log("Report recorded sucessfully");
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          return;
        }
      })
      .catch(err => {
        Log(err);
      });

    enqueueSnackbar("Thanks, we have received your feedback!", {
      variant: "success"
    });
    setReport("");
    setOpen(false);
  }, [auth.user, asset, report, enqueueSnackbar]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Report issue</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: "85%" }}>
          Please provide a brief explanation of the issue with this asset below.
          Thanks, we appreciate your feedback to improve the library.
        </DialogContentText>
        <TextField
          multiline
          autoFocus
          rows={4}
          id="issue"
          value={report}
          onChange={e => setReport(e.target.value)}
          label="Explanation of the issue"
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={submitReport} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
