import React, { useState, useMemo, useCallback, useEffect } from "react";
import Debug from "debug";
import axios from "axios";
import { navigate } from "hookrouter";

import { useAuth } from "../hooks/auth";
import { useGA } from "../hooks/ga";
import useScreenSize from "../hooks/useScreenSize";

import {
  Container,
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grow,
  responsiveFontSizes,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";

import NextIcon from "@material-ui/icons/ArrowForwardIos";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import PhoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";

const Log = Debug("Login");

export default function Login() {
  // Which step are we currently on?
  // 1. Member Number
  // 2. Login
  // 3. Confirm Email/Mobile to send auth code to
  // 4. Enter Authentication Code
  // 5. Set a password
  //
  const [step, setStep] = useState(1);
  const [loginAPI, setLoginAPI] = useState(null);
  const [member, setMember] = useState({ number: null });
  const [authMethod, setAuthMethod] = useState({ email: false, sms: false });

  const screenSize = useScreenSize();

  const saveExistingUser = useCallback((url, memberNumber) => {
    setLoginAPI(url);
    setStep(2);
    setMember(prev => ({
      ...prev,
      number: memberNumber
    }));
  }, []);

  const saveAuthMethods = useCallback((method, memberNumber) => {
    setAuthMethod(method);
    setStep(3);
    setMember(prev => ({
      ...prev,
      number: memberNumber
    }));
  }, []);

  // Set the global styles for containers as their default paddings are too large.
  const theme = useMemo(() => {
    // Style overrides for the MUIDataTable
    let theme = createMuiTheme({
      overrides: {
        MuiContainer: {
          root: {
            paddingLeft: "0px",
            paddingRight: "0px",
            "@media (min-width:375px)": {
              paddingLeft: "4px",
              paddingRight: "4px"
            },
            "@media (min-width:500px)": {
              paddingLeft: "8px",
              paddingRight: "8px"
            },
            "@media (min-width:600px)": {
              paddingLeft: "10px",
              paddingRight: "10px"
            },
            "@media (min-width:700px)": {
              paddingLeft: "15px",
              paddingRight: "15px"
            },
            "@media (min-width:800px)": {
              paddingLeft: "18px",
              paddingRight: "18px"
            },
            "@media (min-width:1200px)": {
              paddingLeft: "20px",
              paddingRight: "20px"
            }
          }
        },
        MuiOutlinedInput: {
          input: {
            padding: "12px 10px",
            "@media (min-width:500px)": {
              padding: "18.5px 14px"
            }
          }
        },

        MuiInputLabel: {
          outlined: {
            transform: "translate(12px, 14px) scale(0.9)",
            "@media (min-width:500px)": {
              transform: "translate(14px, 20px) scale(1.0)"
            }
          }
        }
      }
    });
    theme = responsiveFontSizes(theme);

    return theme;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper
          style={{
            margin: `${screenSize.width < 500 ? "0" : "20"}px`,
            boxShadow: `${
              screenSize.width < 500 ? "none" : "2px 1px 8px 0px #7b1b1b"
            }`
          }}
        >
          <Box style={{ padding: `${screenSize.width < 500 ? "10" : "25"}px` }}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Typography align="center" variant="h4">
                  Team Wild Fire App
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center" variant="h5">
                  Sign in
                </Typography>
              </Grid>
              <MemberNumber
                member={member}
                isActiveStep={step === 1}
                saveExistingUser={saveExistingUser}
                saveAuthMethods={saveAuthMethods}
              />

              {step === 2 && (
                <VerifyPassword
                  member={member}
                  isActiveStep={step === 2}
                  loginAPI={loginAPI}
                  back={() => setStep(1)}
                  saveAuthMethods={saveAuthMethods}
                />
              )}

              <ConfirmAuthMethod
                member={member}
                currentStep={step}
                authMethod={authMethod}
                nextStep={useCallback(() => {
                  setStep(4);
                }, [])}
              />

              <AuthenticationCode
                member={member}
                currentStep={step}
                back={useCallback(() => {
                  setStep(3);
                }, [])}
              />
            </Grid>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

// ---------------------------------------------------------------
// 1. MemberNumber
// ---------------------------------------------------------------
function MemberNumber({
  member,
  isActiveStep,
  saveExistingUser,
  saveAuthMethods
}) {
  const [number, setNumber] = useState(member.number ? member.number : "");
  const [disabled, setDisabled] = useState(!isActiveStep ? true : false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);

  // This handles if we go BACK to this step from a previous step, it
  // will re-enable the fields to allow them to be changed.
  useEffect(() => {
    if (isActiveStep) setDisabled(false);
  }, [isActiveStep]);

  // onChange of the member number, make sure that it is digits only
  const handleChange = e => {
    setNumber(e.target.value.replace(/[^0-9]/g, ""));
  };

  const checkMember = useCallback(() => {
    Log(`Check member number ${number}`);
    setDisabled(true);
    setWaiting(true);

    axios
      .post(process.env.REACT_APP_AUTH_API, {
        action: "checkMemberNumber",
        team: process.env.REACT_APP_TEAM,
        number: number
      })
      .then(response => {
        // response.data.code RETURN CODES:
        // 1. An existing user of the app
        //    response.data.data.api contains the Login API URL
        //
        // 2. A member of the team but not yet a user of the app
        //    response.data.data.emailAuthCode - true if support email authentication
        //    response.data.data.SMSAuthCode - true if support SMS authentication
        //
        // 3. Not a member of the team
        //
        // 500. Server error occured

        Log(response.data);

        // 1. An existing user of the app
        if (response.data.code === 1) {
          setError(false);
          setWaiting(false);
          saveExistingUser(response.data.data.api, number);
          return;
        }

        // 2. A member of the team but not yet a user of the app
        if (response.data.code === 2) {
          setWaiting(false);

          // We need to continue on the the authentication code step.
          // However, we need to be able to send an auth code via email or SMS.
          // Check to make sure we have one of these options available. If not,
          // then present an error message.
          if (
            response.data.data.emailAuthCode ||
            response.data.data.SMSAuthCode
          ) {
            // All good, we have at least one method of sending the auth code
            Log(
              `On to the next Authentication Code step. Email: ${response.data.data.emailAuthCode} SMS: ${response.data.data.SMSAuthCode}`
            );
            setError(false);
            saveAuthMethods(
              {
                email: response.data.data.emailAuthCode,
                sms: response.data.data.SMSAuthCode
              },
              number
            );
            return;
          } else {
            // We have no way of sending the auth code, so we have to let the user know
            // that they cannot access the app with a polite message...
            setError(
              "Apologies, but you will not be able to access the app - as there is currently no email address or mobile phone number on your Young Living account."
            );
            return;
          }
        }

        // 3. Not a member of the team
        if (response.data.code === 3) {
          setError(
            "Your member number is not listed as being in this team (check that you entered it correctly and try again). Please contact your upline to verify you are in this team."
          );
          setWaiting(false);
          setDisabled(false);
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setError(response.data.err);
          setWaiting(false);
          setDisabled(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [number, saveExistingUser, saveAuthMethods]);

  return (
    <React.Fragment>
      <Grid item>
        <TextField
          id="member-number"
          label="Member number"
          value={number}
          error={error ? true : false}
          helperText={
            error
              ? error
              : isActiveStep
              ? "Your Young Living member number"
              : ""
          }
          variant="outlined"
          onChange={handleChange}
          disabled={disabled}
          style={{ margin: "15px 0px" }}
          fullWidth
          autoFocus={isActiveStep}
        />
      </Grid>

      {isActiveStep && number.length > 3 && (
        <Grid item>
          <Grow in={number.length !== 0 ? true : !disabled} timeout={2000}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "15px" }}
              disabled={number.length === 0 ? true : disabled}
              onClick={checkMember}
              endIcon={
                waiting ? (
                  <CircularProgress style={{ height: "30px", width: "30px" }} />
                ) : (
                  <NextIcon />
                )
              }
            >
              Next
            </Button>
          </Grow>
        </Grid>
      )}
    </React.Fragment>
  );
}

// ---------------------------------------------------------------
// 2. VerifyPassword
// ---------------------------------------------------------------
function VerifyPassword({
  member,
  isActiveStep,
  loginAPI,
  back,
  saveAuthMethods
}) {
  const [password, setPassword] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);

  const auth = useAuth();
  const ga = useGA();

  const verifyPassword = useCallback(() => {
    Log(`Verifying password for ${member.number}`);
    setWaiting(true);

    axios
      .post(loginAPI, {
        action: "loginMember",
        team: process.env.REACT_APP_TEAM,
        password: password,
        number: member.number
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //  1. Correct password, token is returned
        //
        //  2. Invalid password
        //
        //  3. No token exists yet (they need do SMS/email authentication first)
        //
        //  4. Invalid member number (they dont exist in the database yet)
        //
        //  5. Error generating JWT token.
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 1) {
          Log(
            "Login sucessful so need to grab info, set user auth object and redirect to /app"
          );
          // Log in member and then redirect to app
          auth
            .signin({
              number: parseInt(member.number),
              token: response.data.data.token,
              jwt: response.data.data.jwt,
              name: response.data.data.name,
              country: response.data.data.country,
              api: response.data.data.api
            })
            .then(() => {
              ga.ReactGA.set({ userId: parseInt(member.number) });
              ga.ReactGA.event({
                category: "User",
                action: "Login"
              });
              navigate("/app");
            })
            .catch(err => {
              setError(
                "Please contact admin with this error: Code 501 failed to login to firebase with JWT token."
              );
              setWaiting(false);
            });

          return;
        }

        if (response.data.code === 2) {
          setError("Incorrect password");
          setWaiting(false);
          Log("Incorrect password");
          return;
        }

        if (response.data.code === 3) {
          setError(
            "Cannot log you in as no token exists on your account. Please contact the administrator."
          );
          setWaiting(false);
          Log("No token exists for the member");
          return;
        }

        if (response.data.code === 4) {
          setError(
            "Invalid member number. Please click back button and try again."
          );
          setWaiting(false);
          Log("Invalid member number");
          return;
        }

        if (response.data.code >= 500) {
          Log(
            `Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [member.number, password, loginAPI, auth, ga]);

  const forgotPassword = useCallback(() => {
    Log(`Forgot password for member number ${member.number}`);
    setWaiting(true);

    axios
      .post(process.env.REACT_APP_AUTH_API, {
        action: "forgotPassword",
        team: process.env.REACT_APP_TEAM,
        number: member.number
      })
      .then(response => {
        // response.data.code RETURN CODES:
        // 2. A member of the team but not yet a user of the app
        //    response.data.data.emailAuthCode - true if support email authentication
        //    response.data.data.SMSAuthCode - true if support SMS authentication
        //
        // 500. Server error occured

        Log(response.data);

        // 2. A member of the team but not yet a user of the app
        if (response.data.code === 2) {
          setWaiting(false);

          if (
            response.data.data.emailAuthCode ||
            response.data.data.SMSAuthCode
          ) {
            // All good, we have at least one method of sending the auth code
            Log(
              `On to the next Authentication Code step. Email: ${response.data.data.emailAuthCode} SMS: ${response.data.data.SMSAuthCode}`
            );
            setError(false);
            saveAuthMethods(
              {
                email: response.data.data.emailAuthCode,
                sms: response.data.data.SMSAuthCode
              },
              member.number
            );
            return;
          } else {
            // We have no way of sending the auth code, so we have to let the user know
            // that they cannot access the app with a polite message...
            setError(
              "Apologies, but you will not be able to access the app - as there is currently no email address or mobile phone number on your Young Living account."
            );
            return;
          }
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [member.number, saveAuthMethods]);

  return (
    <React.Fragment>
      <Grid item>
        <TextField
          id="password"
          label="Enter your password"
          type="password"
          value={password}
          error={error ? true : false}
          helperText={error ? error : ""}
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
          disabled={waiting}
          style={{ margin: "15px 0px" }}
          fullWidth
          autoFocus
        />
      </Grid>

      {isActiveStep && (
        <Grid item>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {error && (
              <Grid item>
                {" "}
                <Grow in={true} timeout={2000}>
                  <Button
                    variant="contained"
                    style={{
                      fontSize: "90%",
                      marginTop: "15px",
                      marginRight: "5px"
                    }}
                    disabled={password.length === 0 ? true : waiting}
                    onClick={back}
                    startIcon={<BackIcon />}
                  >
                    Back
                  </Button>
                </Grow>
              </Grid>
            )}

            <Grid item>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  fontSize: "80%",
                  marginTop: "15px"
                }}
                onClick={forgotPassword}
                disabled={waiting}
              >
                Forgot password?
              </Button>
            </Grid>

            {password.length >= 8 && (
              <Grid item>
                <Grow
                  in={password.length !== 0 ? true : !waiting}
                  timeout={2000}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "15px" }}
                    disabled={password.length === 0 ? true : waiting}
                    onClick={verifyPassword}
                    endIcon={
                      waiting ? (
                        <CircularProgress
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <NextIcon />
                      )
                    }
                  >
                    Login
                  </Button>
                </Grow>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

// ---------------------------------------------------------------
// 3. ConfirmAuthMethod
// ---------------------------------------------------------------
function ConfirmAuthMethod({ member, currentStep, authMethod, nextStep }) {
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  const [waiting, setWaiting] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [smsError, setSMSError] = useState(false);

  const isActiveStep = useMemo(() => currentStep === 3, [currentStep]);

  const showSMS = useMemo(() => {
    if (authMethod.sms) {
      if (currentStep === 3) {
        return true;
      } else {
        return number.length > 0 ? true : false;
      }
    }
  }, [authMethod.sms, currentStep, number.length]);

  const showEmail = useMemo(() => {
    if (authMethod.email) {
      if (currentStep === 3) {
        return true;
      } else {
        return email.length > 0 ? true : false;
      }
    }
  }, [authMethod.email, currentStep, email.length]);

  const sendSMS = useCallback(() => {
    Log(`Sending SMS auth code to ${member.number}`);
    setWaiting(true);

    axios
      .post(process.env.REACT_APP_AUTH_API, {
        action: "sendAuthCode",
        team: process.env.REACT_APP_TEAM,
        number: member.number,
        mobile: number,
        email: ""
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //
        // 1. Authentication code sent via SMS
        // N/A 2. Authentication code sent via email
        // N/A 3. Email mismatch (supplied email does not match CSV)
        // 4. Mobile mismatch (supplied mobile number does not match CSV)
        // 5. Try other method
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 1) {
          setSMSError(false);
          setWaiting(false);
          Log("Sent auth code to mobile phone via SMS");
          nextStep(); // Continue onto next step....
          return;
        }

        if (response.data.code === 4) {
          setSMSError(
            "The number entered does not match the mobile number listed on your Young Living account"
          );
          setWaiting(false);
          Log("Mismatch with mobile number on file");
          return;
        }

        if (response.data.code === 5) {
          setSMSError("An error occured, please try email instead.");
          setWaiting(false);
          Log("Try other method");
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setSMSError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [member.number, number, nextStep]);

  const sendEmail = useCallback(() => {
    Log(`Sending Email auth code to ${member.number}`);
    setWaiting(true);

    axios
      .post(process.env.REACT_APP_AUTH_API, {
        action: "sendAuthCode",
        team: process.env.REACT_APP_TEAM,
        number: member.number,
        mobile: "",
        email: email
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //
        // N/A 1. Authentication code sent via SMS
        // 2. Authentication code sent via email
        // 3. Email mismatch (supplied email does not match CSV)
        // N/A 4. Mobile mismatch (supplied mobile number does not match CSV)
        // 5. Try other method
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 2) {
          setEmailError(false);
          setWaiting(false);
          Log("Sent auth code to email address");
          nextStep(); // Continue onto next step....
          return;
        }

        if (response.data.code === 3) {
          setEmailError(
            "The email address entered does not match the email address listed on your Young Living account"
          );
          setWaiting(false);
          Log("Mismatch with email address on file");
          return;
        }

        if (response.data.code === 5) {
          setEmailError("An error occured, please try SMS instead.");
          setWaiting(false);
          Log("Try other method");
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setEmailError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [member.number, email, nextStep]);

  if (currentStep < 3) return null;

  return (
    <React.Fragment>
      {isActiveStep && (
        <Grid item>
          <Typography style={{ fontSize: "85%" }}>
            {authMethod.sms && authMethod.email && (
              <>
                To authenticate you, we will send an authentication code to
                either your mobile phone or email address. Enter your mobile or
                email below.
              </>
            )}

            {authMethod.sms && !authMethod.email && (
              <>
                To authenticate you, we will send an authentication code to your
                mobile phone. Enter your mobile below.
              </>
            )}

            {!authMethod.sms && authMethod.email && (
              <>
                To authenticate you, we will send an authentication code to your
                email address. Enter your email below.
              </>
            )}
          </Typography>
        </Grid>
      )}

      {showSMS && (
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={!isActiveStep}>
              <TextField
                id="mobile"
                label="Mobile number"
                value={number}
                error={smsError ? true : false}
                helperText={
                  smsError
                    ? smsError
                    : isActiveStep
                    ? "Mobile phone number"
                    : ""
                }
                variant="outlined"
                onChange={e => setNumber(e.target.value)}
                disabled={waiting || !isActiveStep}
                fullWidth={!isActiveStep}
                style={{ margin: "15px 0px", maxWidth: "240px" }}
                autoFocus={isActiveStep}
              />
            </Grid>

            {isActiveStep && number.length >= 8 && (
              <Grid item>
                <Grow in={true} timeout={2000}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "18px",
                      marginLeft: "12px"
                    }}
                    disabled={number.length === 0 ? true : waiting}
                    onClick={sendSMS}
                    endIcon={
                      waiting && number.length > 0 ? (
                        <CircularProgress
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <PhoneIcon />
                      )
                    }
                  >
                    Send
                  </Button>
                </Grow>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {showEmail && (
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={!isActiveStep}>
              <TextField
                id="email"
                label="Email"
                value={email}
                error={emailError ? true : false}
                helperText={
                  emailError
                    ? emailError
                    : isActiveStep
                    ? "Email address (on your YL account)"
                    : ""
                }
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                disabled={waiting || !isActiveStep}
                fullWidth={!isActiveStep}
                style={{
                  margin: "15px 0px",
                  maxWidth: `${isActiveStep ? "240px" : "100%"}`
                }}
                autoFocus={isActiveStep && !authMethod.sms}
              />
            </Grid>

            {isActiveStep && email.indexOf("@") !== -1 && (
              <Grid item>
                <Grow in={true} timeout={2000}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "18px", marginLeft: "12px" }}
                    disabled={email.length === 0 ? true : waiting}
                    onClick={sendEmail}
                    endIcon={
                      waiting && email.length > 0 ? (
                        <CircularProgress
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <EmailIcon />
                      )
                    }
                  >
                    Send
                  </Button>
                </Grow>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

// ---------------------------------------------------------------
// 4. AuthenticationCode
// ---------------------------------------------------------------
function AuthenticationCode({ member, currentStep, back, nextStep }) {
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);

  const auth = useAuth();
  const ga = useGA();

  const isActiveStep = useMemo(() => currentStep === 4, [currentStep]);

  useEffect(() => {
    setDisabled(currentStep !== 4);
  }, [currentStep]);

  const verifyCode = useCallback(() => {
    Log(`Verifying authentication code`);
    setWaiting(true);

    axios
      .post(process.env.REACT_APP_AUTH_API, {
        action: "verifyAuthCode",
        team: process.env.REACT_APP_TEAM,
        number: member.number,
        authCode: code
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //
        // 1. authCode matches, token returned in data object of response
        // 2. authCode does not exist for member
        // 3. authCode exists but did not match the passed in authCode
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 1) {
          Log("Auth code matches, can login member!");
          // Log in member and then next step to set password.
          return auth
            .signin({
              number: parseInt(member.number),
              token: response.data.data.token,
              jwt: response.data.data.jwt,
              name: response.data.data.name,
              country: response.data.data.country,
              api: response.data.data.api
            })
            .then(() => {
              ga.ReactGA.set({ userId: parseInt(member.number) });
              ga.ReactGA.event({
                category: "User",
                action: "Login",
                label: "via authCode"
              });
              navigate("/app/password");
            })
            .catch(err => {
              setError(
                "Please contact admin with this error: Code 501 failed to login to firebase with JWT token."
              );
              setWaiting(false);
            });
        }

        if (response.data.code === 2) {
          setError("Please go back and send a new auth code.");
          setWaiting(false);
          Log("Auth code does not exist for this member.");
          return;
        }

        if (response.data.code === 3) {
          setError("The code you have entered is not correct.");
          setWaiting(false);
          Log("Auth code does not match");
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [member.number, code, auth, ga]);

  if (currentStep !== 4) return null;

  return (
    <React.Fragment>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <TextField
              id="auth-code"
              label="Authentication code"
              value={code}
              error={error ? true : false}
              helperText={
                error ? error : isActiveStep ? "Code sent to you" : ""
              }
              variant="outlined"
              onChange={e => setCode(e.target.value)}
              disabled={disabled}
              style={{ margin: "15px 0px", maxWidth: "240px" }}
              autoFocus
              inputRef={input => input && input.focus()}
            />
          </Grid>
          <Grid item>
            {code.length >= 5 && (
              <Grow in={true} timeout={1000}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "18px", marginLeft: "12px" }}
                  disabled={code.length === 0 ? true : waiting}
                  onClick={verifyCode}
                  endIcon={
                    waiting ? (
                      <CircularProgress
                        style={{ height: "30px", width: "30px" }}
                      />
                    ) : (
                      <NextIcon />
                    )
                  }
                >
                  Next
                </Button>
              </Grow>
            )}
          </Grid>
        </Grid>
      </Grid>

      {isActiveStep && (
        <Grid item>
          <Button
            variant="contained"
            style={{ marginTop: "15px", marginRight: "20px" }}
            disabled={waiting}
            onClick={back}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
}
