import React from "react";

import YouTube from "react-youtube"; // https://www.npmjs.com/package/react-youtube
import InstagramEmbed from "react-instagram-embed"; // https://github.com/sugarshin/react-instagram-embed
import Image from "material-ui-image"; // https://mui.wertarbyte.com/#material-ui-image

// "react-embed": "^3.3.2",
// import Embed from "react-embed"; // https://github.com/streamich/react-embed

import { Box, Button, Grid } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/OpenInNew";

import useScreenSize from "../../../hooks/useScreenSize";

// Supported types:
// instagram, image, ylPodcast, googleAudio, googleImage, youtube
export function EmbedAsset({ type, asset, isSA, viewLink }) {
  const screenSize = useScreenSize();

  // If the type to display does not match this asset then display nothing.
  if (!isAsset(type, asset)) return null;

  if (type === "link") {
    return (
      <Box
        style={{
          width: `${
            isSA ? 324 : screenSize.width > 656 ? 656 : screenSize.width - 40
          }px`,
          maxWidth: `${isSA ? "344px" : "auto"}`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ViewIcon />}
              onClick={() => viewLink(asset.url)}
            >
              View Link
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (type === "instagram") {
    return (
      <Box
        style={{
          width: `${
            isSA ? 324 : screenSize.width > 656 ? 656 : screenSize.width - 40
          }px`,
          maxWidth: `${isSA ? "344px" : "auto"}`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <InstagramEmbed
          url={asset.url}
          maxWidth={`${
            isSA ? 324 : screenSize.width > 656 ? 656 : screenSize.width - 40
          }`}
          hideCaption={isSA}
          containerTagName="div"
          protocol=""
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
        />
      </Box>
    );
  }

  if (type === "image" || type === "googleImage") {
    return (
      <Box
        style={{
          width: `${
            isSA ? 324 : screenSize.width > 656 ? 656 : screenSize.width - 40
          }px`,
          maxWidth: `${isSA ? "344px" : "auto"}`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Image
          src={isAsset(type, asset, isSA)}
          alt="Img"
          animationDuration={2200}
          imageStyle={{
            position: "relative",
            maxWidth: "100%",
            height: "auto"
          }}
          style={{ paddingTop: "0px" }}
        />
      </Box>
    );
  }

  if (type === "ylPodcast") {
    return (
      <Box
        style={{
          width: `${
            isSA
              ? screenSize.width > 300
                ? "300"
                : screenSize.width - 10
              : screenSize.width > 560
              ? "560"
              : screenSize.width - 40
          }px`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <audio
          src={asset.extra}
          type="audio/mpeg"
          controls="controls"
          autoPlay={
            localStorage.getItem("autoplay") === "true" && !isSA
              ? "autoplay"
              : false
          }
          controlsList={`${asset.share ? "" : "nodownload"}`}
          style={{ width: "95%" }}
        />
      </Box>
    );
  }

  if (type === "googleAudio") {
    return (
      <Box
        style={{
          width: `${
            isSA
              ? screenSize.width > 300
                ? "300"
                : screenSize.width - 10
              : screenSize.width > 560
              ? "560"
              : screenSize.width - 40
          }px`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <audio
          src={isAsset(type, asset)}
          type="audio/mp3"
          controls="controls"
          autoPlay={
            localStorage.getItem("autoplay") === "true" && !isSA
              ? "autoplay"
              : false
          }
          controlsList={`${asset.share ? "" : "nodownload"}`}
          style={{ width: "95%" }}
        />
      </Box>
    );
  }

  if (type === "googleVideo") {
    // Calculate the optimal window size for videos base on our screen width.
    let width = 640;

    if (screenSize.width > 310) width = 320;
    if (screenSize.width > 370) width = 350;
    if (screenSize.width > 420) width = 400;
    if (screenSize.width > 470) width = 450;
    if (screenSize.width > 520) width = 500;
    if (screenSize.width > 620) width = 600;
    if (screenSize.width > 820) width = 800;
    if (screenSize.width > 1020) width = 1000;
    if (screenSize.width > 1120) width = 1060;
    if (isSA) width = 320;

    return (
      <Box
        style={{
          width: `${width}px`,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <video
          src={isAsset(type, asset)}
          type="video/mp4"
          controls="controls"
          preload="none"
          autoPlay={
            localStorage.getItem("autoplay") === "true" && !isSA
              ? "autoplay"
              : false
          }
          controlsList={`${asset.share ? "" : "nodownload"}`}
          style={{ width: "100%" }}
        />
      </Box>
    );
  }

  if (type === "youtube") {
    // Calculate the optimal window size for videos base on our screen width.
    let width = 640;
    let height = 360;

    if (screenSize.width > 310) width = 320;
    if (screenSize.width > 370) width = 350;
    if (screenSize.width > 420) width = 400;
    if (screenSize.width > 470) width = 450;
    if (screenSize.width > 520) width = 500;
    if (screenSize.width > 620) width = 600;
    if (screenSize.width > 820) width = 800;
    if (screenSize.width > 1020) width = 1000;
    if (screenSize.width > 1120) width = 1060;
    if (isSA) width = 320;
    height = Math.ceil(width / 1.777);

    // Get the youtube video ID
    let id = "";
    id =
      asset.url.indexOf("youtube.com/") !== -1
        ? asset.url.match(/v=([a-zA-Z0-9-_]+)/)[1]
        : asset.url.match(/youtu\.be\/([a-zA-Z0-9-_]+)/)[1];

    // See if there is a TIME startpoint by looking for [?&]t=200 OR [?&]t=1m2s
    let startTime = asset.url.match(/[?&](t=[0-9ms]+)/)
      ? `${asset.url.match(/[?&]t=([0-9ms]+)/)[1]}`
      : "";

    // We need to convert the start time into a number which is seconds. Format we currently
    // have is 1h23m26s
    let hr = startTime.match(/([0-9]+)h/) ? startTime.match(/([0-9]+)h/)[1] : 0;
    let mins = startTime.match(/([0-9]+)m/)
      ? parseInt(startTime.match(/([0-9]+)m/)[1])
      : 0;
    let secs = startTime.match(/([0-9]+)s/)
      ? parseInt(startTime.match(/([0-9]+)s/)[1])
      : 0;

    startTime = hr * 60 * 60 + mins * 60 + secs;
    //console.log(`${hr}h ${mins}m ${secs}s`);

    const opts = {
      height: height,
      width: width,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: localStorage.getItem("autoplay") === "true" && !isSA ? 1 : 0,
        modestbranding: 1,
        rel: 0 // related videos will only be videos from the same channel
      }
    };

    if (startTime > 0) opts.playerVars.start = startTime;

    return (
      <YouTube
        videoId={id}
        opts={opts}
        onPlay={() => screenSize.disable()}
        onPause={() => screenSize.enable()}
        onEnd={() => screenSize.enable()}
      />
    );
  }

  return null;
}

// Returns true (the URL to use for embed) or false if not correct type
// Supported types:
// instagram, image, ylPodcast, googleAudio, googleImage, youtube
// Returns the URL to use for the embed (so for some types it is changed)
// For example, for googleAudio and googleImage
export function isAsset(type, asset, isSA) {
  if (type === "link" && asset.type === "link") return true;

  if (type === "instagram") {
    if (asset.type === "ig") return asset.url;
    if (asset.url.indexOf("instagram.com/") !== -1) return asset.url;
    if (asset.url.indexOf("instagr.am/") !== -1) return asset.url;
    return false;
  }

  if (type === "image") {
    if (asset.type === "image" && asset.url.indexOf(".jpg") !== -1)
      return asset.url;
    if (asset.type === "image" && asset.url.indexOf(".png") !== -1)
      return asset.url;
    if (asset.type === "image" && asset.url.indexOf(".gif") !== -1)
      return asset.url;
    return false;
  }

  if (type === "googleImage") {
    if (
      asset.type === "image" &&
      asset.url.indexOf("drive.google.com") !== -1
    ) {
      // Set a special URL for actual image embed URL.
      // This URL *did* work fine:
      // http://docs.google.com/uc?export=open&id=xxx
      // Where id is extracted from
      // https://drive.google.com/file/d/0B9D0fQkNFSz_bDBHVlVONlpFVEk/view?usp=sharing
      //
      // HOWEVER, we decided to use the /thumbnail method as it allows us to set the maximum dimensions of the image
      // which is better because the actual image will then be smaller (ie less data), especially when it is
      // a see also image.
      // https://drive.google.com/thumbnail?id=1vK_Ny3yNhk3czOl5JvPZY3550KAPhfRN&sz=w1000-h1000
      // The sz=w1000-h1000 tells google to return an image which resized to a MAXIMUM of the passed in
      // width and height.
      //
      return `https://drive.google.com/thumbnail?id=${
        asset.url.match(/([^/]+)\/view(.*)$/)[1]
      }&sz=w${isSA ? 344 * 2 : 656 * 2}-h2000`; // Note: We x 2 here to get a higher res image on phones.
    }

    return false;
  }

  // The HTML5 video player for Google Videos is not great because it
  // will download the ENTIRE video file which is too large for efficient streaming on mobile.
  // So we highly discourage its use for large videos. Use vimeo or youtube instead
  if (type === "googleVideo") {
    if (
      asset.type === "video" &&
      asset.url.indexOf("drive.google.com") !== -1
    ) {
      // https://www.googleapis.com/drive/v3/files/0B9D0fQkNFSz_OFdDOXBGdnhDVkU?alt=media&key=AIzaSyD6C3R0TCuwMM3wlqYTo_uCp9xwoY251J8
      // AIzaSyD6C3R0TCuwMM3wlqYTo_uCp9xwoY251J8 is API key for intrax-dev
      return `https://www.googleapis.com/drive/v3/files/${
        asset.url.match(/([^/]+)\/view(.*)$/)[1]
      }?alt=media&key=AIzaSyD6C3R0TCuwMM3wlqYTo_uCp9xwoY251J8`;
    }

    return false;
  }

  if (type === "ylPodcast") {
    if (
      asset.type === "audio" &&
      asset.url.indexOf("youngliving.com/podcast") !== -1 &&
      asset.extra.length > 0
    )
      return asset.url;

    return false;
  }

  if (type === "googleAudio") {
    if (
      asset.type === "audio" &&
      asset.url.indexOf("drive.google.com") !== -1
    ) {
      // Set a special URL for actual audio embed URL.
      // http://docs.google.com/uc?export=open&id=xxx
      // Where id is extracted from
      // https://drive.google.com/file/d/0B9D0fQkNFSz_bDBHVlVONlpFVEk/view?usp=sharing
      return `http://docs.google.com/uc?export=open&id=${
        asset.url.match(/([^/]+)\/view(.*)$/)[1]
      }`;
    }

    return false;
  }

  if (type === "youtube") {
    if (
      asset.url.indexOf("youtube.com/") !== -1 ||
      asset.url.indexOf("youtu.be/") !== -1
    ) {
      return asset.url;
    } else {
      return false;
    }
  }

  // No match!
  return false;
}

// Returns TRUE if the passed in asset is embedable
export function canEmbed(asset) {
  if (!asset.url) return false;

  // These types we make embeddable regardless of the assets url
  if (["blog", "fb", "ig", "link"].includes(asset.type)) return true;

  if (
    asset.url.indexOf("publitas.com/") !== -1 ||
    asset.url.indexOf("vimeo.com/") !== -1 ||
    asset.url.indexOf("youtube.com/") !== -1 ||
    asset.url.indexOf("youtu.be/") !== -1 ||
    asset.url.indexOf("facebook.com/") !== -1 ||
    asset.url.indexOf("instagram.com/") !== -1 ||
    asset.url.indexOf("instagr.am/") !== -1 ||
    asset.url.indexOf("issuu.com/") !== -1 ||
    asset.url.indexOf("flickr.com/") !== -1 ||
    asset.url.indexOf("youngliving.com/podcast") !== -1 ||
    asset.url.indexOf("drive.google.com") !== -1 ||
    asset.url.indexOf("docs.google.com") !== -1 ||
    asset.url.indexOf(".pdf") !== -1 ||
    asset.url.indexOf(".jpg") !== -1 ||
    asset.url.indexOf(".png") !== -1 ||
    asset.url.indexOf(".gif") !== -1
  ) {
    return true;
  } else {
    return false;
  }
}

// Pass in an asset and returns the embed code.
export function getEmbedCode({ url, name, type }, isSA) {
  if (url.indexOf("publitas.com/") !== -1) {
    // Calculate width of iframe
    let width = 320;
    let height = 510;

    if (window.innerWidth > 310) {
      width = 300;
      height = 240;
    }

    if (window.innerWidth > 370) {
      width = 350;
      height = 280;
    }
    if (window.innerWidth > 420) {
      width = 400;
      height = 310;
    }
    if (window.innerWidth > 470) {
      width = 450;
      height = 360;
    }
    if (window.innerWidth > 520) {
      width = 500;
      height = 400;
    }
    if (window.innerWidth > 620) width = 600;
    if (window.innerWidth > 820) width = 800;
    if (window.innerWidth > 1020) {
      width = 1000;
      height = 600;
    }
    if (window.innerWidth > 1120) {
      width = 1060;
      height = 630;
    }

    return {
      __html: `<iframe src="${url}" title="${encodeURI(name)}"  ${
        isSA ? `width="100%"` : `height="${height}px" width="${width}px"`
      } style="display:block; border:1px solid #c7c4c0; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;" frameborder="0"></iframe>`
    };
    // <div id="publitas-embed-1ukcfkhkl6aj"></div><script data-publication="${url}" data-cfasync="false" data-width="${width}" data-height="${height}" data-publication-aspect-ratio="1.4189473684210527" data-responsive="true"  data-wrapperId="publitas-embed-1ukcfkhkl6aj" publitas-embed src="https://view.publitas.com/embed.js" type="text/javascript"></script>
  }

  // Calculate the optimal window size for videos base on our screen width.
  let width = 640;
  let height = 360;

  if (window.innerWidth > 310) width = 320;
  if (window.innerWidth > 370) width = 350;
  if (window.innerWidth > 420) width = 400;
  if (window.innerWidth > 470) width = 450;
  if (window.innerWidth > 520) width = 500;
  if (window.innerWidth > 620) width = 600;
  if (window.innerWidth > 820) width = 800;
  if (window.innerWidth > 1020) width = 1000;
  if (window.innerWidth > 1120) width = 1060;
  height = Math.ceil(width / 1.777);

  // For PDFs, we us an A4 ratio to calculate the height.
  if (type === "pdf" || type === "blog")
    height = Math.ceil(width * 1.42) < 1100 ? Math.ceil(width * 1.42) : 1100;

  // If this is a SEE ALSO then we fix the width and height.
  if (isSA) {
    width = 320;
    height = 180;
  }

  if (url.indexOf("vimeo.com/") !== -1) {
    // Get the vimeo video ID
    let id = url.match(/vimeo\.com\/(\d+)/)[1];

    // See if there is a TIME startpoint by looking for #t=200 OR #t=1m2s
    // or ?t=200 or ?t=1m2s if they have made the mistake of doing it like a YouTube video
    // For Vimeo videos, the start time must be a hash after the URL. ie.
    // https://vimeo.com/303837277/a3883c7d04#t=1m2s
    let startTime = url.match(/[#?&](t=[0-9ms]+)/)
      ? `#${url.match(/[#?&](t=[0-9ms]+)/)[1]}`
      : "";

    return {
      __html: `<iframe src="https://player.vimeo.com/video/${id}${startTime}?autoplay=${
        localStorage.getItem("autoplay") === "true" && !isSA ? "1" : "0"
      }" width="${width}"
         height="${height}"
         frameborder="0"
         allow="autoplay; fullscreen"
         webkitAllowFullScreen
         mozallowfullscreen
         allowfullscreen ></iframe>`
    };
  }

  if (url.indexOf("facebook.com/") !== -1) {
    // <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&show_text=false&width=734&height=411" width="734" height="411" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>

    // If it is a FB post in SeeAlso then we make the dimensions bigger.
    if (isSA && type !== "video") {
      width = 350;
      height = 350;
    }

    // If FB post and not SA, then make square.
    if (!isSA && type !== "video") {
      height = width > 700 ? 700 : width;
    }

    return {
      __html: `<iframe src="https://www.facebook.com/plugins/${
        type === "video" ? "video" : "post"
      }.php?href=${encodeURIComponent(url)}&show_text=${
        isSA || type === "video" ? "false" : "true"
      }&width=${width}&height=${height}"
        width="${width}"
        height="${height}"
        style="border:none;overflow:hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media; autoplay; fullscreen"
        allowFullScreen ></iframe>`
    };
  }

  if (url.indexOf("issuu.com/") !== -1) {
    // Need to extract the username and document name
    // https://issuu.com/{username}/docs/{documentname}

    let pageNumber = 1; // Default the page number to 1 - we overwrite it below if required.
    let userName = url.split("/docs/")[0].split("issuu.com/")[1];
    let documentName = url.split(/\/docs\/([^/]+)/)[1];
    // See if the format includes a page number i.e. https://issuu.com/{username}/docs/{documentname}/{pageNumber}
    if (url.match(/\/docs\/[^/]+\/(.+)/)) {
      pageNumber = url.split(/\/docs\/[^/]+\/(.+)$/)[1];
    }

    return {
      __html: `<iframe allowfullscreen allow="fullscreen" style="${
        isSA ? "width:100%;" : `width:${width}px;height:${height}px;`
      }border:none;" src="//e.issuu.com/embed.html?backgroundColor=%23ebb88a&backgroundColorFullscreen=%23ebb88a&d=${documentName}&pageNumber=${pageNumber}&hideIssuuLogo=true&u=${userName}"></iframe>`
    };
  }

  if (url.indexOf("flickr.com/") !== -1) {
    let flickrURL = url.replace("www.flickr.com", "embedr.flickr.com");

    return {
      __html: `<iframe allowfullscreen allow="fullscreen" style="width:${width}px;height:${height}px;" src="${flickrURL}?width=${width}&height=${height}"></iframe>`
    };
  }

  if (
    url.indexOf("drive.google.com") !== -1 ||
    url.indexOf("docs.google.com") !== -1
  ) {
    // Standard Google Drive doc or Google Doc (any type of Google Slide, Google Sheet, Google Doc)
    // Change the /edit to /preview in the URL OR
    // Change the /view to /preview in the URL.
    // Otherwise the default iframe is fine.

    url = url.replace(/\/(view|edit)/, "/preview");
  }

  // DEFAULT iframe
  return {
    __html: `<iframe allowfullscreen allow="fullscreen" style="width:${width}px;height:${height}px;" src="${url}"></iframe>`
  };
}
