import React, { useContext, createContext } from "react";
import ReactGA from "react-ga";
import { gaTrackingId } from "../config/team";

const gaContext = createContext();

// Provider component that wraps your app and makes ga object ...
// ... available to any child component that calls useGA().
export function ProvideGA({ children }) {
  const ga = useProvideGA();
  return <gaContext.Provider value={ga}>{children}</gaContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useGA = () => {
  return useContext(gaContext);
};

// Provider hook that creates auth object and handles state
function useProvideGA() {
  ReactGA.initialize(gaTrackingId());

  return {
    ReactGA
  };
}
