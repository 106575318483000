/*

Library hook that exports :
  useLibrary


  Usage:

  import { useLibrary } from "../../hooks/library.js";
  ...
  const library = useLibrary();

  useLibrary provides the following :
    results
      - The array of all of the assets

    isFavourite(asset)
      - Returns true if the passed in asset is a favourite

    updateFavourite(asset,isFavourite)
      - If isFavourite makes asset a favourite otherwise removes asset as a favourite.

    getFavourites()
      - Returns an array of the asset ids they have added to their favourites list

    isViewLater(asset)
      - Returns true if the passed in asset is on the members view later list

    updateViewLater(asset,isViewLater)
      - If isViewLater adds the asset to the members view later list otherwise removes it from list.

    getViewLater()
      - Returns an array of the asset ids on their view later list

    checkForUpdate(noCache)
      - Checks for any new results. If noCache, forces the API to fetch latest library data.

    updateTableState(newState)
      - Updates the table state object with whatever has been passed in.

    getTableState(view,key)
      - Returns the value of the passed in key



 */

import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import Dexie from "dexie";
import Debug from "debug";
import axios from "axios";
import { useSnackbar } from "notistack";
import { navigate } from "hookrouter";
import _ from "lodash";

import firebase from "../config/firebase";
import fb from "firebase/app";
import "firebase/firestore";

import { useAuth } from "./auth.js";
import { useGA } from "./ga";

const Log = Debug("useLibrary");

export function useLibrary() {
  const [results, setResults] = useState([]); // All of the assets that are in the library

  const auth = useAuth();
  const ga = useGA();
  const { enqueueSnackbar } = useSnackbar();

  // Dixie database handle
  const db = useMemo(() => {
    const db = new Dexie("library");
    // id,name,type,extra,tags,url,seealso,share,desc,added,updated,expires
    // The only fields we need to list are those that indexs are created for.
    // i.e. those that we query in .where clauses
    db.version(1).stores({
      assets: `id,updated,expires,added`,
      saved: `id,assetid,type,updated`
    });

    db.version(2).stores({
      saved: `id,assetid,type,updated,deleted,[type+deleted]`
    });

    return db;
  }, []);

  // clearDixieTables should be called just before auth.signout is called
  // This function clears the contents of the local Dixie database
  const clearDixieTables = useCallback(() => {
    db.table("assets").clear();
    db.table("saved").clear();
  }, [db]);

  // Maintaining Table State Section ------------------------------------------
  //

  // Internally we keep the current table state in ts.current ref
  const ts = useRef({});

  // Updates the table state with the passed in obj key/values
  // updateTableState("library",{ page: 1 })
  const updateTableState = useCallback((view, obj) => {
    ts.current = {
      ...ts.current,
      [view]: { ...ts.current[view], ...obj }
    };

    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...ts.current,
        [view]: { ...ts.current[view], ...obj }
      })
    );
  }, []);

  // Returns the value of the passed in tableState key
  // getTableState("library","page")
  const getTableState = useCallback((view, key) => {
    // On the new page we never store the selected asset, to force the user to
    // select the news item they wish to display.
    if (view === "news" && key === "asset") return {};

    // Make sure we have a value defined then return it.
    if (!_.isUndefined(ts.current[view][key])) return ts.current[view][key];

    // For config, handle these special cases.
    if (view === "config") {
      if (
        [
          "snapshotLastUpdated",
          "libraryUpdated",
          "newsLastSeen",
          "collectionsUpdated"
        ].includes(key)
      )
        return 1504885000000;
    }

    return undefined;
  }, []);

  // Resets the table for the passed in view to be empty
  // This is used for views like mycollections where each time they click on
  // a different collection we need to reset the table state for the view back
  // to the default.
  const resetTableState = useCallback(view => {
    ts.current[view] = {
      page: 0,
      rowsPerPage: 10,
      tableFilters: [[], [], [], [], [], [], [], [], []],
      tagCombo: "or",
      searchText: "",
      asset: {}
    };
  }, []);

  // Loads favourites, viewLater and collections in a debounced way
  // Check to see where we call this and why
  const loadEverything = _.debounce(() => {
    loadFavourites();
    loadViewLater();
    loadAllCollections();
  }, 1000);

  // FAVOURITES Section -------------------------------------------------------
  const favourites = useRef([]); // Array of asset ids

  // Fetchs all favourites from indexedDB and stores them in favourites.current
  const loadFavourites = useCallback(() => {
    db.table("saved")
      .where({ type: "favourites" })
      .toArray()
      .then(items => {
        Log(`${items.length} favourites loaded from local database`);
        favourites.current = _.map(items, item => item.assetid);
      })
      .catch(err => {
        Log(`Unable to fetch favourites from favourites table`);
        ga.ReactGA.exception({
          description: `library.js:loadFavourites:${err}`
        });
      });
  }, [db, ga]);

  const updateFavourite = useCallback(
    (asset, isFavourite) => {
      // isFavourite is true then we ADD the favourite otherwise REMOVE it as a favourite
      favourites.current = isFavourite
        ? [...favourites.current, asset.id]
        : _.without(favourites.current, asset.id);

      // Add (or remove) from the "favourites" table of the local indexedDB
      // and to the firestore database
      if (isFavourite) {
        // Add the asset as a favourite in the local favourites table
        db.table("saved")
          .put({
            id: `fav___${asset.id}`,
            assetid: asset.id,
            type: "favourites",
            updated: new Date()
          })
          .catch(err => {
            Log(
              `db error adding asset id fav___${asset.id} to table favourites : ${err}`
            );
            ga.ReactGA.exception({
              description: `library.js:updateFavourite:db.table("saved").put:${err}`
            });
          });

        // Add the asset as a favourite in the firestore database
        firebase
          .firestore()
          .collection("teams")
          .doc(process.env.REACT_APP_TEAM)
          .collection("members")
          .doc(String(auth.user.number))
          .collection("saved")
          .doc(`fav___${asset.id}`)
          .set(
            {
              id: `fav___${asset.id}`,
              assetid: asset.id,
              type: "favourites",
              deleted: false,
              updated: fb.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          )
          .then(() => {
            Log(`Updated favourite status on asset id ${asset.id} in database`);
          })
          .catch(err => {
            Log(`Error updating favourite status on asset in database: ${err}`);
            enqueueSnackbar(
              `Error updating favourite status on asset in database: ${err}`,
              {
                variant: "error"
              }
            );
            ga.ReactGA.exception({
              description: `library.js:updateFavourite:firestore.set:${err}`
            });
          });
      } else {
        // Remove the asset as a favourite in the local favourites table
        db.table("saved")
          .delete(`fav___${asset.id}`)
          .catch(err => {
            Log(
              `db error deleteing asset id fav___${asset.id} from table favourites : ${err}`
            );
            ga.ReactGA.exception({
              description: `library.js:updateFavourite:db.table("saved").delete(fav___${asset.id}):${err}`
            });
          });

        // Remove the asset as a favourite from the firestore database by deleteing the doc
        firebase
          .firestore()
          .collection("teams")
          .doc(process.env.REACT_APP_TEAM)
          .collection("members")
          .doc(String(auth.user.number))
          .collection("saved")
          .doc(`fav___${asset.id}`)
          .set(
            {
              deleted: true,
              updated: fb.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          )
          .then(() => {
            Log(`Marked ${asset.id} as deleted in firestore`);
          })
          .catch(err => {
            Log(
              `Error updating favourite status on asset ${asset.id} in database: ${err}`
            );
            enqueueSnackbar(
              `Error updating favourite status on asset ${asset.id} in database: ${err}`,
              {
                variant: "error"
              }
            );
            ga.ReactGA.exception({
              description: `library.js:updateFavourite:firestore.set({deleted:true}):${err}`
            });
          });
      }
    },
    [db, auth.user.number, enqueueSnackbar, ga]
  );

  const isFavourite = useCallback(
    asset => favourites.current.includes(asset.id),
    []
  );

  const getFavourites = useCallback(() => favourites.current, []);

  // VIEW LATER Section -------------------------------------------------------
  const viewLater = useRef([]); // Array of asset ids

  // Fetchs all view later asset ids from indexedDB and stores them in viewLater.current
  const loadViewLater = useCallback(() => {
    db.table("saved")
      .where({ type: "later" })
      .toArray()
      .then(items => {
        Log(`${items.length} view later assets loaded from local database`);
        viewLater.current = _.map(items, item => item.assetid);
      })
      .catch(err => {
        Log(`Unable to fetch view later assets from the saved table`);
        ga.ReactGA.exception({
          description: `library.js:loadViewLater:${err}`
        });
      });
  }, [db, ga]);

  const updateViewLater = useCallback(
    (asset, isViewLater) => {
      viewLater.current = isViewLater
        ? [...viewLater.current, asset.id]
        : _.without(viewLater.current, asset.id);

      // Add (or remove) from the table in the local (indexedDB) and remote (firestore) databases
      if (isViewLater) {
        // Add the asset as a view later in the local later table
        db.table("saved")
          .put({
            id: `vl___${asset.id}`,
            assetid: asset.id,
            type: "later",
            updated: new Date()
          })
          .catch(err => {
            Log(
              `db error adding asset id vl___${asset.id} to table later : ${err}`
            );
            ga.ReactGA.exception({
              description: `library.js:updateViewLater:db.table("saved").put:${err}`
            });
          });

        // Add the asset as a view later in the firestore database
        firebase
          .firestore()
          .collection("teams")
          .doc(process.env.REACT_APP_TEAM)
          .collection("members")
          .doc(String(auth.user.number))
          .collection("saved")
          .doc(`vl___${asset.id}`)
          .set(
            {
              id: `vl___${asset.id}`,
              assetid: asset.id,
              type: "later",
              deleted: false,
              updated: fb.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          )
          .then(() => {
            Log(
              `Updated view later status on asset id ${asset.id} in database`
            );
          })
          .catch(err => {
            Log(
              `Error updating view later status on asset in database: ${err}`
            );
            enqueueSnackbar(
              `Error updating view later status on asset in database: ${err}`,
              {
                variant: "error"
              }
            );
            ga.ReactGA.exception({
              description: `library.js:updateViewLater:firestore.set:${err}`
            });
          });
      } else {
        // Remove the asset as a view later in the local later table
        db.table("saved")
          .delete(`vl___${asset.id}`)
          .catch(err => {
            Log(
              `db error deleteing asset id vl___${asset.id} from table saved : ${err}`
            );
            ga.ReactGA.exception({
              description: `library.js:updateViewLater:db.table("saved").delete(vl___${asset.id}):${err}`
            });
          });

        // Remove the asset as a view later from the firestore database by marking doc as deleted
        firebase
          .firestore()
          .collection("teams")
          .doc(process.env.REACT_APP_TEAM)
          .collection("members")
          .doc(String(auth.user.number))
          .collection("saved")
          .doc(`vl___${asset.id}`)
          .set(
            {
              deleted: true,
              updated: fb.firestore.FieldValue.serverTimestamp()
            },
            { merge: true }
          )
          .then(() => {
            Log(`Marked ${asset.id} as deleted in database`);
          })
          .catch(err => {
            Log(
              `Error marking as deleted asset id vl___${asset.id} in database: ${err}`
            );
            enqueueSnackbar(
              `Error marking as deleted asset id vl___${asset.id} in database: ${err}`,
              {
                variant: "error"
              }
            );
            ga.ReactGA.exception({
              description: `library.js:updateViewLater:firestore.set({deleted:true}):${err}`
            });
          });
      }
    },
    [db, auth.user.number, enqueueSnackbar, ga]
  );

  const isViewLater = useCallback(
    asset => viewLater.current.includes(asset.id),
    []
  );

  const getViewLater = useCallback(() => viewLater.current, []);

  // COLLECTIONS Section -------------------------------------------------------
  //
  // Each collection object has the following properties -
  //   id : co____1 (see below for id format based on type)
  //   name : string
  //   desc : string
  //   assets : array of asset ids
  //   type : "myCollection" || "libraryCollection" || "bizCollection" || "tutCollection"
  //   updated : date
  //   deleted : 0 or 1 (in the firestore database this is false or true, but in indexedDB 0 or 1)
  //
  //   id's are in the following format:
  //     myCollection - co___{index}
  //     libraryCollection - coLibrary___{memberid}_{myCollection id}
  //     bizCollection - coBiz___{memberid}_{myCollection id}
  //     tutCollection - coTut___sa_{myCollection id}  // NOTE: ONLY SYS ADMINS CAN CREATE THESE
  //
  const collections = useRef({
    // Each of these is an array of collection objects
    my: [], // The users personal collections
    library: [], // Collections created by the team
    biz: [], // Collections created by the team but for business
    tut: [], // Collections created by an admin for app tutorials
    myProspect: [], // Collections created by the user for prospects
    teamProspect: [] // Collections created by the team for prospects
  });

  // Fetchs all of my collections from indexedDB and stores them in collections.current.my
  const loadMyCollections = useCallback(() => {
    db.table("saved")
      .where({ type: "myCollection" })
      .toArray()
      .then(items => {
        Log(`${items.length} my collections loaded from local database`);
        collections.current.my = items;
      })
      .catch(err => {
        Log(`Unable to fetch my collections from the saved table`);
        ga.ReactGA.exception({
          description: `library.js:loadMyCollections:${err}`
        });
      });
  }, [db, ga]);

  // Fetchs all of the library collections from indexedDB and stores them in collections.current.library
  const loadLibraryCollections = useCallback(() => {
    db.table("saved")
      .where({ type: "libraryCollection", deleted: 0 })
      .toArray()
      .then(items => {
        Log(`${items.length} library collections loaded from local database`);
        collections.current.library = items;
      })
      .catch(err => {
        Log(`Unable to fetch library collections from the saved table`);
        ga.ReactGA.exception({
          description: `library.js:loadLibraryCollections:${err}`
        });
      });
  }, [db, ga]);

  // Fetchs all of the biz collections from indexedDB and stores them in collections.current.biz
  const loadBizCollections = useCallback(() => {
    db.table("saved")
      .where({ type: "bizCollection", deleted: 0 })
      .toArray()
      .then(items => {
        Log(`${items.length} biz collections loaded from local database`);
        collections.current.biz = items;
      })
      .catch(err => {
        Log(`Unable to fetch biz collections from the saved table`);
        ga.ReactGA.exception({
          description: `library.js:loadBizCollections:${err}`
        });
      });
  }, [db, ga]);

  // Fetchs all of the tut collections from indexedDB and stores them in collections.current.tut
  const loadTutCollections = useCallback(() => {
    db.table("saved")
      .where({ type: "tutCollection", deleted: 0 })
      .toArray()
      .then(items => {
        Log(`${items.length} tut collections loaded from local database`);
        collections.current.tut = items;
      })
      .catch(err => {
        Log(`Unable to fetch tut collections from the saved table`);
        ga.ReactGA.exception({
          description: `library.js:loadTutCollections:${err}`
        });
      });
  }, [db, ga]);

  // Fetch ALL collections from indexedDB and store them in collections.current
  const loadAllCollections = useCallback(() => {
    loadMyCollections();
    loadLibraryCollections();
    loadBizCollections();
    loadTutCollections();
  }, [
    loadMyCollections,
    loadLibraryCollections,
    loadBizCollections,
    loadTutCollections
  ]);

  const getCollections = useCallback(
    collectionType => {
      // collectionType can be my, library or biz
      // Special case: If collectionType is my and isTeamAdmin
      // then we need to check if any of the collections in my collections are library or biz
      // collections and REMOVE them from the list. We ONLY show them to the team admin
      // in their my collections when the config adminCollections is true.

      if (
        collectionType === "my" &&
        auth.user.isTeamAdmin &&
        !getTableState("config", "adminCollections")
      ) {
        let adminsCollections = collections.current.my; // Will filter out any library or biz collections from this list

        // Go through each collection and see if we need to remove it
        collections.current.my.map(collection => {
          const libraryCollectionIndex = `coLibrary___${auth.user.number}_${collection.id}`;
          const bizCollectionIndex = `coBiz___${auth.user.number}_${collection.id}`;
          if (
            _.find(collections.current.library, {
              id: libraryCollectionIndex
            }) ||
            _.find(collections.current.biz, { id: bizCollectionIndex })
          ) {
            // Remove this collection from adminsCollections
            _.remove(adminsCollections, c => c.id === collection.id);
          }
          return collection;
        });

        return adminsCollections;
      } else {
        return collections.current[collectionType];
      }
    },
    [auth.user.isTeamAdmin, auth.user.number, getTableState]
  );

  const getAssetsInCollection = useCallback((collectionType, collectionID) => {
    // Return an array of asset IDs that are in the passed in collection ID
    Log(`getAssetsInCollection "${collectionType}" for ${collectionID}`);

    let index = _.findKey(
      collections.current[collectionType],
      collection => collection.id === collectionID
    );

    return index ? collections.current[collectionType][index].assets : [];
  }, []);

  const updateCollection = useCallback(
    async collection => {
      // Updates or Adds a NEW collection into the database. The passed in collection object
      // looks like this for an ADD. You can see it has no id yet. If it has an id,
      // then we perform an update, not an add
      //
      //   name : string
      //   desc : string
      //   assets : array of asset ids (will only be an array with 1 asset id in it)
      //
      // and we will then add the following properties to the object before adding it to database
      //   id
      //   type : "myCollection"
      //   updated : date

      // If we are doing an ADD, then we need to work out and assign a new collection ID.
      if (!collection.id) {
        // ADD
        // to perform an add, we need to work out a unique collection id.
        // the collection prefix id we use is co___ then a number. So we start
        // from number 1 until we find an id that is not currently used.
        let collectionID = false; // This gets set to an available collection ID (next one in the sequence)
        let count = 1;
        while (!collectionID) {
          const id = `co___${count}`;
          let exists = await db.table("saved").get(id); // This will return undefined if it cannot find an object in the table with this id
          if (!exists) collectionID = id; // We are looking for an id that does not yet exist, and we found one. Yippie!
          count++;
        }
        Log(`New collection id: ${collectionID}`);

        // Great, we have a collection ID, now update add the rest of the properties into the object that we need
        collection = {
          ...collection,
          id: collectionID,
          type: "myCollection"
        };
      }

      // Now the code for an ADD or an UPDATE is EXACTLY the same. Makes life easy. :)
      // First, update the collection in the local database
      collection.updated = new Date();

      db.table("saved")
        .put(collection)
        .catch(err => {
          Log(
            `db error updating collection id ${collection.id} in the table saved : ${err}`
          );
          ga.ReactGA.exception({
            description: `library.js:updateCollection:db.table("saved").put:${err}`
          });
        });

      // Second, update the collection in the firestore database
      firebase
        .firestore()
        .collection("teams")
        .doc(process.env.REACT_APP_TEAM)
        .collection("members")
        .doc(String(auth.user.number))
        .collection("saved")
        .doc(collection.id)
        .set({ ...collection, deleted: false }, { merge: true })
        .then(() => {
          Log(`Updated collection ${collection.id} in database`);
        })
        .catch(err => {
          Log(`Error updating collection ${collection.id} in database: ${err}`);
          enqueueSnackbar(
            `Error updating collection ${collection.id} in database: ${err}`,
            {
              variant: "error"
            }
          );
          ga.ReactGA.exception({
            description: `library.js:updateCollection:firestore.set (in update section):${err}`
          });
        });
    },
    [db, ga, auth.user.number, enqueueSnackbar]
  );

  const addAssetToMyCollection = useCallback(
    (collectionID, assetID) => {
      let index = _.findKey(
        collections.current.my,
        collection => collection.id === collectionID
      );

      if (index) {
        updateCollection({
          ...collections.current.my[index],
          assets: [...collections.current.my[index].assets, assetID]
        });
      }
    },
    [updateCollection]
  );

  const deleteAssetFromMyCollection = useCallback(
    (collectionID, assetID) => {
      let index = _.findKey(
        collections.current.my,
        collection => collection.id === collectionID
      );

      if (index) {
        updateCollection({
          ...collections.current.my[index],
          assets: _.filter(
            collections.current.my[index].assets,
            v => v !== assetID
          )
        });
      }
    },
    [updateCollection]
  );

  const deleteCollection = useCallback(
    collection => {
      // Deletes the passed in collection from local database and firestore database

      // Delete from the local database
      db.table("saved")
        .delete(collection.id)
        .catch(err => {
          Log(
            `db error deleteing collection id ${collection.id} from table saved : ${err}`
          );
          ga.ReactGA.exception({
            description: `library.js:deleteCollection:db.table("saved").delete(${collection.id}):${err}`
          });
        });

      // Delete from the firestore database
      firebase
        .firestore()
        .collection("teams")
        .doc(process.env.REACT_APP_TEAM)
        .collection("members")
        .doc(String(auth.user.number))
        .collection("saved")
        .doc(collection.id)
        .set(
          { deleted: true, updated: fb.firestore.FieldValue.serverTimestamp() },
          { merge: true }
        )
        .then(() => {
          Log(`Marked collection ${collection.id} as deleted in firestore`);
        })
        .catch(err => {
          Log(
            `Error marking collection ${collection.id} as deleted in firestore: ${err}`
          );
          enqueueSnackbar(
            `Error marking collection ${collection.id} as deleted in firestore: ${err}`,
            {
              variant: "error"
            }
          );
          ga.ReactGA.exception({
            description: `library.js:deleteCollection:firestore.set({deleted:true}):${err}`
          });
        });
    },
    [db, ga, auth.user.number, enqueueSnackbar]
  );

  // ASSETS Section -----------------------------------------------------------
  // Load the Library assets!
  const loadResults = useCallback(() => {
    db.table("assets")
      .toArray()
      .then(async items => {
        if (items.length === 0) {
          // Nothing yet in the database? Then we need to fetch the data
          Log("No results in local library database, fetching from remote DB");

          items = await axios
            .post(auth.user.api.libraryAPI, {
              action: "updateLibrary",
              team: process.env.REACT_APP_TEAM,
              number: auth.user.number,
              token: auth.user.token,
              since: 1
            })
            .then(async response => {
              if (response.data.code === 1) {
                // This will add all of the results into the database asyncronously
                return await db
                  .table("assets")
                  .bulkPut(response.data.data)
                  .then(lastKey => {
                    Log(`Done adding results in bulk. Last ID was ${lastKey}`);
                    localStorage.setItem(
                      "library_checked",
                      new Date().getTime()
                    );
                    return response.data.data;
                  })
                  .catch(e => {
                    Log(`${e.failures.length} additions did not succeed`);
                    ga.ReactGA.exception({
                      description: `library.js:loadResults:db.table("assets").bulkPut:${e.failures.length} additions did not succeed`
                    });
                  });
              }

              if (response.data.code === 500) {
                Log(
                  `500 Error Returned. Client message: ${response.data.err}  ${
                    response.data.info
                      ? ` More info: ${response.data.info}`
                      : ""
                  }`
                );

                enqueueSnackbar(response.data.err, { variant: "error" });
              }

              if (response.data.code > 600) auth.signout();

              return [];
            })
            .catch(err => {
              Log(err);
              ga.ReactGA.exception({
                description: `library.js:loadResults:axios.post:${err}`
              });
            });
        } else {
          Log(`${items.length} results in local library database`);
        }

        // Retrieve the most recent date in updated date and store it in local storage
        const mostRecentUpdatedDate = _.sortBy(items, "updated")[
          items.length - 1
        ].updated;

        Log(
          `Most recent update to library results: ${new Date(
            mostRecentUpdatedDate
          )}`
        );

        updateTableState("config", { libraryUpdated: mostRecentUpdatedDate });

        // Filter out any expired items.
        const now = new Date().getTime();

        // Initialise newsLastSeen config to be whatever the most recent news item added date is
        const newsItems = _.chain(items)
          .filter(item => {
            if (!item.id.match(/-news$/)) return false; // Not a news item
            if (item.expires > now) return false; // Expired items
            if (!item.added) return false;
            return true;
          })
          .sortBy(["added"])
          .reverse()
          .value();

        // We should always have news items, but if not then thats ok.
        if (newsItems.length > 0)
          updateTableState("config", {
            newsLastSeen: newsItems[0].added
          });

        setResults(
          _.filter(items, item => {
            if (item.expires === 0) return true; // This means it does not have an expiry date
            return item.expires > now; // Item must have an expiry date in the future
          })
        );
      });
  }, [db, auth, enqueueSnackbar, updateTableState, ga]);

  // Do a check to see if there are any new results to update/add into the library
  // If true is passed in (noCache) then it will force the API to fetch the latest
  // library data and not use its cached results.
  const checkForUpdate = useCallback(
    noCache => {
      // COLLECTIONS check via firestore query
      // Have a look at the docs in the /teams/{team}/collections collection to see if
      // there are any updated collections since the last time we checked.
      firebase
        .firestore()
        .collection("teams")
        .doc(process.env.REACT_APP_TEAM)
        .collection("collections")
        .where(
          "updated",
          ">",
          new Date(getTableState("config", "collectionsUpdated"))
        )
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) return; // No results, nothing to do.

          // We have results, so update our local database with these results.
          Log(
            `${querySnapshot.size} updated/new collections returned from firestore`
          );

          // Go through each returned doc and add it into the array called collections
          let collections = [];
          querySnapshot.forEach(doc => {
            collections.push({
              ...doc.data(),
              deleted: doc.data().deleted ? 1 : 0, // Change this from a boolean to 1/0 as dexie cannot index booleans
              updated: doc.data().updated.toDate()
            });
          });

          // Now update all of the collections in the local database.
          db.table("saved")
            .bulkPut(collections)
            .then(lastKey => {
              Log(`Added collections in bulk. Last ID was ${lastKey}`);

              // Retrieve the most recent date in updated date and store it in local storage
              const mostRecentUpdatedDate = _.sortBy(collections, "updated")[
                collections.length - 1
              ].updated.getTime();

              Log(
                `Most recent update to collections: ${new Date(
                  mostRecentUpdatedDate
                )}`
              );
              updateTableState("config", {
                collectionsUpdated: mostRecentUpdatedDate
              });

              loadLibraryCollections();
              loadBizCollections();
              loadTutCollections();
            })
            .catch(e => {
              Log(
                `${e.failures.length} additions did not succeed to saved table for collections`
              );
              ga.ReactGA.exception({
                description: `library.js:checkForUpdate:collections:db.table("saved").bulkPut:${e.failures.length} additions did not succeed`
              });
            });
        })
        .catch(err => {
          Log(err);
          ga.ReactGA.exception({
            description: `library.js:checkForUpdate:firestore.get:${err}`
          });
        });

      // LIBRARY check via the libraryAPI
      axios
        .post(auth.user.api.libraryAPI, {
          action: "updateLibrary",
          team: process.env.REACT_APP_TEAM,
          number: auth.user.number,
          token: auth.user.token,
          since: getTableState("config", "libraryUpdated"),
          noCache: noCache ? true : false
        })
        .then(response => {
          if (response.data.code === 1) {
            // This will add all of the results into the database asyncronously
            const items = response.data.data;

            if (items.length > 0) {
              // Calculate how many assets are new vs updated. We do this simply so
              // that we can report this back to the user
              const itemsArr = _.map(items, item => item.id);
              const updatedCount = _.filter(results, item =>
                itemsArr.includes(item.id)
              ).length;
              const addedCount = itemsArr.length - updatedCount;
              const clientMessage = `The library was just updated with ${addedCount} new assets and ${updatedCount} updated assets.`;

              db.table("assets")
                .bulkPut(items)
                .then(lastKey => {
                  Log(
                    `Added: ${addedCount} Updated: ${updatedCount} assets in the library. Last ID was ${lastKey}`
                  );

                  // Retrieve the most recent date in updated date and store it in local storage
                  const mostRecentUpdatedDate = _.sortBy(items, "updated")[
                    items.length - 1
                  ].updated;

                  Log(
                    `Most recent update to library results: ${new Date(
                      mostRecentUpdatedDate
                    )}`
                  );
                  updateTableState("config", {
                    libraryUpdated: mostRecentUpdatedDate
                  });

                  // Get the full library database and update via setResults
                  db.table("assets")
                    .toArray()
                    .then(items => {
                      // Filter out any expired items.
                      const now = new Date().getTime();

                      setResults(
                        _.filter(items, item => {
                          if (item.expires === 0) return true; // This means it does not have an expiry date
                          return item.expires > now; // Item must have an expiry date in the future
                        })
                      );

                      // Has a new NEWS item come through???
                      // If it has, then we will navigate to display that news item
                      // and show a message to let the user know.
                      const newsLastSeen = getTableState(
                        "config",
                        "newsLastSeen"
                      );
                      const newNewsItems = _.chain(items)
                        .filter(item => {
                          if (!item.id.match(/-news$/)) return false; // Not a news item
                          if (item.expires > now) return false; // Expired items
                          if (!item.added) return false;
                          if (item.added > newsLastSeen) return true;
                          return false;
                        })
                        .sortBy(["added"])
                        .reverse()
                        .value();

                      if (newNewsItems.length > 0) {
                        // We have some new news to display to the user.
                        updateTableState("config", {
                          newsLastSeen: newNewsItems[0].added
                        });

                        enqueueSnackbar(
                          "Displaying a news item that you have not seen yet.",
                          { variant: "info" }
                        );

                        ga.ReactGA.event({
                          category: "news",
                          action: "New News Item",
                          label: `${newNewsItems[0].name} [${newNewsItems[0].type}] [${newNewsItems[0].id}]`
                        });

                        navigate(`/app/news/${newNewsItems[0].id}`);
                      } else {
                        // No new news to display, so just inform the user of the update to the library
                        enqueueSnackbar(clientMessage, { variant: "info" });
                      }
                    });
                })
                .catch(e => {
                  Log(`${e.failures.length} additions did not succeed`);
                  ga.ReactGA.exception({
                    description: `library.js:checkForUpdate:db.table("assets").bulkPut:${e.failures.length} additions did not succeed`
                  });
                });
            } else {
              Log("checkForUpdate: No new or updated library items");
            }
          }

          if (response.data.code === 500) {
            Log(
              `500 Error Returned. Client message: ${response.data.err}  ${
                response.data.info ? ` More info: ${response.data.info}` : ""
              }`
            );
            enqueueSnackbar(response.data.err, { variant: "error" });
          }

          if (response.data.code > 600) auth.signout();
        })
        .catch(err => {
          Log(err);
          ga.ReactGA.exception({
            description: `library.js:checkForUpdate:axios.post:${err}`
          });
        });
    },
    [
      db,
      auth,
      ga,
      enqueueSnackbar,
      results,
      getTableState,
      updateTableState,
      loadLibraryCollections,
      loadBizCollections,
      loadTutCollections
    ]
  );

  // useEffects Section -------------------------------------------------------
  // Load assets from local database
  // ----------------------------------------------------------------------
  // Load up the assets from the local database (if they exist in there)
  // otherwise fetch the results from the remote database
  useEffect(() => {
    if (!auth.isAuthenticated) return; // Do nothing if not authenticated.

    // Initialise the tableState
    let initialTableState = {
      library: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      librarynew: {
        page: 0,
        rowsPerPage: 25,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      favourites: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      later: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      biz: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      biznew: {
        page: 0,
        rowsPerPage: 25,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      team: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      news: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      tut: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      tutcollections: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      mycollections: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      librarycollections: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      bizcollections: {
        page: 0,
        rowsPerPage: 10,
        tableFilters: [[], [], [], [], [], [], [], [], []],
        tagCombo: "or",
        searchText: "",
        asset: {}
      },

      config: {
        snapshotLastUpdated: 1504885000000,
        libraryUpdated: 1504885000000,
        newsLastSeen: 1504885000000,
        collectionsUpdated: 1504885000000,
        adminCollections: false
      }
    };

    // See if there is any localStorage item for tableState at the moment
    if (localStorage.getItem("tableState")) {
      ts.current = {
        ...initialTableState,
        ...JSON.parse(localStorage.getItem("tableState"))
      };
    } else {
      localStorage.setItem("tableState", JSON.stringify(initialTableState));
      ts.current = initialTableState;
    }

    Log(
      "Calling loadFavourites, loadViewLater, loadAllCollections and loadResults"
    );
    loadFavourites();
    loadViewLater();
    loadAllCollections();
    loadResults();
  }, [
    auth.isAuthenticated,
    loadFavourites,
    loadViewLater,
    loadAllCollections,
    loadResults
  ]);

  // Check for any new assets after 60 seconds
  // ----------------------------------------------------------------------
  // 30 seconds after getting into app, we do a check to see if there are are asset
  // updates in the library. After this initial check, the schedule of updates is defined
  // in app/index.js
  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const timer = setTimeout(() => checkForUpdate(false), 30000);

    return () => {
      clearTimeout(timer);
    };
  }, [auth.isAuthenticated, checkForUpdate]);

  // Firestore Listener for new favourites/view later/collections docs
  // ----------------------------------------------------------------------
  // Setup the firstore listener on this members docs to monitor for any updates.
  useEffect(() => {
    if (!auth.isAuthenticated) return; // Do nothing if not authenticated.

    Log(
      `Setup firestore snapshot listener with date ${new Date(
        getTableState("config", "snapshotLastUpdated")
      )}`
    );
    let snapshotListener = firebase
      .firestore()
      .collection("teams")
      .doc(process.env.REACT_APP_TEAM)
      .collection("members")
      .doc(String(auth.user.number))
      .collection("saved")
      .where(
        "updated",
        ">",
        new Date(getTableState("config", "snapshotLastUpdated"))
      )
      .onSnapshot(
        querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            let docData = change.doc.data();
            Log(
              `${change.doc.id} "${change.type}" in firestore (via snapshot listener)`
            );

            // Handle a REMOVE (ie deletion)
            if (
              (change.type === "removed" &&
                ["favourites", "later", "collection", "myCollection"].includes(
                  docData.type
                )) ||
              (docData.deleted && docData.deleted === true)
            ) {
              // Make sure we have an id
              if (!docData.id || docData.id.length === 0) {
                Log(`WARNING: ${change.doc.id} has no id field`);
                return;
              }

              // Update the last updated value that is used when we initialise the snapshot listener
              updateTableState("config", {
                snapshotLastUpdated: docData.updated.toDate().getTime()
              });

              // Remove the asset in the local table
              db.table("saved")
                .delete(docData.id)
                .then(() => {
                  Log(`Removed ${docData.id} from saved table`);
                  loadEverything();
                })
                .catch(err => {
                  Log(
                    `db error deleteing asset id ${docData.id} from table saved : ${err}`
                  );
                  ga.ReactGA.exception({
                    description: `library.js:snapshotListener:db.table("saved").delete(${docData.id}):${err}`
                  });
                });

              return; // Nothing else to do here.
            }

            // Handle an UPDATE/ADDITION
            if (
              (change.type === "added" || change.type === "modified") &&
              ["favourites", "later", "collection", "myCollection"].includes(
                docData.type
              ) &&
              docData.updated &&
              docData.updated.toDate()
            ) {
              // Update the last updated value that is used when we initialise the snapshot listener
              updateTableState("config", {
                snapshotLastUpdated: docData.updated.toDate().getTime()
              });
              //Log(`snapshotLastUpdated setting to: ${docData.updated.toDate()}`);

              db.table("saved")
                .put({ ...docData, updated: docData.updated.toDate() })
                .then(() => {
                  Log(`Put ${docData.id} into saved table`);
                  loadEverything();
                })
                .catch(err => {
                  Log(
                    `db error adding/updating asset id ${docData.id} to table ${docData.type} : ${err}`
                  );
                  ga.ReactGA.exception({
                    description: `library.js:snapshotListener:db.table("saved").put:${err}`
                  });
                });
            }
          });
        },
        err => {
          console.error(`Failed to setup snapshotListener : ${err}`);
          enqueueSnackbar(`Failed to setup snapshotListener : ${err}`, {
            variant: "error"
          });
          ga.ReactGA.exception({
            description: `library.js:snapshotListener:FATAL - could not setup snapshot listener:${err}`,
            fatal: true
          });
        }
      );

    return () => {
      // Remove the snapshot listeners when we unmount this component.
      Log(`UNMOUNTED and removing snapshot listener`);
      snapshotListener();
    };
  }, [
    db,
    auth,
    enqueueSnackbar,
    loadFavourites,
    loadViewLater,
    loadMyCollections,
    loadEverything,
    getTableState,
    updateTableState,
    ga
  ]);

  return {
    results, // entire library of assets
    isFavourite,
    updateFavourite,
    getFavourites,
    isViewLater,
    updateViewLater,
    getViewLater,
    collections,
    getCollections,
    getAssetsInCollection,
    addAssetToMyCollection,
    deleteAssetFromMyCollection,
    updateCollection,
    deleteCollection,
    checkForUpdate,
    updateTableState,
    getTableState,
    resetTableState,
    clearDixieTables
  };
}
