import React, { useState, useMemo, useCallback } from "react";
import Debug from "debug";
import axios from "axios";
import { navigate } from "hookrouter";

import useScreenSize from "../../../hooks/useScreenSize";

import {
  Container,
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  responsiveFontSizes,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";

import NextIcon from "@material-ui/icons/ArrowForwardIos";

const Log = Debug("ChangePassword");

export default function SetPassword({ auth }) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);

  const screenSize = useScreenSize();

  const savePassword = useCallback(() => {
    Log(`Setting password`);
    setWaiting(true);

    axios
      .post(auth.user.api.userAPI, {
        action: "setPassword",
        team: process.env.REACT_APP_TEAM,
        number: auth.user.number,
        token: auth.user.token,
        password: password1
      })
      .then(response => {
        // response.data.code RETURN CODES:
        //
        // 1. Password updated
        //
        // 500. Server error occured

        Log(response.data);

        if (response.data.code === 1) {
          setError(false);
          setWaiting(false);
          Log("Password updated");
          navigate("/app");
          return;
        }

        if (response.data.code === 500) {
          Log(
            `500 Error Returned. Client message: ${response.data.err}  ${
              response.data.info ? ` More info: ${response.data.info}` : ""
            }`
          );
          setError(response.data.err);
          setWaiting(false);
          return;
        }
      })
      .catch(err => {
        Log(err);
      });
  }, [auth.user, password1]);

  // Set the global styles for containers as their default paddings are too large.
  const theme = useMemo(() => {
    // Style overrides for the MUIDataTable
    let theme = createMuiTheme({
      overrides: {
        MuiContainer: {
          root: {
            paddingLeft: "0px",
            paddingRight: "0px",
            "@media (min-width:375px)": {
              paddingLeft: "4px",
              paddingRight: "4px"
            },
            "@media (min-width:500px)": {
              paddingLeft: "8px",
              paddingRight: "8px"
            },
            "@media (min-width:600px)": {
              paddingLeft: "10px",
              paddingRight: "10px"
            },
            "@media (min-width:700px)": {
              paddingLeft: "15px",
              paddingRight: "15px"
            },
            "@media (min-width:800px)": {
              paddingLeft: "18px",
              paddingRight: "18px"
            },
            "@media (min-width:1200px)": {
              paddingLeft: "20px",
              paddingRight: "20px"
            }
          }
        },
        MuiOutlinedInput: {
          input: {
            padding: "12px 10px",
            "@media (min-width:500px)": {
              padding: "18.5px 14px"
            }
          }
        },

        MuiInputLabel: {
          outlined: {
            transform: "translate(12px, 14px) scale(0.9)",
            "@media (min-width:500px)": {
              transform: "translate(14px, 20px) scale(1.0)"
            }
          }
        }
      }
    });
    theme = responsiveFontSizes(theme);

    return theme;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper
          style={{
            margin: `${screenSize.width < 500 ? "0" : "20"}px`,
            boxShadow: `${
              screenSize.width < 500 ? "none" : "2px 1px 8px 0px #7b1b1b"
            }`
          }}
        >
          <Box style={{ padding: `${screenSize.width < 500 ? "10" : "25"}px` }}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Typography align="center" variant="h5">
                  Change/Set your Password
                </Typography>
              </Grid>

              <Grid item>
                <Typography style={{ fontSize: "85%" }}>
                  Set your password. The password must be 8 characters or
                  longer.
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="password1"
                  type="password"
                  label="Set a password"
                  value={password1}
                  error={error ? true : false}
                  helperText={error ? error : "Minimum of 8 characters"}
                  variant="outlined"
                  onChange={e => setPassword1(e.target.value)}
                  disabled={waiting}
                  style={{ margin: "15px 0px", width: "240px" }}
                  autoFocus
                />
              </Grid>

              <Grid item>
                <TextField
                  id="password2"
                  type="password"
                  label="Confirm password"
                  value={password2}
                  error={error ? true : false}
                  helperText={"Re-enter password"}
                  variant="outlined"
                  onChange={e => setPassword2(e.target.value)}
                  disabled={waiting}
                  style={{ margin: "15px 0px", width: "240px" }}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "15px" }}
                  disabled={
                    password1 !== password2 || password1.length < 8
                      ? true
                      : waiting
                  }
                  onClick={savePassword}
                  endIcon={
                    waiting ? (
                      <CircularProgress
                        style={{ height: "30px", width: "30px" }}
                      />
                    ) : (
                      <NextIcon />
                    )
                  }
                >
                  Set password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
