import React, { useCallback, useEffect, useState } from "react";
import Debug from "debug";
import _ from "lodash";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/AddCircle";

const Log = Debug("AddToCollection");

export default function AddToCollection({
  asset,
  library,
  newCollection,
  addToCollection,
  resetDialog
}) {
  const [open, setOpen] = useState(false); // The useEffect will set this to true
  const [view, setView] = useState(
    library.getCollections("my").length === 0 ? "new" : "select"
  ); // select is to select an existing collection. new is to create a new collection.

  const [collectionName, setCollectionName] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");

  // When the asset being passed in changes, we open the dialog.
  useEffect(() => {
    if (asset && asset.id) {
      Log(`Add to collection dialog display for ${asset.id}`);
      setCollectionName("");
      setCollectionDesc("");
      if (library.getCollections("my").length === 0) {
        setView("new");
      } else {
        setView("select");
      }
      setOpen(true);
    }
  }, [asset, library]);

  const handleClose = useCallback(() => {
    setOpen(false);
    resetDialog();
  }, [resetDialog]);

  const useStyles = makeStyles({
    paper: {
      margin: "5px"
    },

    paperFullWidth: {
      width: "calc(100% - 2vw)"
    }
  });

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
      classes={{
        paper: classes.paper,
        paperFullWidth: classes.paperFullWidth
      }}
    >
      {view === "new" && (
        <>
          <DialogTitle id="form-dialog-title">
            Create a new collection
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontSize: "85%" }}>
              This asset will be assigned to this new collection you are
              creating. Give this new collection a name and description then
              click the Create & Assign button.
            </DialogContentText>
            <TextField
              id="cn"
              label="Collection Name"
              value={collectionName}
              onChange={e => setCollectionName(e.target.value)}
              variant="outlined"
              fullWidth
              autoFocus
              style={{ marginBottom: "25px" }}
            />
            <TextField
              id="cd"
              label="Collection Description"
              value={collectionDesc}
              onChange={e => setCollectionDesc(e.target.value)}
              variant="outlined"
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => {
                Log(`Adding new collection with name ${collectionName}`);
                newCollection(
                  {
                    name: collectionName,
                    desc: collectionDesc,
                    assets: [asset.id]
                  },
                  asset
                );
                handleClose();
              }}
              color="primary"
              variant="contained"
              disabled={collectionName.length === 0}
            >
              Create & Assign
            </Button>
          </DialogActions>
        </>
      )}

      {view === "select" && (
        <>
          <DialogTitle id="form-dialog-title">Add to collection</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontSize: "85%" }}>
              Tap an existing collection to add this asset to <i>or</i> tap the
              new collection button:
            </DialogContentText>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: "cornsilk" }}
            >
              <Table size="small" aria-label="collections">
                <TableBody>
                  {_.orderBy(
                    library.getCollections("my"),
                    ["name"],
                    ["asc"]
                  ).map(collection => (
                    <TableRow
                      key={collection.id}
                      hover={!collection.assets.includes(asset.id)}
                      onClick={e => {
                        if (!collection.assets.includes(asset.id)) {
                          addToCollection(collection, asset);
                          handleClose();
                        }
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: "85%",
                          color: `${
                            collection.assets.includes(asset.id)
                              ? "grey"
                              : "default"
                          }`
                        }}
                      >
                        {collection.assets.includes(asset.id)
                          ? "[already in] "
                          : ""}
                        {collection.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setView("new")}
            >
              New collection
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
