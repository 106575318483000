import React, { useEffect } from "react";
import Debug from "debug";

import AppArea from "./pages/app/";
import Login from "./pages/Login";
import SignedOutPage from "./pages/SignedOutPage";
import InstallationPage from "./pages/InstallationPage";
import BrowserCheckPage from "./pages/BrowserCheck";

import { useRoutes } from "hookrouter";

const Log = Debug("Routes");

export default function Routes({ library }) {
  useEffect(() => {
    Log("MOUNTED");

    return () => {
      Log("UNMOUNTED");
    };
  }, []);

  const routes = {
    "/app*": () => <AppArea library={library} />,

    "/login": () => <Login />,

    "/signedout": () => <SignedOutPage />,

    "/check": () => <BrowserCheckPage />,

    "*": () => <InstallationPage />
  };

  const Routes = () => {
    const routeResult = useRoutes(routes);
    return routeResult;
  };

  return <Routes />;
}
