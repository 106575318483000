import React, { useEffect, useState } from "react";
import Debug from "debug";
import _ from "lodash";

import { CircularProgress, Container, Typography } from "@material-ui/core";

import Page from "../library/Page";

const Log = Debug("FavouritesPage");

export default function FavouritesPage({ library }) {
  const [results, setResults] = useState(
    _.chain(library.results)
      .filter(asset => library.getFavourites().includes(asset.id))
      .sortBy("name")
      .value()
  );

  // Fetch the results
  useEffect(() => {
    if (results.length === 0) {
      if (library.results.length === 0) {
        Log("Waiting for local indexedDB library.results to return data");
        return;
      }

      setResults(
        _.chain(library.results)
          .filter(asset => library.getFavourites().includes(asset.id))
          .sortBy("name")
          .value()
      );
    }
  }, [library, library.results, results.length]);

  if (library.results.length === 0)
    return (
      <Container maxWidth="sm">
        <Typography variant="h4">Loading</Typography>
        <CircularProgress />
      </Container>
    );

  return (
    <Page
      page="favourites"
      results={results}
      library={library}
      searchPlaceholder="Search Favourites"
    />
  );
}
